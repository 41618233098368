import {useStore} from '../../../store';
import React from 'react';
import {Link} from 'react-router-dom';

const FacilityComplete = () => {
  const resetIsFinalized = () => useStore.setState((state) => ({
    checkout: {
      ...state.checkout,
      isFinalized: false
    }
  }));

  resetIsFinalized();

  return (
    <section className='post-checkout'>
      <h1 className='post-checkout__heading'>Thank you for your purchase!</h1>
      <h3 className='post-checkout__desc'>An email of your invoice has been sent to you. <Link to='/account/invoices'>You can also view your order history by clicking here</Link></h3>
    </section>
  );
};

export default FacilityComplete;