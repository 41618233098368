import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Response = [
  {
    id: number;
    image: string;
    link: string;
    hide: 1 | 0;
    button_text: string;
    headline: string;
    subhead: string;
    orderNum: number;
  }
];

const getLandingCarouselPort = '/getLandingPage_shopLinks_DnD';

/**Requests a list of all admins */
const getLandingCarouselAPI = async (): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getLandingCarouselPort);

  if (isDebugging) {
    console.log(`${getLandingCarouselPort} response:`, res);//TESTING
  }

  return res;
};

export default getLandingCarouselAPI;