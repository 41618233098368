import FreeCatalogController from './FreeCatalogController';
import AccountNav from '../../navigation/account/AccountNavView';
import SelectCountry from '../../../utility/location/SelectCountry';
import SelectRegion from '../../../utility/location/SelectRegion';
import SelectCounty from '../../../utility/location/SelectCounty';
import catalogImage from '../../../media/Catalog.png';
import {ReactComponent as LoadingIconSmall} from '../../../media/icon-loading-small.svg';
import {useEffect} from 'react';
import {allCountries, CountryData} from 'country-region-data';
import {TextField, FormControl, Select, MenuItem, InputLabel} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

const FreeCatalogView = () => {
  const {
    account,
    updateAccountInfo,
    formData,
    location,
    onChangeInput,
    onChangeState,
    onChangeCountry,
    onSubmit,
    isLoading,
    submitButtonAttributes,
    selectedCountry,
    selectedRegion,
    setSelectedCountry,
    setSelectedRegion
  } = FreeCatalogController();

  useEffect(() => {
    updateAccountInfo();
  }, [account]);

  return (
    <section className='free-catalog'>
    {/* <section className={`free-catalog ${account && account.roleId === 3 ? 'free-catalog--facility' : ''}`}> */}
      {/* {
        account &&
        account.roleId === 3 &&
        <AccountNav currentPath={location.pathname} />
      } */}
      <div className='free-catalog__main'>
        <h1 className='free-catalog__heading'>Free Catalog</h1>
        <p className='free-catalog__subheading'>
          If you would like a copy of our most current catalog,
          <br />
          please fill out the following information:
        </p>
        <form onSubmit={onSubmit} className='free-catalog__form'>
          <div className='free-catalog__form-group'>
            <p>Shipping:</p>
            <TextField
              name='firstName'
              placeholder='First Name'
              value={formData.firstName}
              onChange={onChangeInput}
              required
            />
            <TextField
              name='lastName'
              placeholder='Last Name'
              value={formData.lastName}
              onChange={onChangeInput}
              required
            />
            <TextField
              name='companyName'
              placeholder='Company Name'
              value={formData.companyName}
              onChange={onChangeInput}
            />
            <TextField
              name='address1'
              placeholder='Address'
              value={formData.address1}
              onChange={onChangeInput}
              required
            />
            <TextField
              name='address2'
              placeholder='Apt. or Ext.'
              value={formData.address2}
              onChange={onChangeInput}
            />
            <TextField
              name='city'
              placeholder='City'
              value={formData.city}
              onChange={onChangeInput}
              required
            />
            <SelectRegion
              selectedCountry={selectedCountry}
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
            />
            <TextField
              type='number'
              name='zip'
              placeholder='Zip Code'
              value={formData.zip}
              onChange={onChangeInput}
              required
            />
            <SelectCountry
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </div>
          <div className='free-catalog__form-group'>
            <p>Contact Information:</p>
            <TextField
              name='phoneNumber'
              placeholder='Phone Number'
              value={formData.phoneNumber}
              onChange={onChangeInput}
              required
            />
            <TextField
              name='email'
              placeholder='Email'
              value={formData.email}
              onChange={onChangeInput}
              required
            />
            <p className='form__group-heading'>How many copies would you like to recieve?</p>
            <TextField
              type='number'
              name='quantity'
              placeholder='Quantity'
              value={formData.quantity}
              onChange={onChangeInput}
              required
            />
            <button
              className='btn btn--primary form__submit'
              type='submit'
              value={submitButtonAttributes.text}
              disabled={submitButtonAttributes.disabled}
            >
              {
                isLoading ?
                <LoadingIconSmall /> :
                submitButtonAttributes.text
              }
            </button>
          </div>
        </form>
        <img src={catalogImage} alt='catalog preview' className='free-catalog__preview'/>
      </div>
    </section>
  );
};

export default FreeCatalogView;
