import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

export type Request = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  facName: string;
  comment: string;
  requestFollowup: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const giveFeedbackPort = '/customerFeedback';

/**Submits customer feedback. */
const giveFeedbackAPI = async (req: Request, captchaToken: string): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');
  const res = await api.post(giveFeedbackPort, req, {
    headers: {
      remoteip: '',
      captchaToken: captchaToken
    }
  });

  if (isDebugging) {
    console.log(`${giveFeedbackPort} response:`, res);//TESTING
  }

  return res;
};

export default giveFeedbackAPI;