
const FAQController = () => {
  const onClickQuestion = (id: string) => {
    const answer = document.querySelector(`#${id}`);

    if (!answer) return;

    answer.classList.toggle('faq__answer--active');
  };

  return {onClickQuestion};
};

export default FAQController;
