import {State} from '../..';
import {SetState, GetState} from 'zustand';

const setSelectedResident = (set: SetState<State>, get: GetState<State>) =>
async (residentId: number) => {
  const residentInStore = get().account?.residents?.find((resident) => resident.id === residentId);

  if (residentInStore) {
    set({
      selectedResident: residentInStore
    });

    localStorage.setItem('selectedResident', residentId.toString());
  } 
};

export default setSelectedResident;
