import FAQController from './FAQController';
import faqBanner from '../../../media/FAQBanner.png';
import React from 'react';
import {Link} from 'react-router-dom';

type FAQSectionProps = {
  id: string;
  header: string;
  answer: JSX.Element;
}

const FAQView = () => {
  const {onClickQuestion} = FAQController();

  const FAQSection = ({id, header, answer}: FAQSectionProps) => 
  <li onClick={() => onClickQuestion(id)}>
    <h3 className='faq__question-header'>{header}</h3>
    <p id={id} className='faq__answer'>{answer}</p>
  </li>;

  return (
    <section className='faq'>
      <span className='faq__banner-wrapper'>
        <img src={faqBanner} alt='FAQ Banner' className='faq__banner'/>
        <h1>FAQs</h1>
      </span>
      <ul className='faq__questions'>
        <FAQSection
          id='a1'
          header='Do I have to register to use this site?'
          answer={<>No. Registration is not required to use this site, unless you are a facility and would like
            to set up a direct bill for your facility. If you are not a nursing facility, and wish to 
            shop and simply pay with a credit card, you may do so without registering.</>}
        />
        <FAQSection
          id='a2'
          header='How much is shipping and handling?'
          answer={<>For nursing facility orders that total over $500, your shipping is FREE! There is an exception for
            oversized furniture shipments. The oversized shipping rate ranges from $25 to $200. Please see our
            catalog or website for specific costs on a specific item. For all other invoices or orders, there is a
            flat rate of $9.95 within the continental United States. For Alaska, Hawaii, and Canada, please call for 
            inquiries at (800) 334-2897 or email us
            at <a href='mailto:service@seniorshoppingservice.com'>service@seniorshoppingservice.com</a>.</>}
        />
        <FAQSection
          id='a3'
          header='When can I expect my order to be delivered?'
          answer={<>All orders will be shipped within 1-4 business days from our warehouse, the UPS Ground transit can be
            calculated onto the total. See <Link to='info/shipping'>Shipping Info</Link> for transit times. If you
            need a rush shipment, we can definitely accommodate those needs. Call us at (800) 334-2897 or email us
            at <a href='mailto:service@seniorshoppingservice.com'>service@seniorshoppingservice.com</a>.</>}
        />
        <FAQSection
          id='a4'
          header='How do I return something?'
          answer={<>To initiate a return, please contact our customer service team at <a href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a> to obtain a Return Merchandise Authorization (RMA) number.<br />
          Once you receive your RMA number, please securely package the item(s), and include the RMA number on the package.<br />
          Ship the item(s) to <b>319 Anderson Rd, Chesterfield, IN 46017</b><br />
          Once we receive and inspect the returned item(s), we will process your refund within seven (7) business days. Please note that shipping charges are non-refundable.
          </>}
        />
        <FAQSection
          id='a5'
          header='What is your privacy policy?'
          answer={<>We follow strict privacy policy guidelines. Your information, facility's information, and resident's
          information is never used or sold. NEVER. If you would like your name removed from our mailing list,
          please email us at <a href='mailto:service@seniorshoppingservice.com'>service@seniorshoppingservice.com</a> and
          let us know that you would like to be removed from our database.</>}
        />
        <FAQSection
          id='a6'
          header='Options for payment?'
          answer={<>We accept Visa, Master, or Discovery cards. You may also prepay for your order by check or money order,
            payable to <strong>Senior Shopping Service</strong>. For nursing or long-term care facilities we offer
            direct billing to the facility or to the resident's trust account. A Direct Bill Ordering form will
            need to be filled out and faxed back to our corporate office at (765) 378-7758, to be eligible for
            30-day terms.</>}
        />
      </ul>
      <span className='faq__new-question'>
        <h3>
          Don't see your question?
        </h3>
        <p>
          Please email us at <a href='mailto:service@seniorshoppingservice.com'>service@seniorshoppingservice.com</a> and
          we will see to your question within the next 24-hour business day.
        </p>
      </span>
    </section>
  );
};

export default FAQView;