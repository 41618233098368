import {State} from '../..';
import {getHeroTextAPI} from '../../../api';
import {SetState, GetState} from 'zustand';

export const getHeroText = (set: SetState<State>, get: GetState<State>) => async () => {
	const res = await getHeroTextAPI();

	if (res === null) return;
	
	set(() => ({
		heroText: {
			header: res[0].headline,
			subheader: res[0].subhead
		}
	}));
};

export default getHeroText;