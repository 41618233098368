import type FacilityInvoice from '../entities/FacilityInvoice';
import {State} from 'application/store';
import {getFacilityInvoicesAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const getFacilityInvoices = (set: SetState<State>, get: GetState<State>) =>
	async () => {

    const res = await getFacilityInvoicesAPI();
    
    if (res === null) return;

    const modifiedInvoices: Array<FacilityInvoice> = res.map((inv) => ({
      id: inv.invoice_ID,
      date: inv.date,
      invoiceType: inv.invoice_type,
      isProcessed: inv.PROCESSED === 1,
      isPaid: inv.isPaid === 1,
      totalCost: inv.total_cost,
      user: {
        id: inv.facility_details[0].userID
      },
      facility: {
        id: inv.facility_PO,
        name: inv.facility_details[0].facility_name
      }
    }));

    set((state) => ({
      invoices: {
        ...state.invoices,
        facility: modifiedInvoices
      }
    }));
	};

export default getFacilityInvoices;