import {DomainError, ErrorConfig} from 'application/utility';

/**Custom error to indicate that it has originated from the server.*/
export default class ServerError extends DomainError {
  status?: number;
  path: string;

  /**
   * Creates a new ServerError instance.
   * @param error 
   * @param message Custom message for error
   * @param path API path that was called
   * @param status Status code of the error, if available
   * @param config Optional config to alter behavior
   * @returns New ServerError instance
   */
  constructor(error: any, message: string, path: string, status?: number, config?: ErrorConfig) {
    super(error, message, config);

    this.name = 'ServerError'
    this.message = message;
    this.status = status;
    this.path = path;
    this.data = { error };
  }
};