import type IndividualInvoice from '../entities/IndividualInvoice';
import {State} from 'application/store';
import {getIndividualInvoicesAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const getIndividualInvoices = (set: SetState<State>, get: GetState<State>) =>
	async (userId?: string) => {

    const res = await getIndividualInvoicesAPI(userId);

    if (res === null) return;

    const modifiedInvoices: Array<IndividualInvoice> = res.map((inv) => ({
      id: inv.invoice_ID,
      date: inv.date,
      isProcessed: inv.PROCESSED ? true : false,
      totalCost: inv.total_cost,
      user: {
        id: inv.account_details[0].userID,
        firstName: inv.account_details[0].firstName,
        lastName: inv.account_details[0].lastName
      }
    }));

    set((state) => ({
      invoices: {
        ...state.invoices,
        individual: modifiedInvoices
      }
    }));
  };

export default getIndividualInvoices;