import getSubAccounts from '../actions/getSubAccounts';
import setAlert from '../../alerts/actions/setAlert';
import getResidentList from '../../residents/actions/getResidentList';
import getCarts from '../../checkout/actions/getCarts';
import {State} from '../..';
import {getAccountDetailsAPI} from '../../../api';
import {authService, isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const getAccountDetails = (set: SetState<State>, get: GetState<State>) => 
  async () => {
		const accountInStore = get().account;

		try {
				if (!accountInStore) return;

				const res = await getAccountDetailsAPI(
					accountInStore.userId,
				);

        const {
					roleID,
					userID,
					userToken,
					username,
					firstName,
					lastName,
					title,
					email,
					phoneNumber,
					newsletter
				} = res.data;
				
				authService.token.set(userToken);

        switch (Number(roleID)) {
          // case 2:
          //   const {
					// 		address1,
					// 		address2,
					// 		city, 
					// 		state,
					// 		zip,
					// 		country,
          //   } = res.data;

					// 	set({ account: {
					// 		roleId: 2,
					// 		userId: userID,
					// 		firstName: firstName,
					// 		title: title,
					// 		username: username,
					// 		email: email,
					// 		lastName: lastName,
					// 		phoneNumber: phoneNumber,
					// 		shipping: {
					// 			address1: address1,
					// 			address2: address2,
					// 			city: city,
					// 			state: state,
					// 			zip: zip,
					// 			country: country
					// 		},
					// 		newsletter: newsletter === '1' ? true : false
					// 	}});
					// 	await getCarts(set, get)();
          //   break;
          case 3:
            const {
							facName,
							facPhone,
							facAdmin,
							facFax,
							facAddress1,
							facAddress2,
							facCity,
							facCounty,
							facState,
							facZip,
							facCountry,
							facPO,
							facOfficeMgr,
							facOfficeMgrEmail,
							facRewards
            } = res.data;

						set((state) => ({
							account: {
								roleId: 3,
								userId: userID,
								title: title,
								username: username,
								firstName: state.account?.firstName || firstName,
								lastName: state.account?.lastName || lastName,
								firstNameMain: firstName,
								lastNameMain: lastName,
								email: email,
								phoneNumber: phoneNumber,
								company: {
									name: facName,
									admin: facAdmin,
									phoneNumber: facPhone,
									fax: facFax,
									po: facPO,
									rewards: facRewards,
									officeManager: {
										name: facOfficeMgr,
										email: facOfficeMgrEmail
									}
								},
								shipping: {
									address1: facAddress1,
									address2: facAddress2,
									city: facCity,
									county: facCounty || undefined,
									state: facState,
									zip: facZip,
									country: facCountry
								},
								newsletter: newsletter === '1' ? true : false
							}
						}));
						
						await getCarts(set, get)();
						await getResidentList(set, get)();
						await getSubAccounts(set, get)();
            break;
          default:
						if (isDebugging) {
							console.log('getAccountDetails has defaulted:', res);
						}
            break;
				}
			} catch (err: any | unknown) {
				if (isDebugging) {
					console.log('get account details store error:', err);
				}
				setAlert(set, get)({
					status: 'danger',
					header: 'Error',
					text: err?.response?.data?.message || 'Failed to get account details'
				});
      }
	};

export default getAccountDetails;