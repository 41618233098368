import FooterViewModel from './FooterController';
import {ReactComponent as LoadingIconSmall} from '../../media/icon-loading-small.svg';
import {companyInfo} from '../../../utility';
import React from 'react';
import {Link} from 'react-router-dom';
import {TextField} from '@mui/material';

const FooterView = () => {
  const {
    account,
    email,
    setEmail,
    isLoading,
    onSubmitNewsletter
  } = FooterViewModel();

  const copyRightYear = new Date().getFullYear();

  return (
    <section className='footer'>
      <div className='footer__account'>
        <h5>Account</h5>
        {
          account &&
          account.roleId === 3 ?
          <Link to='/account/edit'>Manage Account</Link> :
          <Link to='/register'>Register your Facility</Link>
        }
        <Link to='/checkout/carts'>Shopping Cart</Link>
        {
          account &&
          account.roleId === 3 &&
          <>
            <Link to='/account/invoices'>Order History</Link>
            <Link to='/account/residents'>Resident List</Link>
          </>
        }
        <Link to='/info/giftCards'>Rewards</Link>
        <Link to='/info/facilityForms'>Facility Forms</Link>
        <Link to='/info/freeCatalog'>Free Catalog</Link>
      </div>
      <div className='footer__needs'>
        <h5>Shop by Need</h5>
        <ul>
          <Link to={`/productList/need/${'ADAPX'}`}>Adaptive Apparel</Link>
          <Link to={`/productList/need/${'ALSX'}`}>ALS Products</Link>
          <Link to={`/productList/need/${'ALZX'}`}>Alzheimer's</Link>
          <Link to={`/productList/need/${'ARTHX'}`}>Arthritic Clothing</Link>
          <Link to={`/productList/need/${'DIAX'}`}>Diabetes/Edema</Link>
          <Link to={`/productList/need/${'ELAPX'}`}>Elderly Apparel</Link>
          <Link to={`/productList/need/${'HANDX'}`}>Handicap Needs</Link>
          <Link to={`/productList/need/${'INCX'}`}>Incontinence</Link>
          <Link to={`/productList/need/${'SENX'}`}>Senior Clothing</Link>
          <Link to={`/productList/need/${'SPFOX'}`}>Specialty Footwear</Link>
          <Link to={`/productList/need/${'WHCX'}`}>Wheelchair Accessories</Link>
        </ul>
      </div>
      <div className='footer__company'>
        <h5>Senior Shopping Service</h5>
        <Link to='/info/about'>About</Link>
        <Link to='/info/faq'>FAQs</Link>
        <Link to='/info/labeling'>Labeling/Alterations</Link>
        <Link to='/info/returnPolicy'>Returns/Refunds</Link>
        <Link to='/info/qualityGuarantee'>Quality Guarantee</Link>
        <Link to='/info/shipping'>Shipping Info</Link>
        <Link to='/info/sizeGuide'>Sizing Chart</Link>
      </div>
      <div className='footer__connect'>
        <h5>Connect</h5>
        <a href={`tel:${companyInfo.phone.main}`}>{companyInfo.phone.main}</a>
        <a href={`tel:${companyInfo.phone.fax}`}>{`Fax: ${companyInfo.phone.fax}`}</a>
        <a href={`mailto:${companyInfo.email.customerService}`}>{companyInfo.email.customerService}</a>
        <Link to='/info/feedback'>Tell Us What You Think</Link>
        <Link to='/info/contact'>Contact Us</Link>
      </div>
      <div className='footer__newsletter'>
        <p>Sign up and save! Receive updates with exclusive offers!</p>
        <TextField
          label='Email Address'
          variant='standard'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className='btn btn--primary'
          onClick={onSubmitNewsletter}
          disabled={isLoading}
        >
          {
            isLoading ?
            <LoadingIconSmall /> :
            'Send'
          }
        </button>
      </div>
      <div className='footer__copyright'>
        <p>
          {`©${copyRightYear} Senior Shopping Service, Inc. All rights reserved. `}
        </p>
        <Link to='/info/privacy'>Privacy Policy</Link>
      </div>
    </section>
  );
};

export default FooterView;