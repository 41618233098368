import womenSizingChart from '../../../media/Women_Sizing_Chart.png';
import womenSizingChartMobile from '../../../media/Women_Sizing_Chart-Mobile.png';
import womenSizingGuide from '../../../media/Women_Sizing_Guide.png';
import womenSizingGuideMobile from '../../../media/Women_Sizing _Guide-Mobile.png';
import menSizingChart from '../../../media/Men_Sizing_Chart.png';
import menSizingChartMobile from '../../../media/Men_Sizing_Chart-Mobile.png';
import menSizingGuide from '../../../media/Men_Sizing_Guide.png';
import menSizingGuideMobile from '../../../media/Men_Sizing_Guide-Mobile.png';
import React, {useState, useEffect} from 'react';

type SelectedNav = {
  index: 1 | 2 | 3 | 4;
  desktopImage: string;
  mobileImage: string;
  imgAlt: string;
};

const SizingController = () => {
  const [selectedNav, setSelectedNav] = useState<SelectedNav>({
    index: 1,
    desktopImage: womenSizingChart,
    mobileImage: womenSizingChartMobile,
    imgAlt: `Women's Sizing Chart`
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const init = () => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  };

  const onWidthChange = () => {

  };

  const onChangeNav = (index: number) => {
    switch (index) {
      case 1:
      default:
        setSelectedNav({
          index: 1,
          desktopImage: womenSizingChart,
          mobileImage: womenSizingChartMobile,
          imgAlt: `Women's Sizing Chart`
        });
        break;
      
      case 2:
        setSelectedNav({
          index: 2,
          desktopImage: womenSizingGuide,
          mobileImage: womenSizingGuideMobile,
          imgAlt: `Women's Sizing Guide`
        });
        break;
      
      case 3:
        setSelectedNav({
          index: 3,
          desktopImage: menSizingChart,
          mobileImage: menSizingChartMobile,
          imgAlt: `Men's Sizing Chart`
        });
        break;
      
      case 4:
        setSelectedNav({
          index: 4,
          desktopImage: menSizingGuide,
          mobileImage: menSizingGuideMobile,
          imgAlt: `Men's Sizing Guide`
        });
        break;
    }
  };

  return {
    init,
    selectedNav,
    onChangeNav,
    windowWidth
  }
};

export default SizingController;