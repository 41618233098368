import AuthActions from './auth/entities/AuthActions';
import Account from './auth/entities/Account';
import login from './auth/actions/login';
import register from './auth/actions/register';
import createGuest from './auth/actions/createGuest';
import logout from './auth/actions/logout';
import getAccountDetails from './auth/actions/getAccountDetails';
import getSubAccounts from './auth/actions/getSubAccounts';
import addSubAccount from './auth/actions/addSubAccount';
import resetSubPassword from './auth/actions/resetSubPassword';
import editAccount from './auth/actions/editAccount';
import subscribeToNewsletter from './auth/actions/subscribeToNewsletter';

import NavActions from './navigation/entities/NavActions';
import Breadcrumbs from './navigation/entities/Breadcrumbs';
import getNavigation from './navigation/actions/getNavigation';
import createBreadcrumbs from './navigation/actions/createBreadcrumbs';
import getBreadcrumbs from './navigation/actions/getBreadcrumbs';

import ResidentActions from './residents/entities/ResidentActions';
import Resident from './residents/entities/Resident';
import getResidentList from './residents/actions/getResidentList';
import editResident from './residents/actions/editResident';
import addResident from './residents/actions/addResident';
import setSelectedResident from './residents/actions/setSelectedResident';

import AlertActions from './alerts/entities/AlertActions';
import Alert from './alerts/entities/Alert';
import BannerText from './alerts/entities/BannerText';
import setAlert from './alerts/actions/setAlert';
import deleteAlert from './alerts/actions/deleteAlert';
import getBannerText from './alerts/actions/getBannerText';
import editBannerText from './alerts/actions/editBannerText';

import CheckoutActions from './checkout/entities/CheckoutActions';
import Checkout, {defaultCheckout} from './checkout/entities/Checkout';
import getCarts from './checkout/actions/getCarts';
import addToCart from './checkout/actions/addToCart';
import editCart from './checkout/actions/editCart';
import emptyCart from './checkout/actions/emptyCart';
import finalizeCarts from './checkout/actions/finalizeCarts';

import Admin from './admin/entities/Admin';
import AdminActions from './admin/entities/AdminActions';
import AdminToEdit from './admin/entities/AdminToEdit';
import Invoices from './admin/entities/Invoices';
import ProductInfo from './admin/entities/Product';
import Dept from './admin/entities/Dept';
import TaxRate from './admin/entities/TaxRate';
import loginAdmin from './admin/actions/loginAdmin';
import logoutAdmin from './admin/actions/logoutAdmin';
import getFacilityInvoices from './admin/actions/getFacilityInvoices';
import getIndividualInvoices from './admin/actions/getIndividualInvoices';
import editFacilityInvoice from './admin/actions/editFacilityInvoice';
import editIndividualInvoice from './admin/actions/editIndividualInvoice';
import getProductInfo from './admin/actions/getProductInfo';
import editProduct from './admin/actions/editProduct';
import editSubProduct from './admin/actions/editSubProduct';
import addSubProduct from './admin/actions/addSubProduct';
import deleteSubProduct from './admin/actions/deleteSubProduct';
import getDeptMatrix from './admin/actions/getDeptMatrix';
import deleteDept from './admin/actions/deleteProductDept';
import addProductDept from './admin/actions/addProductDept';
import addProduct from './admin/actions/addProduct';
import deleteProduct from './admin/actions/deleteProduct';
import getTaxRates from './admin/actions/getTaxRates';
import editTaxRate from './admin/actions/editTaxRate';
import editAdmin from './admin/actions/editAdmin';
import addAdmin from './admin/actions/addAdmin';
import deleteAdmin from './admin/actions/deleteAdmin';
import getAdmins from './admin/actions/getAdmins';
import CarouselActions from './carousel/entities/CarouselActions';
import LandingCarousel from './carousel/entities/LandingCarousel';

import HeroActions from './hero/entities/HeroActions';
import HeroText from './hero/entities/HeroText';
import getHeroText from './hero/actions/getHeroText';
import editHeroText from './hero/actions/editHeroText';

import getLandingCarousel from './carousel/actions/getLandingCarousel';
import addLandingCarousel from './carousel/actions/addLandingCarousel';
import editLandingCarousel from './carousel/actions/editLandingCarousel';
import deleteLandingCarousel from './carousel/actions/deleteLandingCarousel';
import updateCarouselOrder from './carousel/actions/updateCarouselOrder';

import RewardsActions from './rewards/entities/RewardsActions';
import addRewards from './rewards/actions/addRewards';

import {GetNavigationRes} from '../api';
import {isDebugging} from '../utility';
import create from 'zustand';
import {devtools} from 'zustand/middleware';

export type State = {
  isAuthenticated: boolean;
  account?: Account;
  selectedResident?: Resident;
  alerts: Array<Alert>;
  bannerText: Array<BannerText>;
  heroText?: HeroText;
  navigation?: GetNavigationRes;
  breadcrumbs?: Breadcrumbs;
  checkout: Checkout;
  admin?: Admin;
  invoices: Invoices;
  productInfo?: ProductInfo;
  deptMatrix?: Array<Dept>;
  taxRates?: Array<TaxRate>;
  adminList: Array<AdminToEdit>;
  carousels: {
    landing: Array<LandingCarousel>;
  }
} & AuthActions
  & NavActions
  & ResidentActions
  & AlertActions
  & CheckoutActions
  & AdminActions
  & HeroActions
  & CarouselActions
  & RewardsActions;

export const initialState = {
  isAuthenticated: false,
  account: undefined,
  alerts: [],
  bannerText: [],
  navigation: undefined,
  checkout: defaultCheckout,
  invoices: {
    facility: [],
    individual: []
  },
  adminList: [],
  carousels: {
    landing: []
  }
};

// const log = config => (set, get, api) => config(args => {
//   console.log("  applying", args)
//   set(args)
//   console.log("  new state", get())
// }, get, api)

export const useStore = create<State>(devtools((set, get) => ({
  ...initialState,
  dispatchLogin: login(set, get),
  dispatchRegister: register(set, get),
  dispatchCreateGuest: createGuest(set, get),
  dispatchLogout: logout(set),
  dispatchGetAccountDetails: getAccountDetails(set, get),
  dispatchEditAccount: editAccount(set, get),
  dispatchGetNavigation: getNavigation(set, get),
  dispatchGetResidentList: getResidentList(set, get),
  dispatchEditResident: editResident(set, get),
  dispatchSetAlert: setAlert(set, get),
  dispatchDeleteAlert: deleteAlert(set, get),
  dispatchAddResident: addResident(set, get),
  dispatchGetCarts: getCarts(set, get),
  dispatchAddToCart: addToCart(set, get),
  dispatchEditCart: editCart(set, get),
  dispatchSetSelectedResident: setSelectedResident(set, get),
  dispatchEmptyCart: emptyCart(set, get),
  dispatchFinalizeCarts: finalizeCarts(set, get),
  dispatchCreateBreadcrumbs: createBreadcrumbs(set, get),
  dispatchGetBreadcrumbs: getBreadcrumbs(set),
  dispatchSubscribeToNewsletter: subscribeToNewsletter(set, get),
  dispatchLoginAdmin: loginAdmin(set, get),
  dispatchGetFacilityInvoices: getFacilityInvoices(set, get),
  dispatchGetIndividualInvoices: getIndividualInvoices(set, get),
  dispatchEditFacilityInvoice: editFacilityInvoice(set, get),
  dispatchEditIndividualInvoice: editIndividualInvoice(set, get),
  dispatchGetProductInfo: getProductInfo(set, get),
  dispatchEditProduct: editProduct(set, get),
  dispatchAddSubProduct: addSubProduct(set, get),
  dispatchEditSubProduct: editSubProduct(set, get),
  dispatchGetDeptMatrix: getDeptMatrix(set, get),
  dispatchAddProductDept: addProductDept(set, get),
  dispatchDeleteSubProduct: deleteSubProduct(set, get),
  dispatchDeleteDept: deleteDept(set, get),
  dispatchAddProduct: addProduct(set, get),
  dispatchDeleteProduct: deleteProduct(set, get),
  dispatchGetTaxRates: getTaxRates(set, get),
  dispatchEditTaxRate: editTaxRate(set, get),
  dispatchGetBannerText: getBannerText(set, get),
  dispatchEditBannerText: editBannerText(set, get),
  dispatchGetHeroText: getHeroText(set, get),
  dispatchEditHeroText: editHeroText(set, get),
  dispatchLogoutAdmin: logoutAdmin(set, get),
  dispatchGetSubAccounts: getSubAccounts(set, get),
  dispatchResetSubPassword: resetSubPassword(set, get),
  dispatchAddSubAccount: addSubAccount(set, get),
  dispatchEditAdmin: editAdmin(set, get),
  dispatchGetAdmins: getAdmins(set, get),
  dispatchAddAdmin: addAdmin(set, get),
  dispatchDeleteAdmin: deleteAdmin(set, get),
  dispatchGetLandingCarousel: getLandingCarousel(set, get),
  dispatchAddLandingCarousel: addLandingCarousel(set, get),
  dispatchEditLandingCarousel: editLandingCarousel(set, get),
  dispatchDeleteLandingCarousel: deleteLandingCarousel(set, get),
  dispatchAddRewards: addRewards(set, get),
  dispatchUpdateCarouselOrder: updateCarouselOrder(set, get)
})));

if (isDebugging) {
  useStore.subscribe(console.log);
}