import dateToNumber from './dateToNumber';

const dateComparator = (date1: string, date2: string) => {
  const date1Number = dateToNumber(date1);
  const date2Number = dateToNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};

export default dateComparator;