import qualityGuaranteeImage from '../../../media/QualityGuaranteeSeal.png';
import React, {useState} from 'react';

const QualityGuaranteeView = () => {

  return (
    <section className='quality-guarantee'>
      <div className='quality-guarantee__banner'>
        <h1 className='quality-guarantee__heading'>Quality Guarantee</h1>
        <img src={qualityGuaranteeImage} alt='quality guarantee'/>
      </div>
      <div className='quality-guarantee__main'>
        <div className='quality-guarantee__promise'>
          <h3>Our Promise</h3>
          <p>
            Senior Shopping Service, Inc. is proud to guarantee that our clothing is of superior quality.
            We are so confident that our clothing is exceptional that we guarantee our merchandise will hold
            up in your industrial laundry for at least thirty days. If you happen to have any problems, we
            will cheerfully replace the garment(s) free of charge.
          </p>
          <p>
            Please call for a shipping return label with any wearable product(s) that has quality issues. We take pride in decades of happy customers.
          </p>
        </div>
        <div className='quality-guarantee__substitutions'>
          <h3>Substitutions</h3>
          <p>
            To expedite your delivery, we may avoid backordering by upgrading to a similar or better product
            at no extra charge.
          </p>
        </div>
      </div>
    </section>
  );
};

export default QualityGuaranteeView;
