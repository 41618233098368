import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Request = {
  image: string;
  link: string;
  hide: 1 | 0;
  button_text: string;
  headline: string;
  subhead: string;
  orderNum: number;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const addLandingCarouselPort = '/addLandingPage_shopLinks';

/**Edits the text shown in the notification bar. */
const addLandingCarouselAPI = async (request: Request): Promise<AxiosResponse<Response>> => {
  const res = await api.post(addLandingCarouselPort, request);
  
  if (isDebugging) {
    console.log(`${addLandingCarouselPort} response:`, res);//TESTING
  }

  return res;
};

export default addLandingCarouselAPI;