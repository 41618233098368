import DeptNavController from './DeptNavController';
import React from 'react';
import {Link} from 'react-router-dom';

const DeptNavView = () => {
  const {
    navigation,
    breadcrumbs
  } = DeptNavController();

  return <>
    {
      navigation &&
      navigation.map((d, index) => {
        if (
          breadcrumbs &&
          breadcrumbs.length > 0 &&
          d.dept_ID === breadcrumbs[0].id
        ) {
          return <ul className='dept-nav hide--medium' key={index}>
            <Link className='dept-nav__dept' to={`/productList/dept/${d.dept_ID}`}>
              {`${d.dept}${d.dept.endsWith('s') ? `'` : `'s`} All`}
            </Link>
            {
              d.subDepartments &&
              <ul className='dept-nav__subdepts'>
                {
                  d.subDepartments.map((subD) => 
                    <li
                      key={subD.subDept_ID}
                      className='dept-nav__subdept'
                    >
                      <Link to={`/productList/dept/${d.dept_ID}/${subD.subDept_ID}`}>
                        {
                          breadcrumbs.length > 1 &&
                          subD.subDept_ID === breadcrumbs[1].id ?
                          <strong>{subD.subDept}</strong> :
                          subD.subDept
                        }
                      </Link>
                      {
                        subD.superSubDepartments &&
                        <ul className='dept-nav__supersubdepts'>
                          {
                            subD.superSubDepartments.map((superSubD) =>
                              (
                                superSubD.superSubDept && superSubD.superSubDept_ID &&
                                <li
                                  key={superSubD.superSubDept_ID}
                                  className='dept-nav__supersubdept'
                                >
                                  <Link to={`/productList/dept/${d.dept_ID}/${subD.subDept_ID}/${superSubD.superSubDept_ID}`}>
                                    {
                                      breadcrumbs.length > 2 &&
                                      superSubD.superSubDept_ID === breadcrumbs[2].id ?
                                      <strong>{superSubD.superSubDept}</strong> :
                                      superSubD.superSubDept
                                    }
                                  </Link>
                                </li>
                              )
                            )
                          }
                        </ul>
                      }
                    </li>
                  )
                }
              </ul>
            }
          </ul>
        }
      })
    }
  </>
};

export default DeptNavView;
