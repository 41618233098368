import {useStore} from '../../../store';
import React, {useEffect, Dispatch, SetStateAction} from 'react';
import {allCountries, CountryData, Region} from 'country-region-data';
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';

interface Props extends SelectProps {
  selectedCountry: CountryData | undefined;
  selectedRegion: Region | undefined;
  setSelectedRegion: Dispatch<SetStateAction<Region | undefined>>;
};

const SelectRegion = (props: Props) => {
  const {
    selectedCountry,
    selectedRegion,
    setSelectedRegion,
    required = true,
    disabled,
    ...rest
  } = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);

  const onChangeSelectedRegion = (e: SelectChangeEvent<unknown>) => {
    if (!selectedCountry) {
      dispatchSetAlert({
        status: 'warning',
        header: 'Error',
        text: 'Please select a country'
      });
      return;
    }

    const newRegion = selectedCountry[2].find((region) => region[1] === e.target.value);

    if (newRegion) {
      setSelectedRegion(newRegion);
    } else {
      dispatchSetAlert({
        status: 'danger',
        header: 'Error',
        text: 'Error occured while attempting to change region'
      });
    }
  };

  const selectedCountryListener = () => {
    if (!selectedCountry) {
      setSelectedRegion(undefined);
      return;
    }

    if (
      !selectedRegion ||
      !selectedCountry[2].some((region) => region[1] === selectedRegion[1])
    ) {
      // Disabling default region selection
      // const defaultRegion = selectedCountry[2][0];
  
      setSelectedRegion(undefined);
    }
  };

  useEffect(() => selectedCountryListener(), [selectedCountry, selectedRegion]);

  return (
    <FormControl>
      <InputLabel id='region-label'>State</InputLabel>
      <Select
        label='State'
        labelId='region-label'
        id='region'
        value={selectedRegion ? selectedRegion[1] : ''}
        onChange={onChangeSelectedRegion}
        disabled={!selectedCountry || disabled}
        required={required}
        {...rest}
      >
        {
          selectedCountry ?
          selectedCountry[2].map((region, index) =>
            <MenuItem value={region[1]} key={index}>{region[0]}</MenuItem>
          ) :
          <MenuItem value='' selected>State (please select country)</MenuItem>
        }
      </Select>
    </FormControl>
  );
};

export default SelectRegion;