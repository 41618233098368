import OrderToCreate from '../entities/OrderToCreate';
import getCarts from './getCarts';
import {State} from '../..';
import createGuest from '../../auth/actions/createGuest';
import setAlert from '../../alerts/actions/setAlert';
import {addToCartAPI, OrderToAdd} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const addToCart = (set: SetState<State>, get: GetState<State>) =>
async (orderToCreate: OrderToCreate) => {
  try {
    let account = get().account;

    if (!account) {
      await createGuest(set, get)();
      account = get().account;

      if (!account) throw new Error();
    }

    const createdOrder: OrderToAdd = {
      userID: Number(account.userId),
      residentID: orderToCreate.residentId || 0,
      prod_itemID: orderToCreate.product.id,
      prod_name: orderToCreate.product.name,
      prod_color: orderToCreate.product.option,
      prod_size: orderToCreate.product.size,
      prod_price: orderToCreate.product.price,
      quantity: orderToCreate.product.quantity,
      prod_shipping: orderToCreate.product.shipping,
      sched_delivery: orderToCreate.product.scheduledDelivery
    };

    await addToCartAPI(createdOrder);

    getCarts(set, get)();

    setAlert(set, get)({
      status: 'success',
      header: 'Item Added',
      text: 'Item has been added'
    });

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get carts store error:', err);//TESTING
    }
    setAlert(set, get)({
      status: 'danger',
      header: 'Error',
      text: err.response.data.message || 'Failed to add item to cart'
    });  
  }
};

export default addToCart;