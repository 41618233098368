import React from 'react';
import {Link} from 'react-router-dom';

type Props = {
  value: number;
};

const InvoiceButtonRenderer = (props: Props) => {

  return (
    <Link to={`/admin/invoices/individual/${props.value}`}>
      View Invoices
    </Link>
  );
};

export default InvoiceButtonRenderer;