import {useStore} from '../../../store';
import React, {useState, useEffect, Fragment} from 'react';

const Banner = () => {
  const alerts = useStore((state) => state.alerts);
  const bannerText = useStore((state) => state.bannerText);
  const dispatchGetBannerText = useStore((state) => state.dispatchGetBannerText);
  const defaultText = {
    id: 0,
    text: `Free Labeling of Resident's Name Available!`
  };
  const [currentText, setCurrentText] = useState(
    bannerText.length > 0 ?
    bannerText[0] :
    defaultText
  );

  useEffect(() => {
    let intervalId: number;

    if (bannerText.length === 0) {
      dispatchGetBannerText()

    } else {
      intervalId = window.setInterval(() => setCurrentText(
        bannerText[Math.floor(Math.random() * bannerText.length)]
      ), 15000);
    }

    return () => window.clearInterval(intervalId);

  }, [bannerText]);

  return <Fragment>
    {
      alerts &&
      alerts.length > 0 ?
      <div className={`banner banner--${alerts[alerts.length - 1].status}`}>
        <h5 className='banner__alert'>{alerts[alerts.length - 1].text}</h5>
      </div> :
      <div className='banner'>
        <h5 className='banner__ad'>{currentText.text}</h5>
        <a className='banner__number' href='tel:18003342897'>1-800-334-2897</a>
      </div>
    }
  </Fragment>
};

export default Banner;
