import {useStore} from '../../../../store';
import React, {useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';

const BreadcrumbsController = () => {
  const breadcrumbs = useStore((state) => state.breadcrumbs);
  const dispatchGetBreadcrumbs = useStore((state) => state.dispatchGetBreadcrumbs);
  const navigation = useStore((state) => state.navigation);

  const init = () => {
    if (!breadcrumbs) dispatchGetBreadcrumbs();
  };

  return {
    breadcrumbs,
    init
  };
};

export default BreadcrumbsController;