import shippingBanner from '../../../media/ShippingBanner.png';
import shippingMap from '../../../media/ShippingMap.png';
import companyInfo from '../../../utility/companyInfo';
import React from 'react';

const ShippingView = () => {

  return (
    <section className='shipping'>
      <div className='shipping__banner'>
        <img src={shippingBanner} alt='banner'/>
        <h1>Shipping Info</h1>
      </div>
      <div className='shipping__main'>
        <div className='shipping__tracking'>
          <h3>Tracking Available</h3>
          <p>
            Please contact us regarding your shipment
            at <a href='tel:18003342897'>(800) 334-2897</a>. If you give 
            us an email address, we can email you your tracking number for
            all your packages once they have been shipped. Average
            turnaround from order to delivery is 7-10 business days.
          </p>
        </div>
        <div className='shipping__charges'>
          <h3>Shipping and Handling Charges</h3>
          <p>
            For Continental United States deliveries*, we primarily ship
            UPS Ground and USPS Priority Mail.<br/>
            However, Express Delivery is available upon request for an
            additional charge.<br />
            <span className='shipping__fine-print shipping__fine-print--charges'>
              We will ship outside the continental United States. Please
              call our customer service department toll-free
              at <a href='tel:18003342897'>(800) 334-2897</a>. to get a
              shipping rate, Monday-Friday 9am-5pm EDT. You can also
              email us
              at <a href='mailto:service@seniorshoppingservice.com'>service@seniorshoppingservice.com</a>.
            </span>
          </p>
          <div className='shipping__charges-chart'>
            <h5>Type (per invoice)</h5>
            <h5>{'S&H Cost'}</h5>
            <p>Residential Deliveries</p>
            <p>$9.95</p>
            <p>Facility Delivery</p>
            <p>$9.95</p>
            <p>Facility Delivery (over $500)</p>
            <p>FREE</p>
            <h5 className='shipping__additional-charges'>Additional Charge</h5>
            <h5 className='shipping__additional-charges'>{'S&H Cost'}</h5>
            <p>Oversized Furniture*</p>
            <p>$25-$200</p>
            <span className='shipping__fine-print shipping__fine-print--charges-chart'>
              *Unless otherwise noted in publications or on website.
            </span>
          </div>
        </div>
        <div className='shipping__map-desc'>
          This map shows you the number of typical transit days that it
          takes to ship from our warehouse to you. At any time that you
          have questions, please contact us
          at <a href='tel:18003342897'>(800) 334-2897</a>.
        </div>
        <img src={shippingMap} alt='shipping map' className='shipping__map'/>
      </div>
    </section>
  );
};

export default ShippingView;