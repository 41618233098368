import React, {useState, useEffect, SyntheticEvent} from 'react';
import ReactImageMagnify from 'react-image-magnify';

export type Img = {
  id: string;
  src: string;
  alt: string;
};

export type Thumbnail = Img & {
  imageId: string;
};

type Props = {
  images:Array<Img>;
  defaultImg: Img;
  thumbnails: Array<Thumbnail>;
  defaultThumbnail: Thumbnail;
};

const ImageSelection = (props: Props) => {
  const {images, defaultImg, thumbnails, defaultThumbnail} = props;
  const [isThumbnailActive, setIsThumbnailActive] = useState(true);
  const [curImage, setCurImage] = useState(
    images.length > 0 ?
    images[0] :
    defaultImg
  );

  const changeMainImage = (thumbnailId: string, imgId: string) => {
		const thumbs = document.querySelectorAll('.image-selection__thumbnail');
		thumbs.forEach((thumbnail) => 
			thumbnail.classList.remove('image-selection__thumbnail--active')
		);

		const selectedThumb = document.querySelector(`#thumbnail-${thumbnailId}`);
		selectedThumb?.classList.add('image-selection__thumbnail--active');

		setCurImage(images.find((img) => img.id === imgId) || defaultImg);
	};

	const onBrokenImage = () => {
    setCurImage(defaultImg);
		setIsThumbnailActive(false);
	};

	const onBrokenThumbnail = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultThumbnail.src;
  };

  useEffect(() => setCurImage(
    images.length > 0 ?
    images[0] :
    defaultImg
  ), [images]);

  return (
    <div className='image-selection'>
      <ReactImageMagnify
        imageClassName='image-selection__image'
        smallImage={{
          src: curImage.src,
          isFluidWidth: true,
          onError: onBrokenImage
        }}
        largeImage={{
          src: curImage.src,
          width: 1000,
          height: 1000,
          onError: onBrokenImage
        }}
      />
      {
        isThumbnailActive &&
        <span className='image-selection__thumbnails'>
          {thumbnails.length > 0 && thumbnails.map((thumbnail, i) => 
            <img
              key={thumbnail.id}
              className={
                `image-selection__thumbnail ${i === 0 ? 'image-selection__thumbnail--active' : ''}`
              }
              id={`thumbnail-${thumbnail.id}`}
              src={thumbnail.src}
              alt={thumbnail.alt}
              onError={onBrokenThumbnail}
              onClick={() => changeMainImage(thumbnail.id, thumbnail.imageId)}
            />
          )}
        </span>
      }
    </div>
  );
};

export default ImageSelection;