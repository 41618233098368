import {useStore} from '../../../store';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Custom hook to handle taxrate state and actions
 * @returns taxrates from the back-end, and a function to update them
 */
const useTaxRates = () => {
  const taxRates = useStore((state) => state.taxRates);
  const dispatchGetTaxRates = useStore((state) => state.dispatchGetTaxRates);
  const dispatchEditTaxRate = useStore((state) => state.dispatchEditTaxRate);
  
  useEffect(() => {
    dispatchGetTaxRates();
  }, []);

  return {taxRates, dispatchEditTaxRate}
};

/**
 * Page that lists taxrates
 * @returns Page component
 */
const TaxRates = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 9,
      name: 'Tax Rates',
      path: location.pathname
    }
  ]);
  const {taxRates, dispatchEditTaxRate} = useTaxRates();
  const [rowData, setRowData] = useState<Array<Object>>([]);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {field: 'state', headerName: 'State', filter: 'agTextColumnFilter'},
    {field: 'county', headerName: 'County', filter: 'agTextColumnFilter'},
    {field: 'tax', headerName: 'Tax', editable: true, filter: 'agTextColumnFilter'}
  ];

  const onCellValueChanged = (e: any) => {
    dispatchEditTaxRate({
      state: e.data.state,
      county: e.data.county,
      tax: e.data.tax
    });
  };

  useEffect(() => {
    if (!taxRates) return;

    setRowData(taxRates.map((tax) => ({
      state: tax.state,
      county: tax.county,
      tax: tax.tax
    })));
  }, [taxRates]);

  return (
    <section className='a-table'>
      <DataGrid
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onCellValueChanged={onCellValueChanged}
      />
    </section>
  );
};

export default TaxRates;