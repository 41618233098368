import defaultNav from './defaultNav';
import {useStore} from '../../../../store';
import {Dept, SubDept} from '../../../../api';
import {useState} from 'react';

const NavbarController = () => {
  let navigation = useStore((state) => state.navigation);
  const storedNavigation = localStorage.getItem('navigation');
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const account = useStore((state) => state.account);
  const residents = useStore((state) => state.account?.residents);
  const dispatchGetNavigation = useStore((state) => state.dispatchGetNavigation);
  const dispatchGetResidentList = useStore((state) => state.dispatchGetResidentList);
  const distpatchLogout = useStore((state) => state.dispatchLogout);
  const [hoveredDept, setHoveredDept] = useState<Dept | undefined>(undefined);
  const [hoveredAccount, setHoveredAccount] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const init = () => {
    useStore.setState({
      navigation: defaultNav
    });
    dispatchGetNavigation();
    window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
  };

  const onNavChange = () => {
    if (!navigation && storedNavigation) navigation = JSON.parse(storedNavigation);    
  };

  const onClickLogout = () => {
    clearAccountPanel();
    distpatchLogout();
  };

  const clearAccountPanel = () => setHoveredAccount(false);

  const clearAccountSidebar = () => {
    const accountLinks = document.querySelector('.navbar__links--account');
    accountLinks?.classList.remove('navbar__links--account-active');
  };

  const onHoverAccount = () => {
    setHoveredAccount(true);
    setHoveredDept(undefined);
  };

  const onClickAccount = () => {
    const accountLinks = document.querySelector('.navbar__links--account');
    accountLinks?.classList.toggle('navbar__links--account-active');
  };
  
  const clearHamburger = () => {
    const catalogLinks = document.querySelector('.navbar__links--catalog');
    catalogLinks?.classList.remove('navbar__links--active');
  };

  const onClickHamburger = () => {
    const catalogLinks = document.querySelector('.navbar__links--catalog');
    catalogLinks?.classList.toggle('navbar__links--active');
  };
  
  const clearDeptPanel = () => setHoveredDept(undefined);

  const onHoverDept = (dept: Dept) => {
    setHoveredDept(dept);
    setHoveredAccount(false)
  };

  const onClickDept = (id: number) => {
    const selectedDept = document.querySelector(`#dept${id}`);
    const alreadySelectedDropdown = selectedDept?.querySelector(':scope > .navbar__dropdown--active');

    if (alreadySelectedDropdown) {
      onClickHamburger();

    } else {
      const allDropdownElements = document.querySelectorAll('.navbar__dropdown');
      allDropdownElements.forEach((element) => {
        element.classList.remove('navbar__dropdown--active');
      });
      
      const deptDropdown = selectedDept?.querySelector(':scope > .navbar__dropdown');
      deptDropdown?.classList.toggle('navbar__dropdown--active');
    }
  };

  return {
    navigation,
    account,
    isAuthenticated,
    hoveredDept,
    hoveredAccount,
    screenWidth,
    init,
    onNavChange,
    clearDeptPanel,
    clearAccountPanel,
    clearAccountSidebar,
    clearHamburger,
    onClickLogout,
    onClickAccount,
    onClickHamburger,
    onClickDept,
    onHoverDept,
    onHoverAccount
  };
};

export default NavbarController;