import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import {useStore} from '../../../store';
import React, {MouseEvent, Dispatch, SetStateAction} from 'react';
import { LandingCarousel } from '../../../store';

type Props = {
  value: number;
  data: any;
};

const DeleteCarouselRenderer = (
  props: any,
  curCarousels: LandingCarousel[],
  setCurCarousels: Dispatch<SetStateAction<LandingCarousel[]>>
) => {
  console.log('props', props)
  const {data: {id, isNew}} = props;
  const dispatchDeleteLandingCarousel = useStore((state) => state.dispatchDeleteLandingCarousel);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const isConfirmed = window.confirm(`Are you sure you'd like to delete this item?`);
    

    if (!isConfirmed) return;

    let rowNodes = props.node.parent.allLeafChildren;

    const filteredRows = curCarousels.filter((c) => c.id !== id);

    setCurCarousels(filteredRows);
    

    // dispatchDeleteLandingCarousel({id});
  };

  return (
    isNew ?
    <></> :
    <button
      className='btn'
      onClick={onClick}
      style={
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }
      }
    >
      <TrashIcon style={{width: '2rem'}}/>
    </button>
  );
};

export default DeleteCarouselRenderer;