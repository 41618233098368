import getSubAccounts from './getSubAccounts';
import SubAccount from '../entities/SubAccount';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {addSubAccountAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const addSubAccount = (set: SetState<State>, get: GetState<State>) => async (newSub: SubAccount) => {
  const accountInStore = get().account;

	try {
    if (!accountInStore) throw new Error();

    await addSubAccountAPI({
      userID: newSub.id,
      email: newSub.email,
      title: newSub.title,
      firstName: newSub.firstName,
      lastName: newSub.lastName
    });
    
    setAlert(set, get)({
      status: 'success',
      header: 'Sub Account Added',
      text: 'Sub-account added'
    });

    getSubAccounts(set, get)();
    
	} catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get sub-accounts store error:', err);
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to add sub-account'
		});
	}
};

export default addSubAccount;