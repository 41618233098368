import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  userID: string;
  userToken: string;
  username: string;
  roleID: string;
  firstName: string;
  lastName: string;
};

const createGuestAccountPort = '/createQuickAccount';

const createGuestAccountAPI = async (): Promise<AxiosResponse<Response>> => {
  const res = await api.get(createGuestAccountPort);

  if (isDebugging) {
    console.log(`${createGuestAccountPort} response:`, res);//TESTING
  }

  return res;
};

export default createGuestAccountAPI;