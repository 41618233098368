import {get} from 'application/api/baseApi';

export type Params = {
  username: string;
  password: string;
  captchaToken: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  username: string;
  adminID: number;
  userToken: string;
  superUser: '1' | '0';
};

const path = '/authenticateAdmin';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param params Request parameters
 * @returns Expected response or null
 */
const loginAdminAPI = (params: Params) => get<Response>(
  path,
  {
    headers: {
      // remoteip: ipRes.status === 200 ? ipRes.data.IPv4 : '',
      remoteip: '',
      captchaToken: params.captchaToken
    },
    params: {
      username: params.username,
      pword: params.password,
    }
  }
);

export default loginAdminAPI;