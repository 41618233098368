import rewardsImage from '../../../media/Rewards_Promo(1).jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

export const GiftCardView = () => {
  // const [promo, setPromo] = useState({
  //   giftCard: {
  //     id100: '991100',
  //     id25: '991025'
  //   }
  // });

  return (
    <section className='gift-card'>
      <div className='gift-card__banner'>
        <img src={rewardsImage} alt='gift card'/>
      </div>
      <div className='gift-card__main'>
        <h1>Welcome to our New Rewards Program</h1>
        {/* <h3 className='gift-card__promo-heading'>Gift Card Promo</h3> */}
        <p>
          We’re excited to offer our facilities a seamless experience when using Rewards to purchase items for residents with little to no funds, add to the activities department, or create holiday fun.  Earning rewards is easy, and using them now is even easier! 
          Once you have completed your shopping and proceeded to Checkout, your rewards total will display above the cart.  Choose Rewards as your payment type, enter your amount, and continue to Finalize your purchase.  That’s it! 
        </p>
        <aside>
          Rewards Program: For every $1,000 your facility spends in a calendar month across any/all residents that are paid within 30 days, we will issue $100 Rewards that you can use to purchase products on our website. Qualifying Rewards will be automatically added to your online account. There is no limit to the number of Rewards a facility can earn. Rewards have no cash value and must be redeemed online. All rights are reserved.  Please call us for further information. 
        </aside>
      </div>
    </section>
  );
};

export default GiftCardView;
