import AccountForm from './AccountForm';
import FacilityForm from './FacilityForm';
import AccountFields from './entities/AccountFields';
import FacilityFields from './entities/FacilityFields';
import handleReCAPTCHA from '../../../../utility/google/handleReCAPTCHA';
import {useRecaptchaContext} from '../../../../context/recaptcha';
import {authService} from '../../../../../utility';
import {useStore, Company, Shipping} from '../../../../../store';
import {RegisterFacility} from '../../../../../api';
import React, {
	useState,
	useEffect
} from 'react';
import {Redirect, Link, useHistory} from 'react-router-dom';

const Register = () => {
	const history = useHistory();
	const recaptchaRef = useRecaptchaContext();
	const isAuthenticated = useStore((state) => state.isAuthenticated);
	const account = useStore((state) => state.account);
	const dispatchRegister = useStore((state) => state.dispatchRegister);
	const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const [accountFields, setAccountFields] = useState<AccountFields>({
		username: '',
		email: '',
		plainPassword: '',
		plainPasswordConfirm: '',
		firstName: '',
		lastName: '',
		title: '',
		phoneNumber: ''
	});
	const [facFields, setFacFields] = useState<FacilityFields>({
		companyName: '',
		companyPhoneNumber: '',
		fax: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		admin: '',
		bom: '',
		bomEmail: '',
		wantsNewsletter: false
	});
	const [registerProgress, setRegisterProgress] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	const onSubmitForms = async () => {
		setIsSubmitting(true);

		const token = await handleReCAPTCHA(recaptchaRef);
		if (!token) return dispatchSetAlert({
			status: 'danger',
			header: 'Error',
			text: 'There was a problem authenticating, please try again later'
		});

		const shipping: Shipping = {
			address1: facFields.address1,
			address2: facFields.address2,
			city: facFields.city,
			country: facFields.country,
			state: facFields.state,
			county: facFields.county,
			zip: facFields.zip
		};
		
		const company: Company = {
			rewards: '0.00',
			name: facFields.companyName,
			phoneNumber: facFields.companyPhoneNumber,
			admin: facFields.admin,
			fax: facFields.fax,
			officeManager: {
				name: facFields.bom,
				email: facFields.bomEmail
			}
		};

		const newFacility: RegisterFacility = {
			roleId: 3,
			username: accountFields.username,
			email: accountFields.email,
			firstName: accountFields.firstName,
			lastName: accountFields.lastName,
			title: accountFields.title,
			password: {
				hashed: authService.password.hash(accountFields.plainPassword),
				hashedConfirm: authService.password.hash(accountFields.plainPasswordConfirm)
			},
			phoneNumber: accountFields.phoneNumber,
			company: company,
			shipping: shipping,
			newsletter: facFields.wantsNewsletter
		};

		await dispatchRegister(newFacility, token);
		setIsSubmitting(false);
		setRegisterProgress(registerProgress + 1);
	};

	useEffect(() => {
		if (registerProgress === 2) {
			onSubmitForms();
		}
	}, [registerProgress]);
	
	if (isAuthenticated && account && account.roleId === 3) return <Redirect to='/account/residents' />;

	return (
    <div className='register'>
      {
        registerProgress === 0 ?
				<>
					<AccountForm
						accountFields={accountFields}
						setAccountFields={setAccountFields}
						registerProgress={registerProgress}
						setRegisterProgress={setRegisterProgress}
					/>
					<p className='register__link-wrap'>
						{`Already have an account? `}
						<Link className='register__link' to='/login'>
							Switch over to login
						</Link>
					</p>
				</> :
        <FacilityForm
          facFields={facFields}
          setFacFields={setFacFields}
          registerProgress={registerProgress}
          setRegisterProgress={setRegisterProgress}
					isSubmitting={isSubmitting}
        />
      }
    </div>
	);
};

export default Register;