import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const getInvoicePort = '/getInvoice';

const getInvoiceAPI = async (userId: number, invoiceId: number) => {
  const res = await api.get(getInvoicePort, {
    params: {
      userID: userId,
      invoiceID: invoiceId
    }
  });

  if (isDebugging) {
    console.log(`${getInvoicePort} response:`, res);//TESTING
  }

  return res;
};

export default getInvoiceAPI;