import LinkToFacilityRenderer from '../../utility/agGrid/LinkToFacilityRenderer';
import {lookupFacilityAPI} from '../../../api';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect, FormEvent} from 'react';
import {useLocation} from 'react-router-dom';
import {TextField} from '@mui/material';

/**
 * Page that lists facility accounts
 * @returns Page component
*/
const LookupFacility = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 4,
      name: 'Facilities',
      path: location.pathname
    }
  ]);
  const [rowData, setRowData] = useState<Array<Object>>([]);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 50,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'id',
      headerName: 'User ID',
      filter: 'agTextColumnFilter',
      width: 100,
      hide: true
    },
    {
      field: 'facilityId',
      headerName: 'Facility ID',
      filter: 'agTextColumnFilter',
      cellRenderer: LinkToFacilityRenderer,
      width: 200
    },
    {
      field: 'facilityName',
      headerName: 'Facility name',
      filter: 'agNumberColumnFilter',
      cellRenderer: LinkToFacilityRenderer,
      width: 200
    },
    {
      field: 'accountName',
      headerName: 'Account Name',
      filter: 'agTextColumnFilter',
      width: 200
    },
    {
      field: 'address',
      headerName: 'Street',
      filter: 'agTextColumnFilter',
      width: 200
    },
    {
      field: 'cityState',
      headerName: 'City/State',
      filter: 'agTextColumnFilter',
      width: 200
    },
    {
      field: 'residents',
      headerName: 'Residents',
      filter: 'agTextColumnFilter',
      minWidth: 200
      // cellRenderer: ResidentCellRenderer,
    }
  ];
  const [searchValue, setSearchValue] = useState('');

  const handleLookupFacility = async (value: string) => {
    const res = await lookupFacilityAPI(value);
    if (res === null) return;

    setRowData(res.map((fac) => ({
      id: fac.userID,
      facilityId: fac.facility_PO || '',
      facilityName: fac.facility_name,
      accountName: `${fac.fname} ${fac.lname}`,
      address: `${fac.facility_address1}`,
      cityState: `${fac.facility_city}, ${fac.facility_state}`,
      residents: fac.residentList?.map((resident) => resident.name).join(', ') || ''
    })));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLookupFacility(searchValue);
  };
  
  useEffect(() => {
    handleLookupFacility(' ');
  }, []);

  return (
    <div className='a-table a-table--searchable'>
      <form onSubmit={onSubmit} style={{display: 'flex', margin: '1rem'}}>
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className='btn btn--primary' type='submit'>
          Search
        </button>
      </form>
      <DataGrid
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default LookupFacility;