import {State} from 'application/store';
import StoreError from 'application/store/utility/errors/StoreError';
import getProductInfo from 'application/store/admin/actions/getProductInfo';
import type SubProduct from 'application/store/admin/entities/SubProduct';
import setAlert from 'application/store/alerts/actions/setAlert';
import {addSubProductAPI} from 'application/api';
import {isDebugging} from 'application/utility';
import {SetState, GetState} from 'zustand';

export const addSubProduct = (set: SetState<State>, get: GetState<State>) =>
	async (newSubProduct: Omit<SubProduct, 'id'>) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

		const req = {
			prod_groupID: newSubProduct.groupId,
			prod_itemID: newSubProduct.itemId,
			prod_name: newSubProduct.name,
			prod_size: newSubProduct.size,
			prod_price: newSubProduct.price
		};

		const res = await addSubProductAPI(req);

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully added sub-product'
		});

		getProductInfo(set, get)(productInStore.itemId);
	};

export default addSubProduct;