import {useStore} from '../../../../store';
import {useLocation} from 'react-router-dom';

const FacFormsController = () => {
  const account = useStore((state) => state.account);
  const location = useLocation();

  return {
    account,
    location
  };
};

export default FacFormsController;