import AccountNav from '../navigation/account/AccountNavView';
import {useRecaptchaContext} from '../../context/recaptcha';
import {ReactComponent as LoadingIcon} from '../../media/icon-loading.svg';
import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import SelectCountry from '../../utility/location/SelectCountry';
import SelectRegion from '../../utility/location/SelectRegion';
import SelectCounty from '../../utility/location/SelectCounty';
import {
	useStore,
	Company,
	Shipping,
	SubAccount
} from '../../../store';
import {
	EditedAccount,
	getStateCountyListAPI,
	GetStateCountyListRes,
	editSubAccountAPI,
	deleteSubAccountAPI
} from '../../../api';
import React, {
	useState,
	useEffect,
	ChangeEvent,
	FormEvent
} from 'react';
import {
	useHistory,
	useLocation
} from 'react-router-dom';
import {
	allCountries,
	CountryData,
	Region
} from 'country-region-data';
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel
} from '@mui/material';
import handleReCAPTCHA from '../../utility/google/handleReCAPTCHA';

/**Edit Account Page */
const EditAccount = () => {
	const isAuthenticated = useStore((state) => state.isAuthenticated);
  const account = useStore((state) => state.account);
	const dispatchGetAccountDetails = useStore((state) => state.dispatchGetAccountDetails);
	const dispatchGetSubAccounts = useStore((state) => state.dispatchGetSubAccounts);
	const dispatchAddSubAccount = useStore((state) => state.dispatchAddSubAccount);
	const dispatchEditAccount = useStore((state) => state.dispatchEditAccount);
	const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const history = useHistory();
	const location = useLocation();
	const recaptchaRef = useRecaptchaContext();
	const [isLoading, setIsLoading] = useState(true);
	const [hasAcceptedAgreement, setHasAcceptedAgreement] = useState(true)
	const countySensitiveRegions = [
		'AR',
		'FL',
		'IL',
		'KS',
		'OH'
	];
	const [stateCountyList, setStateCountyList] = useState<GetStateCountyListRes | undefined>(undefined);
	const [accountData, setAccountData] = useState({
		username: '',
		email: '',
		firstName: '',
		lastName: '',
		title: '',
		phoneNumber: '',
		newsletter: account?.newsletter ? '1' : undefined
	});
	const [facilityData, setFacilityData] = useState({
		name: '',
		phoneNumber: '',
		admin: '',
		fax: '',
		address1: '',
		address2: '',
		city: '',
		zip: '',
		officeManagerName: '',
		officeManagerEmail: ''
	});
	const [selectedCountry, setSelectedCountry] = useState<CountryData | undefined>();
	const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();
	const [selectedCounty, setSelectedCounty] = useState<string | undefined>();
	const [subAccounts, setSubAccounts] = useState<Array<SubAccount>>([]);
	const [newSubAccount, setNewSubAccount] = useState<Omit<SubAccount, 'id'>>({
		firstName: '',
		lastName: '',
		email: '',
		title: ''
	});

	const initialize = async () => {
		await dispatchGetAccountDetails();

		setIsLoading(false);
	};

	/**Updates {@link accountData}, {@link facilityData}, and {@link subAccounts}*/
	const updateAccountInfo = () => {
		if (!account) return;
		
		setAccountData({
			username: account.username || '',
			email: account.email || '',
			firstName: account.firstNameMain || '',
			lastName: account.lastNameMain || '',
			title: account.title || '',
			phoneNumber: account.phoneNumber || '',
			newsletter: account.newsletter ? '1' : undefined
		});

		if (account.shipping && account.company) {
			setFacilityData({
				name: account.company.name,
				phoneNumber: account.company.phoneNumber,
				admin: account.company.admin,
				officeManagerName: account.company.officeManager.name || '',
				officeManagerEmail: account.company.officeManager.email || '',
				fax: account.company.fax,
				address1: account.shipping.address1,
				address2: account.shipping.address2,
				city: account.shipping.city,
				zip: account.shipping.zip
			});
		}

    const matchingCountry = allCountries.find(
      (country) => country[1] === account.shipping?.country
    );

    if (matchingCountry) {
      setSelectedCountry(matchingCountry);

      const matchingRegion = matchingCountry[2].find(
        (region) => region[1] === account.shipping?.state
      );

      if (matchingRegion) setSelectedRegion(matchingRegion);
			if (account.shipping?.county) setSelectedCounty(account.shipping.county);
			    }

		if (account.subAccounts && account.subAccounts.length > 0) {
			setSubAccounts(account.subAccounts.map((sub) => ({
				id: sub.id,
				firstName: sub.firstName,
				lastName: sub.lastName,
				email: sub.email,
				title: sub.title
			})));
		}
	};

	/**Edits input data for {@link accountData} and {@link facilityData} */
	const onChangeInput =
	(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, mode: 'account' | 'facility') => {
		if (mode === 'account') {
			setAccountData({
				...accountData,
				[event.target.name]: event.target.value
			})

		} else {
			setFacilityData({
				...facilityData,
				[event.target.name]: event.target.value
			});
		}
	};

	/**Edits input data for {@link subAccounts} */
	const onChangeInputSubAccount =
	(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
		setSubAccounts(subAccounts.map((sub) => {
			if (sub.id === id) {
				return {
					...sub,
					[event.target.name]: event.target.value
				};
			} else return sub;
		}))
	};
	
	/**Deletes a sub-account then calls {@link requestSubAccounts}*/
	const onDeleteSubAccount = async (id: number) => {
		const subAccountToDelete = subAccounts.find((sub) => sub.id === id);

		if (!subAccountToDelete || !account) return;

		const res = await deleteSubAccountAPI({
			subID: subAccountToDelete.id,
			userID: Number(account.userId),
			firstName: subAccountToDelete.firstName,
			lastName: subAccountToDelete.lastName,
			email: subAccountToDelete.email,
			title: subAccountToDelete.title
		});

		if (res.status === 200) {
			dispatchSetAlert({
				status: 'warning',
				header: 'SubAccount Deleted',
				text: 'Sub-account was deleted.'
			});
		}

		dispatchGetSubAccounts();
	};

	/**Edits a sub-account then calls {@link requestSubAccounts} */
	const onEditSubAccount = (subAccountId: number) => {
		const subAccountToEdit = subAccounts.find((sub) => sub.id === subAccountId);
		
		if (!account || !subAccountToEdit) return;
		
		editSubAccountAPI({
			userID: Number(account.userId),
			subID: subAccountToEdit.id,
			firstName: subAccountToEdit.firstName,
			lastName: subAccountToEdit.lastName,
			email: subAccountToEdit.email,
			title: subAccountToEdit.title
		}).then((res) => {
			if (res.status === 200) {
				dispatchSetAlert({
					status: 'success',
					header: 'Sub Account edited',
					text: 'Sub-account edited'
				});
			}
		
			dispatchGetSubAccounts();
		});
	}
	
	/**Adds a sub-account then calls {@link requestSubAccounts} */
	const onAddSubAccount = () => {
		if (!account) return;

		const newSub = {
			id: Number(account.userId),
			...newSubAccount
		};

		dispatchAddSubAccount(newSub);
		
		setNewSubAccount({
			firstName: '',
			lastName: '',
			email: '',
			title: ''
		});
	};
	
	/**Handles form submittion for {@link accountData} and {@link facilityData} */
	const onSubmit =
	async (event: FormEvent<HTMLFormElement>, mode: 'account' | 'facility') => {
		event.preventDefault();

		if (
			!account ||
			!account.shipping ||
			!account.company ||
			!account.username ||
			!account.email ||
			!account.lastName ||
			!account.phoneNumber ||
			!selectedCountry ||
			!selectedRegion ||
			!hasAcceptedAgreement
		) throw new Error();//IMPLEMENT alert

		const invalidInputs =
		facilityData.zip === '';
			
		if (invalidInputs) {
			throw new Error(); //IMPLEMENT alert
		}
		
		//IMPLEMENT some more checks

		let shipping: Shipping;
		let company: Company;
		let editedAccount: EditedAccount;
		
		if (mode === 'account') {
			shipping = {
				address1: account.shipping.address1,
				address2: account.shipping.address2,
				city: account.shipping.city,
				state: account.shipping.state,
				country: account.shipping.country,
				county: account.shipping.county,
				zip: account.shipping.zip
			};

			company = {
				rewards: account.company.rewards,
				name: account.company.name,
				phoneNumber: account.company.phoneNumber,
				admin: account.company.admin,
				officeManager: account.company.officeManager,
				fax: account.company.fax
			};

			editedAccount = {
				userID: account.userId,
				roleID: account.roleId,
				username: accountData.username,
				email: accountData.email,
				firstName: accountData.firstName,
				lastName: accountData.lastName,
				title: accountData.title,
				phoneNumber: accountData.phoneNumber,
				company: company,
				shipping: shipping,
				newsletter: accountData.newsletter
			};

		} else {
			shipping = {
				address1: facilityData.address1,
				address2: facilityData.address2,
				city: facilityData.city,
				county: selectedCounty,
				state: selectedRegion[1],
				country: selectedCountry[1],
				zip: facilityData.zip
			};

			company = {
				rewards: account.company.rewards,
				name: facilityData.name,
				phoneNumber: facilityData.phoneNumber,
				admin: facilityData.admin,
				officeManager: {
					name: facilityData.officeManagerName,
					email: facilityData.officeManagerEmail
				},
				fax: facilityData.fax
			};

			editedAccount = {
				userID: account.userId,
				roleID: account.roleId,
				username: account.username,
				email: account.email,
				firstName: account.firstName,
				lastName: account.lastName,
				title: account?.title || '',
				phoneNumber: account.phoneNumber,
				company: company,
				shipping: shipping,
				newsletter: account.newsletter ? '1' : undefined
			};
		}
		
		const hasChangedAccountName = 
			editedAccount.firstName !== account.firstNameMain ||
			editedAccount.lastName !== account.lastNameMain;

		const recaptchaToken = await handleReCAPTCHA(recaptchaRef);

		dispatchEditAccount(
			editedAccount,
			hasChangedAccountName,
			recaptchaToken
		)
	};
	
	/*Runs initial fetches for the page */
	useEffect(() => {
		initialize();
	}, []);
	
	/*Checks if user is authenticated. If false, pushes to landing page */
	useEffect(() => {
		if (!isAuthenticated) history.push('/');
	}, [isAuthenticated]);
	
	/*Replaces default values with values from state */
	useEffect(updateAccountInfo, [account]);

	if (isLoading) return (
		<section className='edit-account'>
			<LoadingIcon />
		</section>
	);

	return (
		<section className='edit-account'>
			<h1 className='edit-account__heading'>Account</h1>

			{/*Account info form */}
			<form className='edit-account__auth-info' onSubmit={(e) => onSubmit(e, 'account')}>
				<h3>Primary Account Info</h3>
				<TextField
					label='Username'
					name='username'
					value={accountData.username}
					onChange={(e) => onChangeInput(e, 'account')}
					required
				/>
				<TextField
					type='email'
					label='Email'
					name='email'
					value={accountData.email}
					onChange={(e) => onChangeInput(e, 'account')}
					required
				/>
				<TextField
					label='First Name'
					name='firstName'
					value={accountData.firstName}
					onChange={(e) => onChangeInput(e, 'account')}
					required
				/>
				<TextField
					label='Last Name'
					name='lastName'
					value={accountData.lastName}
					onChange={(e) => onChangeInput(e, 'account')}
					required
				/>
				<FormControl>
					<InputLabel id='edit-account-title'>Title</InputLabel>
					<Select
						labelId='edit-account-title'
						label='title'
						value={accountData.title}
						onChange={(e) => setAccountData({...accountData, title: e.target.value})}
						required
					>
						<MenuItem value='Administration'>Administration</MenuItem>
						<MenuItem value='Business Office Staff'>Business Office Staff</MenuItem>
						<MenuItem value='Social Services'>Social Services</MenuItem>
						<MenuItem value='Activities/Recreation'>Activities/Recreation</MenuItem>
						<MenuItem value='Central Supply/Housekeeping'>Central Supply/Housekeeping</MenuItem>
						<MenuItem value='Laundry'>Laundry</MenuItem>
					</Select>
				</FormControl>
				<TextField
					label='Phone Number'
					name='phoneNumber'
					value={accountData.phoneNumber}
					onChange={(e) => onChangeInput(e, 'account')}
					required
				/>
				<div className='edit-account__newsletter'>
					Newsletter Subscription
					<span className='switch'>
						<input
							type='checkbox'
							name='newsletter'
							checked={accountData.newsletter === '1' ? true : false}
							onChange={(e) => setAccountData({
								...accountData,
								newsletter: e.target.checked ? '1' : undefined
							})}
							id='newsletter-checkbox'
							className='switch__checkbox'
						/>
						<label htmlFor='newsletter-checkbox' className='switch__label' />
					</span>
				</div>
				<input className='btn btn--secondary' type='submit' value='Save Account Info'/>
			</form>

			{/*Facility info form */}
			<form className='edit-account__facility-info' onSubmit={(e) => onSubmit(e, 'facility')}>
					<h3>Facility Info</h3>
					<TextField
						label='Facility Name'
						name='name'
						value={facilityData.name}
						onChange={(e) => onChangeInput(e, 'facility')}
						required
					/>
					<TextField
						label='Admin'
						name='admin'
						value={facilityData.admin}
						onChange={(e) => onChangeInput(e, 'facility')}
						required
					/>
					<TextField
						label='Business Office Manager'
						name='officeManagerName'
						value={facilityData.officeManagerName}
						onChange={(e) => onChangeInput(e, 'facility')}
						required
					/>
					<TextField
						label={`Business Office Manager's Email`}
						name='officeManagerEmail'
						value={facilityData.officeManagerEmail}
						onChange={(e) => onChangeInput(e, 'facility')}
						required
					/>
					<span className='edit-account__fine-print edit-account__fine-print--office-manager'>
						*Business Office Manager (BOM) will get a copy
						of all invoices that are placed for this
						facility.
					</span>
					<TextField
						label='Phone Number'
						name='phoneNumber'
						value={facilityData.phoneNumber}
						inputProps={{minLength: 7}}
						onChange={(e) => onChangeInput(e, 'facility')}
						required
					/>
					<TextField
						label='Fax'
						name='fax'
						value={facilityData.fax}
						onChange={(e) => onChangeInput(e, 'facility')}
					/>
					<TextField
						label='Address 1'
						name='address1'
						value={facilityData.address1}
						onChange={(e) => onChangeInput(e, 'facility')}
						disabled
					/>
					<TextField
						label='Address 2'
						name='address2'
						value={facilityData.address2}
						onChange={(e) => onChangeInput(e, 'facility')}
						disabled
					/>
					<TextField
						label='City'
						name='city'
						value={facilityData.city}
						onChange={(e) => onChangeInput(e, 'facility')}
						disabled
					/>
					<SelectCounty
						selectedCountry={selectedCountry}
						selectedRegion={selectedRegion}
						selectedCounty={selectedCounty}
						setSelectedCounty={setSelectedCounty}
						disabled
						required={false}
					/>
					<SelectRegion
						selectedCountry={selectedCountry}
						selectedRegion={selectedRegion}
						setSelectedRegion={setSelectedRegion}
						disabled
						required={false}
					/>
					<TextField
						type='number'
						label='Zip Code'
						name='zip'
						value={facilityData.zip}
						onChange={(e) => onChangeInput(e, 'facility')}
						disabled
					/>
					<SelectCountry
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						disabled
						required={false}
					/>
					<span className='edit-account__fine-print edit-account__fine-print--office-manager'>
						*If you need to change the address for this facility, please contact <a type='email' href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a>
					</span>
					<div className='edit-account__agreement'>
						I understand that terms are net amount due ten days from when we receive the 
						order. The facility is responsible for all orders originated by the authorized 
						facility staff members. You can review a list of authorized users on your 
						Edit Account screen.
						<span className='switch'>
							<input
								type='checkbox'
								checked={hasAcceptedAgreement}
								onChange={() => setHasAcceptedAgreement(!hasAcceptedAgreement)}
								id='agreement-checkbox'
								className='switch__checkbox'
							/>
							<label htmlFor='agreement-checkbox' className='switch__label' />
						</span>
					</div>
					<input
						className='btn btn--secondary'
						type='submit'
						value='Save Facility Info'
						disabled={!hasAcceptedAgreement}
					/>
			</form>

			{/*Sub-accounts list */}
			<div className='edit-account__sub-accounts'>
				<h3>Facility Authorized Users</h3>
				<p className='edit-account__sub-accounts-desc'>
					Please enter any other co-workers at your facility
					that would be able to shop for your residents.<br />
					We will then send them a link to their email
					address to complete setting up a user profile.
				</p>
				{
					subAccounts.length > 0 &&
					subAccounts.map((sub) =>
						<span className='edit-account__sub-account'>
							<button
								className='edit-account__sub-account-delete'
								onClick={() => onDeleteSubAccount(sub.id)}
							>
								<TrashIcon />
							</button>
							<TextField
								label='First Name'
								name='firstName'
								variant='standard'
								value={sub.firstName}
								onChange={(e) => onChangeInputSubAccount(e, sub.id)}
							/>
							<TextField
								label='Last Name'
								name='lastName'
								variant='standard'
								value={sub.lastName}
								onChange={(e) => onChangeInputSubAccount(e, sub.id)}
							/>
							<TextField
								label='Email'
								name='email'
								variant='standard'
								value={sub.email}
								onChange={(e) => onChangeInputSubAccount(e, sub.id)}
							/>
							<FormControl>
								<InputLabel id='edit-subaccount-title'>Title</InputLabel>
								<Select
									labelId='edit-subaccount-title'
									label='title'
									variant='standard'
									value={sub.title}
									onChange={(e) => setSubAccounts(subAccounts.map((subAcc) => {
										if (subAcc.id === sub.id) {
											return {
												...subAcc,
												title: e.target.value
											}
										} else return subAcc;
									}))}
									required
								>
									<MenuItem value='Administration'>Administration</MenuItem>
									<MenuItem value='Business Office Staff'>Business Office Staff</MenuItem>
									<MenuItem value='Social Services'>Social Services</MenuItem>
									<MenuItem value='Activities/Recreation'>Activities/Recreation</MenuItem>
									<MenuItem value='Central Supply/Housekeeping'>Central Supply/Housekeeping</MenuItem>
									<MenuItem value='Laundry'>Laundry</MenuItem>
								</Select>
							</FormControl>
							<button
								className='btn btn--secondary'
								onClick={() => onEditSubAccount(sub.id)}
							>
								Save
							</button>
						</span>
					)
				}
				<span className='edit-account__sub-account edit-account__sub-account--new'>
					<span>
						{/*This span allows CSS to correctly align this new sub-account with the others */}
					</span>
					<TextField
						label='First Name'
						name='firstName'
						variant='standard'
						value={newSubAccount.firstName}
						onChange={(e) => setNewSubAccount({
							...newSubAccount,
							[e.target.name]: e.target.value
						})}
					/>
					<TextField
						label='Last Name'
						name='lastName'
						variant='standard'
						value={newSubAccount.lastName}
						onChange={(e) => setNewSubAccount({
							...newSubAccount,
							[e.target.name]: e.target.value
						})}
					/>
					<TextField
						label='Email'
						name='email'
						variant='standard'
						value={newSubAccount.email}
						onChange={(e) => setNewSubAccount({
							...newSubAccount,
							[e.target.name]: e.target.value
						})}
					/>
					<FormControl>
						<InputLabel id='edit-account-title'>Title</InputLabel>
						<Select
							labelId='edit-account-title'
							label='title'
							variant='standard'
							value={newSubAccount.title}
							onChange={(e) => setNewSubAccount({
								...newSubAccount,
								title: e.target.value
							})}
							required
						>
							<MenuItem value='Administration'>Administration</MenuItem>
							<MenuItem value='Business Office Staff'>Business Office Staff</MenuItem>
							<MenuItem value='Social Services'>Social Services</MenuItem>
							<MenuItem value='Activities/Recreation'>Activities/Recreation</MenuItem>
							<MenuItem value='Central Supply/Housekeeping'>Central Supply/Housekeeping</MenuItem>
							<MenuItem value='Laundry'>Laundry</MenuItem>
						</Select>
					</FormControl>
					<button
						className='btn btn--secondary'
						onClick={onAddSubAccount}
					>
						Add
					</button>
				</span>
			</div>
		</section>
	);
};

export default EditAccount;
