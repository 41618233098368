import {blockStyles} from './draftConfig';
import StyleButton from './StyleButton';
import ControlsComponent from './entities/ControlsComponent';
import React, {Dispatch, SetStateAction} from 'react';
import {Editor, EditorState, Modifier, RichUtils} from 'draft-js';

const BlockControls: ControlsComponent = (props) => {
  const {editorState, setEditorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const toggleBlock = (toggledBlock: string) => setEditorState(
    RichUtils.toggleBlockType(
      editorState,
      toggledBlock
    )
  );

  return (
    <div className='RichEditor-controls'>
      {blockStyles.map((option) =>
        <StyleButton
          key={option.label}
          active={option.style === blockType}
          label={option.label}
          onToggle={toggleBlock}
          style={option.style}
          type='block'
        />
      )}
    </div>
  );
};

export default BlockControls;