import App from './application/ui/App';
import './application/ui/styling/index.min.css';
import reportWebVitals from './reportWebVitals';
import {isDebugging} from './application/utility';
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router} from 'react-router-dom';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

/*
  If you want to start measuring performance in your app, pass a function
  to log results (for example: reportWebVitals(console.log))
  or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
*/

if (isDebugging) {
  reportWebVitals(console.log);
}
