import LandingController from './LandingController';
import SSSPerk from './SSSPerk';
import Quote from './Quote';
import RegisterPopup from './RegisterPopup';
import Carousel from '../../utility/Carousel';
import Image from '../../utility/Image';
import invoiceExample from '../../media/Example-Invoice.png';
import trustAcctExample from '../../media/Example-Trust_Acct.png';
import labelExample from '../../media/Example-Label.png';
import invoiceListExample from '../../media/Example-Invoices.png';
import registerReminder from '../../media/Register_Reminder-min.jpg';
import giftCardsExample from '../../media/Rewards_Perk.png';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';

const Landing = () => {
  const {
    account,
    isPopupActive,
    curCarousel,
    curCarouselIndex,
    imageLocation,
    storeCarousel,
    carousel,
    init,
    onAccountChange,
    onClosePopup,
    setCarousel,
    setCurCarousel,
    setCurCarouselIndex,
    onChangeCurCarouselIndex
  } = LandingController();

  useEffect(init, []);
  useEffect(onAccountChange, [account]);
  useEffect(onChangeCurCarouselIndex, [curCarouselIndex]);
  useEffect(() => {
    setCarousel(storeCarousel)
    // setCurCarousel(storeCarousel[0])
  }, [storeCarousel])

  return (
    <section className='landing'>
      <Carousel
        imageLocation={imageLocation}
        carouselItems={carousel}
        setCurrentIndex={setCurCarouselIndex}
      />
      <div className='landing__hero'>
        <h1>{curCarousel.headerText}</h1>
        <h2>{curCarousel.subHeaderText}</h2>
        { curCarousel.buttonText !== ' ' &&
          <Link
            className='btn btn--primary'
            to={curCarousel.link}
          >
            {curCarousel.buttonText}
          </Link>
        }
      </div>
      {
        isPopupActive &&
        <RegisterPopup onClose={onClosePopup} />
      }
      <div className='landing__perks'>
        <h2>Discover Why Facilities Choose Us</h2>
        <SSSPerk
          img={invoiceExample}
          imgAlt='invoice'
          header='Invoices for Each Resident'
          body={[
            'Business Office Managers love this feature!',
            'This allows them to file each invoice separately by resident.'
          ]}
        />
        <SSSPerk
          img={trustAcctExample}
          imgAlt='payment dropdown with trust account and an option'
          header='Pay with Trust Accounts'
          body={[
            `It's a simple click!`,
            'Shopping for multiple residents? Just finalize each order and cut one check.'
          ]}
        />
        <SSSPerk
          img={labelExample}
          imgAlt='label on clothing'
          header='Personalized Labeling'
          body={[
            'Easy to read and heat pressed.',
            'These industrial-ready labels help clothing get back to their rightful owner.'
          ]}
        />
        <SSSPerk
          img={invoiceListExample}
          imgAlt='list of invoices'
          header='Invoice History'
          body={[
            'Look back at all previous orders and reprint as needed.'
          ]}
        />
        <SSSPerk
          img={giftCardsExample}
          imgAlt='crown'
          header='Rewards Program'
          body={[
            `Earn $100 worth of rewards for every $1000 spent!`,
            'Many facilities use this to care for residents who have limited resources.'
          ]}
        />
      </div>
      <div className='landing__quotes'>
        <div className='landing__quotes-aside'>
          <h2>Thank You for the Kind Words!</h2>
          {/* <p>More Lorem Ipsum</p> */}
        </div>
        <div className='landing__quotes-main'>
          <Quote
            // author='test'
            // location='location'
            quote={`In my ten-year career as a BOM, I've never found a service as smart, convenient, and above all, USEFULL as Senior Shopping Service...I've felt like I have developed a personal relationship with SSS' stellar customer service representatives.`}
          />
          <Quote
            // author='test'
            // location='location'
            quote={`My resident is so happy with her items. She was walking down the hall like a super model. I just wanted to thank you for making my lady smile today.`}
          />
          <Quote
            // author='test'
            // location='location'
            quote={`Thank you so much for your quick response! I truly appreciate the services you provide! You are amazing!`}
          />
        </div>
      </div>
      <div className='landing__reminder'>
        <Image src={registerReminder} alt='' />
        <div className='landing__reminder-aside'>
          <h2>Join Our Family</h2>
          <Link to='/register' className='btn btn--primary'>Register</Link>
        </div>
      </div>
      <div className='landing__req-catalog'>
        <h2>Need a Catalog?</h2>
        <p>Allow us to brighten your resident's day.</p>
        <Link to='/info/freeCatalog' className='btn btn--primary'>Request Catalog</Link>
      </div>
    </section>
  )
};

export default Landing;
