import React from 'react';
import {useHistory} from 'react-router-dom';

type Props = {
  img: string;
  imgAlt: string;
  header: string;
  body: Array<string>;
};

const SSSPerk = ({img, imgAlt, header, body}: Props) => {
  const history = useHistory();
  const onClick = () => history.push('/info/facility');

  return (
    <span onClick={onClick}>
      <img src={img} alt={imgAlt}/>
      <h4>{header}</h4>
      {
        body.map((line, index) =>
          <p key={index}>{line}</p>
        )
      }
    </span>
  );
};

export default SSSPerk;