import {State} from 'application/store';
import getAdmins from 'application/store/admin/actions/getAdmins';
import setAlert from 'application/store/alerts/actions/setAlert';
import type AdminToEdit from 'application/store/admin/entities/AdminToEdit';
import {addAdminAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const addAdmin = (set: SetState<State>, get: GetState<State>) =>
	async (admin: Omit<AdminToEdit, 'id'>) => {
			
		const res = await addAdminAPI({
			username: admin.username,
			pword: admin.password,
			pwordConfirm: admin.passwordConfirm,
			superAdmin: admin.isSuperAdmin ? 1 : 0
		});
		
		getAdmins(set, get)();

		if (res !== null ) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully added admin'
		});
	};

export default addAdmin;