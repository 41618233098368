import ReactGA from 'react-ga4';
import { isDebugging } from '../../../utility';

const reactGA = {
  init: () => ReactGA.initialize('G-VW4XBVFX9L'),
  
  newPage: (title: string, path: string, search = '') => {
    const hitType = 'pageview';
    const expectsSearch = title.endsWith(':');

    if (expectsSearch) {
      if (search !== '') {
        title = title + ` ${search}`;
      }
    }

    if (isDebugging) console.log('GA:', path, search, title);//TESTING

    ReactGA.send({hitType, page: path, title});
  }
};

export default reactGA;