import Cart from '../entities/Cart';
import Order from '../entities/Order';
import CartStatus from '../entities/CartStatus';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {getCartsAPI, GetCartRes} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const getCarts = (set: SetState<State>, get: GetState<State>) => async () => {
  try {
    const account = get().account;
    if (!account) throw new Error('No account found');

    set((state) => ({
      checkout: {
        ...state.checkout,
        isRetrieving: true
      }
    }));
    
    const res = await getCartsAPI(account.userId);
    const carts: GetCartRes = res.data;

    const modifiedCarts: Array<Cart> = carts.map((cart) => {
      const defaultStatus: CartStatus = 'Still Shopping';
      let modifiedOrders: Array<Order>;
      let modifiedCart: Cart;

      //Create the appropriate cart + order based on account.roleId
      switch (account.roleId) {
        case 3:
          if (!cart.residentID) throw new Error('Facility order is missing resident id');
    
          if (cart.facility_orders) {
            modifiedOrders = cart.facility_orders.map((order) => ({
              id: order.order_ID,
              invoiceId: order.invoiceID,
              product: {
                id: order.prod_itemID,
                name: order.prod_name,
                size: order.prod_size || undefined,
                option: order.prod_color || undefined,
                price: order.prod_price,
                shippingPrice: order.prod_shipping.toString(),
                quantity: order.quantity,
              },
              lineTotal: order.lineTotal
            }));
    
            modifiedCart = {
              residentId: cart.residentID,
              status: defaultStatus,
              isAcceptingSubs: true,
              orders: modifiedOrders,
              totals: {
                subTotal: cart.facility_orders[0].subTotal,
                tax: cart.facility_orders[0].tax,
                taxrate: cart.facility_orders[0].taxrate,
                shipping: cart.facility_orders[0].shipping,
                grandTotal: cart.facility_orders[0].grandTotal
              }
            };
    
          } else {
            //default facility cart
            modifiedCart = {
              residentId: cart.residentID,
              status: defaultStatus,
              isAcceptingSubs: true,
              orders: [],
              totals: {
                subTotal: '0',
                tax: '0',
                shipping: '0',
                grandTotal: '0'
              }
            };
          }
          break;
        
        default:
          if (cart.orders) {
            modifiedOrders = cart.orders.map((order) => ({
              id: order.order_ID,
              invoiceId: order.invoice_ID,
              product: {
                id: order.prod_itemID,
                name: order.prod_name,
                size: order.prod_size || undefined,
                option: order.prod_color || undefined,
                price: order.prod_price.toString(),
                shippingPrice: order.prod_shipping,
                quantity: order.quantity,
              },
              lineTotal: order.lineTotal
            }));
            
            modifiedCart = {
              residentId: 0,
              status: 'Still Shopping',
              isAcceptingSubs: true,
              orders: modifiedOrders,
              totals: {
                subTotal: cart.orders[0].subTotal,
                tax: cart.orders[0].tax,
                shipping: cart.orders[0].shipping,
                grandTotal: cart.orders[0].grandTotal,
              }
            };
            
          } else {
            //default individual cart
            modifiedCart = {
              residentId: 0,
              status: defaultStatus,
              isAcceptingSubs: true,
              orders: [],
              totals: {
                subTotal: '0',
                tax: '0',
                shipping: '0',
                grandTotal: '0'
              }
            };
          }
          break;
      }

      return modifiedCart;
    });

    set((state) => ({
      checkout: {
        ...state.checkout,
        carts: modifiedCarts,
        isRetrieving: false
      }
    }));

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get carts store error:', err);//TESTING
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err?.response?.data?.message || 'Failed to retrieve carts'
		});  
  }
};

export default getCarts;