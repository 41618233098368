import {styles} from './draftConfig';
import React, {MouseEvent, useEffect, useState} from 'react';

type Props = {
  label: string;
  style: string;
  onToggle: (string: string) => void;
  active: boolean;
  type: 'block' | 'inline' | 'color';
};

const StyleButton = (props: Props) => {
  const {active, label, style, onToggle, type} = props;
  const [curButtonStyle, setCurButtonStyle] = useState(styles.styleButton);
  const defaultClassName = 'RichEditor-styleButton';
  const activeClassName = 'RichEditor-activeButton';
  const [curClassName, setCurClassName] = useState(defaultClassName);

  const onMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    onToggle(style);
  };

  useEffect(() => {
    if (type === 'color') {
      setCurButtonStyle(
        active ?
        {
          ...styles.styleButton,
          backgroundColor: 'var(--color-primary-light-3)'
        } :
        styles.styleButton
      );
    } else {
      setCurClassName(
        active ?
        `${defaultClassName} ${activeClassName}` :
        defaultClassName
      );
    }
  }, [active]);

  return (
    <span
      className={type === 'color' ? '' : curClassName}
      // style={curButtonStyle}
      onMouseDown={onMouseDown}
    >
      {label}
    </span>
  );
};

export default StyleButton;