import AccountFields from './entities/AccountFields';
import {useStore} from '../../../../../store';
import React, 
{
  Dispatch,
  SetStateAction,
  ChangeEvent,
  FormEvent
} from 'react'
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel
} from '@mui/material';

type Props = {
  accountFields: AccountFields;
  setAccountFields: Dispatch<SetStateAction<AccountFields>>;
  registerProgress: number;
  setRegisterProgress: Dispatch<SetStateAction<number>>;
};

const AccountForm = (props: Props) => {
  const {
    accountFields,
    setAccountFields,
    registerProgress,
    setRegisterProgress
  } = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);

	const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		setAccountFields({
			...accountFields,
			[e.target.name]: e.target.value
		});
	};

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (accountFields.plainPassword !== accountFields.plainPasswordConfirm) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Passwords must match'
			});
			return;
		}

    setRegisterProgress(registerProgress + 1);
  };

  return (
    <form className='register__form' onSubmit={onSubmit}>
      <p className='register__acc-heading'>Let's create your account</p>
      <TextField
        className='register__username'
        label='Username'
        name='username'
        value={accountFields.username}
        onChange={onChangeInput}
        required
      />
      <TextField
        className='register__email'
        label='Email'
        name='email'
        type='email'
        inputMode='email'
        value={accountFields.email}
        onChange={onChangeInput}
        required
      />
      <TextField
        className='register__password'
        type='password'
        label='Password'
        name='plainPassword'
        inputProps={{minLength: 8}}
        value={accountFields.plainPassword}
        onChange={onChangeInput}
        required
      />
      <TextField
        className='register__password-confirm'
        type='password'
        label='Confirm Password'
        name='plainPasswordConfirm'
        value={accountFields.plainPasswordConfirm}
        onChange={onChangeInput}
        inputProps={{minLength: 8}}
        required
      />        
      <TextField
        className='register__fname'
        label='First Name'
        name='firstName'
        value={accountFields.firstName}
        onChange={onChangeInput}
        required
      />
      <TextField
        className='register__lname'
        label='Last Name'
        name='lastName'
        value={accountFields.lastName}
        onChange={onChangeInput}
        required
      />
      <FormControl className='register__title'>
        <InputLabel id='register-title'>Title</InputLabel>
        <Select
          labelId='register-title'
          label='title'
          name='title'
          value={accountFields.title}
          onChange={(e) => setAccountFields({...accountFields, title: e.target.value})}
          required
        >
          <MenuItem value='Administration'>Administration</MenuItem>
          <MenuItem value='Business Office Staff'>Business Office Staff</MenuItem>
          <MenuItem value='Social Services'>Social Services</MenuItem>
          <MenuItem value='Activities/Recreation'>Activities/Recreation</MenuItem>
          <MenuItem value='Central Supply/Housekeeping'>Central Supply/Housekeeping</MenuItem>
          <MenuItem value='Laundry'>Laundry</MenuItem>
        </Select>
      </FormControl>
      {/* <TextField
        label='Title'
        name='title'
        value={accountFields.title}
        onChange={onChangeInput}
        required
      /> */}
      <TextField
        className='register__phone'
        label='Phone Number'
        type='tel'
        inputMode='tel'
        name='phoneNumber'
        value={accountFields.phoneNumber}
        onChange={onChangeInput}
        required
      />
      <input
        className='btn btn--primary register__submit'
        type='submit'
        name='submit'
        value='Continue'
      />
    </form>
  );
};

export default AccountForm;