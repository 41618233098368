import GetCartRes from '../entities/GetCartRes';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

const getCartsPort = '/getCart';

const getCartsAPI = async (userId: string): Promise<AxiosResponse<GetCartRes>> => {
  const res = await api.get(getCartsPort, {params: {userID: Number(userId)}});
  
  if (isDebugging) {
    console.log(`${getCartsPort} response:`, res);//TESTING
  }
  
  return res;
};

export default getCartsAPI;