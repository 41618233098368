import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Response = Array<
  {
    state: string;
    county: string;
  }
>;

const getStateCountyListPort = '/getStateCountyList';

const getStateCountyListAPI = async (): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getStateCountyListPort);

  if (isDebugging) {
    console.log(`${getStateCountyListPort} response:`, res);//TESTING
  }

  return res;
};

export default getStateCountyListAPI;