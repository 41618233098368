import {State} from 'application/store';
import {getTaxRatesAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

/**Retrieves tax rates for all states/counties, then updates the store. */
export const getTaxRates = (set: SetState<State>, get: GetState<State>) =>
	async () => {
		const res = await getTaxRatesAPI();

		if (res === null) return;
		
		set(() => ({
			taxRates: res.map((tax) => ({
				state: tax.state,
				county: tax.county,
				tax: tax.tax_rate
			}))
		}));
	};

export default getTaxRates;