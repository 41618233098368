import {State} from '../..';
import {SetState} from 'zustand';

const getBreadcrumbs = (set: SetState<State>) => () => {
  const breadcrumbs = localStorage.getItem('breadcrumbs');

  if (breadcrumbs) {
    set({
      breadcrumbs: JSON.parse(breadcrumbs)
    });
  }
};

export default getBreadcrumbs;