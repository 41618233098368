import getLandingCarousel from './getLandingCarousel';
import {State} from '../..';
import LandingCarousel from '../entities/LandingCarousel';
import setAlert from '../../alerts/actions/setAlert';
import {editLandingCarouselTESTAPI, editLandingCarouselTESTReq} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

/**Edits carousel for landing page */
export const editLandingCarousel = (set: SetState<State>, get: GetState<State>) =>
	async (carousels: Array<LandingCarousel>) => {

		try {
      // const storeCarousels = get().carousels.landing;
      const carouselsToSend = carousels.map((c) => {
        // const curCarousel = c.id === carousel.id ? carousel : c;
        return {
          id: c.id,
          image: c.image.replace("'", "''"),
          link: c.link.replace("'", "''"),
          button_text: c.buttonText.replace("'", "''"),
          headline: c.headerText.replace("'", "''"),
          subhead: c.subHeaderText.replace("'", "''"),
          hide: c.isHidden ? 1 : 0,
          orderNum: c.id
        }
      });

			const res = await editLandingCarouselTESTAPI(carouselsToSend);

			getLandingCarousel(set, get)(true);

      setAlert(set, get)({
        status: 'success',
        header: 'Success',
        text: 'Successfully updated carousels'
      });

		} catch (err: any | unknown) {
      if (isDebugging) {
        console.log(`editLandingCarousel error:`, err);//TESTING
      }
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response?.data?.message || 'Failed to edit landing carousel'
			});
		}
	};

export default editLandingCarousel;