const defaultNav = [
  {
      "dept_ID": 1,
      "dept": "Women",
      "subDepartments": [
          {
              "subDept_ID": 1,
              "subDept": "Clothing",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 1,
                      "superSubDept": "Outfits"
                  },
                  {
                      "superSubDept_ID": 2,
                      "superSubDept": "Tops"
                  },
                  {
                      "superSubDept_ID": 3,
                      "superSubDept": "Bottoms"
                  },
                  {
                      "superSubDept_ID": 4,
                      "superSubDept": "Sweaters"
                  },
                  {
                      "superSubDept_ID": 5,
                      "superSubDept": "Dresses-Dusters"
                  },
                  {
                      "superSubDept_ID": 6,
                      "superSubDept": "Nightwear"
                  },
                  {
                      "superSubDept_ID": 7,
                      "superSubDept": "Underwear"
                  },
                  {
                      "superSubDept_ID": 8,
                      "superSubDept": "Outerwear"
                  },
                  {
                      "superSubDept_ID": 9,
                      "superSubDept": "4X-6X PLUS"
                  },
                  {
                      "superSubDept_ID": 10,
                      "superSubDept": "Value Packages"
                  }
              ]
          },
          {
              "subDept_ID": 2,
              "subDept": "Footwear",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 11,
                      "superSubDept": "Shoes"
                  },
                  {
                      "superSubDept_ID": 12,
                      "superSubDept": "Slippers"
                  },
                  {
                      "superSubDept_ID": 13,
                      "superSubDept": "Socks"
                  }
              ]
          },
          {
              "subDept_ID": 3,
              "subDept": "Accessories",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 14,
                      "superSubDept": "Baskets"
                  },
                  {
                      "superSubDept_ID": 15,
                      "superSubDept": "Shavers"
                  },
                  {
                      "superSubDept_ID": 16,
                      "superSubDept": "Watches"
                  },
                  {
                      "superSubDept_ID": 17,
                      "superSubDept": "Jewelry"
                  }
              ]
          }
      ]
  },
  {
      "dept_ID": 2,
      "dept": "Men",
      "subDepartments": [
          {
              "subDept_ID": 4,
              "subDept": "Clothing",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 18,
                      "superSubDept": "Outfits"
                  },
                  {
                      "superSubDept_ID": 19,
                      "superSubDept": "Tops"
                  },
                  {
                      "superSubDept_ID": 20,
                      "superSubDept": "Bottoms"
                  },
                  {
                      "superSubDept_ID": 21,
                      "superSubDept": "Sweaters"
                  },
                  {
                      "superSubDept_ID": 22,
                      "superSubDept": "Nightwear"
                  },
                  {
                      "superSubDept_ID": 23,
                      "superSubDept": "Underwear"
                  },
                  {
                      "superSubDept_ID": 24,
                      "superSubDept": "Outerwear"
                  },
                  {
                      "superSubDept_ID": 25,
                      "superSubDept": "4X-6X PLUS"
                  },
                  {
                      "superSubDept_ID": 26,
                      "superSubDept": "Value Package"
                  }
              ]
          },
          {
              "subDept_ID": 5,
              "subDept": "Footwear",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 27,
                      "superSubDept": "Shoes"
                  },
                  {
                      "superSubDept_ID": 28,
                      "superSubDept": "Slippers"
                  },
                  {
                      "superSubDept_ID": 29,
                      "superSubDept": "Socks"
                  }
              ]
          },
          {
              "subDept_ID": 6,
              "subDept": "Accessories",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 30,
                      "superSubDept": "Baskets"
                  },
                  {
                      "superSubDept_ID": 31,
                      "superSubDept": "Razors"
                  },
                  {
                      "superSubDept_ID": 32,
                      "superSubDept": "Accessories"
                  }
              ]
          }
      ]
  },
  {
      "dept_ID": 3,
      "dept": "Furnishings",
      "subDepartments": [
          {
              "subDept_ID": 7,
              "subDept": "Furniture",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 37,
                      "superSubDept": "Recliners"
                  },
                  {
                      "superSubDept_ID": 38,
                      "superSubDept": "Lift Chairs"
                  },
                  {
                      "superSubDept_ID": 39,
                      "superSubDept": "Dressers"
                  },
                  {
                      "superSubDept_ID": 40,
                      "superSubDept": "Nightstands"
                  },
                  {
                      "superSubDept_ID": 41,
                      "superSubDept": "Overbed Table"
                  },
                  {
                      "superSubDept_ID": 42,
                      "superSubDept": "Room Packages"
                  }
              ]
          },
          {
              "subDept_ID": 8,
              "subDept": "Decor",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 43,
                      "superSubDept": "Art Work"
                  },
                  {
                      "superSubDept_ID": 44,
                      "superSubDept": "Clocks"
                  },
                  {
                      "superSubDept_ID": 45,
                      "superSubDept": "Misc"
                  }
              ]
          },
          {
              "subDept_ID": 9,
              "subDept": "Electronics",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 46,
                      "superSubDept": "TV's"
                  },
                  {
                      "superSubDept_ID": 47,
                      "superSubDept": "Radios"
                  },
                  {
                      "superSubDept_ID": 48,
                      "superSubDept": "DVD Players"
                  },
                  {
                      "superSubDept_ID": 49,
                      "superSubDept": "Accessories"
                  },
                  {
                      "superSubDept_ID": 50,
                      "superSubDept": "Razors"
                  }
              ]
          },
          {
              "subDept_ID": 10,
              "subDept": "Beds",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 51,
                      "superSubDept": "Hospital Bed"
                  },
                  {
                      "superSubDept_ID": 52,
                      "superSubDept": "Mattresses"
                  }
              ]
          },
          {
              "subDept_ID": 11,
              "subDept": "Bedding",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 53,
                      "superSubDept": "Comforters"
                  },
                  {
                      "superSubDept_ID": 54,
                      "superSubDept": "Quilts"
                  },
                  {
                      "superSubDept_ID": 55,
                      "superSubDept": "Accessories"
                  }
              ]
          }
      ]
  },
  {
      "dept_ID": 4,
      "dept": "Mobility",
      "subDepartments": [
          {
              "subDept_ID": 12,
              "subDept": "Wheelchairs",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 13,
              "subDept": "Geri Chairs",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 14,
              "subDept": "Cushions",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 15,
              "subDept": "Incontinent Pads",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 16,
              "subDept": "Adaptable Accessories",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 56,
                      "superSubDept": "Clothing Protectors"
                  },
                  {
                      "superSubDept_ID": 57,
                      "superSubDept": "Fanny Pack"
                  },
                  {
                      "superSubDept_ID": 58,
                      "superSubDept": "Foot Pillow"
                  },
                  {
                      "superSubDept_ID": 59,
                      "superSubDept": "Misc"
                  }
              ]
          }
      ]
  },
  {
      "dept_ID": 5,
      "dept": "Personal",
      "subDepartments": [
          {
              "subDept_ID": 17,
              "subDept": "Bath Baskets",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 18,
              "subDept": "Watches",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 19,
              "subDept": "Razors",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 20,
              "subDept": "Perfume/Cologne",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 21,
              "subDept": "Jewelry",
              "superSubDepartments": []
          }
      ]
  },
  {
      "dept_ID": 6,
      "dept": "Sensory",
      "subDepartments": [
          {
              "subDept_ID": 22,
              "subDept": "Plush",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 23,
              "subDept": "Activity",
              "superSubDepartments": [
                  {
                      "superSubDept_ID": 61,
                      "superSubDept": "Bear"
                  },
                  {
                      "superSubDept_ID": 62,
                      "superSubDept": "Twiddle Sleeve"
                  }
              ]
          },
          {
              "subDept_ID": 24,
              "subDept": "Dolls",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 25,
              "subDept": "Puzzles",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 26,
              "subDept": "Kits",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 27,
              "subDept": "Books",
              "superSubDepartments": []
          },
          {
              "subDept_ID": 28,
              "subDept": "Sensory Therapy",
              "superSubDepartments": []
          }
      ]
  }
];

export default defaultNav;