import logout from './logout';
import getAccountDetails from './getAccountDetails';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {authenticateAccountAPI} from '../../../api';
import {authService, isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const login = (set: SetState<State>, get: GetState<State>) =>
	async (usernameOrEmail: string, hashedPassword: string, captchaToken: string, isImplicit = false, isSilent = false) => {
		const accountInStore = get().account;
		
		try {
			if (accountInStore) {
				logout(set);
			}

			const res = await authenticateAccountAPI(usernameOrEmail, hashedPassword, captchaToken);
			
      const {userID, roleID, userToken, firstName, lastName} = res.data;

			authService.credentials.set(usernameOrEmail, hashedPassword);
			authService.token.set(userToken);

			set({
				isAuthenticated: true,
        account: {
          userId: userID,
          roleId: roleID,
          firstName: firstName,
					lastName: lastName
        }
      });

			if (!usernameOrEmail.includes('tempAccount') && !isSilent) {
				//If it is not a guest account or a silent call, notify them that they were logged in
				setAlert(set, get)({
					status: 'success',
					header: 'Logged In',
					text: 'Successfully logged in'
				});
			}

			await getAccountDetails(set, get)();

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log('login store error:', err.response.data);
			}
			logout(set);
			if (isImplicit || isSilent) return;
			
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to log in. Please make sure username and password are correct.'
			});
		}
	};

export default login;