import {getRewardsLogAPI} from 'application/api';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type Log = {
  dateTime: string;
  admin: string;
  type: string;
  facilityName: string;
  amount: string;
  note: string;
};

/**
 * Custom hook to handle reward log state and actions
 * @returns list of reward transactions
 */
const useRewardsLog = () => {
  const [curRewardsLog, setCurRewardsLog] = useState<Array<Log>>([]);

  const getRewardsLog = async () => {
    const res = await getRewardsLogAPI();
    if (res === null) return;

    const modifiedRes: Array<Log> = res.map((log) => ({
      dateTime: log.dateTime,
      admin: log.admin,
      type: log.type,
      facilityName: log.facility_name,
      amount: log.amount,
      note: log.note
    }))

    setCurRewardsLog(modifiedRes);
  };

  useEffect(() => {getRewardsLog()}, []);

  return {curRewardsLog};
};

/**
 * Grid component for {@link RewardsLog}.
 * @returns Grid component
 */
const RewardsGrid = () => {
  const {curRewardsLog} = useRewardsLog();
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'dateTime',
      headerName: 'Date',
      filter: 'agDateColumnFilter',
      maxWidth: 125
    },
    {
      field: 'type',
      headerName: 'Type',
      filter: 'agTextColumnFilter',
      maxWidth: 100
    },
    {
      field: 'facilityName',
      headerName: 'Facility',
      filter: 'agTextColumnFilter',
      maxWidth: 400
    },
    {
      field: 'amount',
      headerName: 'Amount',
      filter: 'agNumberColumnFilter',
      maxWidth: 125
    },
    {
      field: 'admin',
      headerName: 'Admin',
      filter: 'agTextColumnFilter',
      maxWidth: 150
    },
    {
      field: 'note',
      headerName: 'Note',
      filter: 'agTextColumnFilter'
    },
  ];

  return <DataGrid
    rowData={curRewardsLog}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
  />
};

/**
 * Page that lists all rewards transactions
 * @returns Page component
*/
const RewardsLog = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin'
    },
    {
      id: 11,
      name: 'Rewards Log',
      path: location.pathname
    }
  ]);

  return (
    <section className='a-table'>
      <RewardsGrid />
    </section>
  );
};

export default RewardsLog;