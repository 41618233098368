import {get} from 'application/api/baseApi';

export type Response = [
  {
    state: string;
    county: string;
    tax_rate: string;
  }
];

const path = '/getTaxRates';

/**
 * Retrieves tax rates for all states/counties.
 * @remark Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getTaxRatesAPI = () => get<Response>(path);

export default getTaxRatesAPI;