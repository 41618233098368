import {post} from 'application/api/baseApi';

type Request = {
  prod_id: string;
  prod_groupID: string;
  prod_itemID: string;
  prod_name: string;
  prod_size: string;
  prod_desc?: string;
  prod_price: string;
  prod_shipping: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyProductSub';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const editSubProductAPI = (req: Request) => post<Response>(path, req);

export default editSubProductAPI;