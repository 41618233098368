import LinkToProductRenderer from '../../utility/agGrid/LinkToProductRenderer';
import {lookupProductAPI} from '../../../api';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect, FormEvent} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {TextField} from '@mui/material';

/**
 * Page that lists the website's products
 * @returns Page component
*/
const LookupProduct = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 7,
      name: 'Products',
      path: location.pathname
    }
  ]);
  const [rowData, setRowData] = useState<Array<Object>>([]);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 50,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'productId',
      headerName: 'Product ID',
      filter: 'agTextColumnFilter',
      cellRenderer: LinkToProductRenderer,
      maxWidth: 150
    },
    {
      field: 'name',
      headerName: 'Name (Header)',
      filter: 'agTextColumnFilter',
      cellRenderer: LinkToProductRenderer,
      maxWidth: 200
    },
    {
      field: 'dept',
      headerName: 'Dept',
      filter: 'agTextColumnFilter',
      maxWidth: 200
    },
    {
      field: 'subDept',
      headerName: 'Sub Dept',
      filter: 'agTextColumnFilter',
      maxWidth: 200
    },
    {
      field: 'superSubDept',
      headerName: 'Super Sub Dept',
      filter: 'agTextColumnFilter',
      maxWidth: 200
    },
    {
      field: 'price',
      headerName: 'Price',
      filter: 'agNumberColumnFilter',
      minWidth: 200
      // cellRenderer: ResidentCellRenderer,
    }
  ];
  const [searchValue, setSearchValue] = useState('');

  const handleLookupProduct = async (value?: string) => {
    const res = await lookupProductAPI(value);

    if (res === null) return;

    setRowData(res.map((prod) => ({
      id: prod.ID,
      productId: prod.prod_itemID,
      name: prod.prod_name,
      dept: prod.deptName,
      subDept: prod.subDeptName,
      superSubDept: prod.superSubDeptName,
      // residents: fac.residentLists
      price: prod.prod_price
    })));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLookupProduct(searchValue);
  };
  
  useEffect(() => {
    handleLookupProduct();
  }, []);

  return (
    <div className='a-table a-table--searchable'>
      <span>
        <form onSubmit={onSubmit} style={{display: 'flex', margin: '1rem'}}>
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button className='btn btn--primary' type='submit'>
            Search
          </button>
        </form>
        <Link className='btn btn--secondary' to='/admin/product/add'>Add Product</Link>
      </span>
      <DataGrid
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default LookupProduct;