import {State} from '../..';
import LandingCarousel from '../entities/LandingCarousel';
import setAlert from '../../alerts/actions/setAlert';
import {getLandingCarouselAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

/**Retrieves carousel for landing page */
export const getLandingCarousel = (set: SetState<State>, get: GetState<State>) =>
	async (getHidden = false) => {

		try {
			const res = await getLandingCarouselAPI();
			console.log('getLandingCarousels', res);
			

			const carousel: Array<LandingCarousel> = [];

			res.data.forEach((item) => {
				if (!getHidden && item.hide === 1) return;
	
				const modifiedItem: LandingCarousel = {
					orderNum: item.orderNum,
					id: item.id,
					image: item.image,
					link: item.link,
					headerText: item.headline,
					subHeaderText: item.subhead,
					buttonText: item.button_text,
					isHidden: item.hide === 1
				};
	
				carousel.push(modifiedItem);
			});

			if (carousel.length > 0) {
				localStorage.setItem('landingCarousel', JSON.stringify(carousel));

				set(() => ({
					carousels: {
						landing: carousel
					}
				}));
			}

		} catch (err: any | unknown) {
      if (isDebugging) {
        console.log(`getLandingCarousel error:`, err);//TESTING
      }
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response?.data?.message || 'Failed to retrieve landing carousel'
			});
		}
	};

export default getLandingCarousel;