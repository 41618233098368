import getAccountDetails from './getAccountDetails';
import login from './login';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editAccountAPI, EditedAccount} from '../../../api';
import {authService, isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const editAccount = (set: SetState<State>, get: GetState<State>) =>
async (editedAccount: EditedAccount, hasChangedAccountName = false, captchaToken?: string) => {
	const userToken = authService.token.get();

	try {
		if (!userToken) throw new Error();

		await editAccountAPI(editedAccount);

		setAlert(set, get)({
			status: 'success',
			header: 'Account Successfully Added',
			text: 'Your account has successfully been updated.'
		});

		if (hasChangedAccountName) {
			const credentials = authService.credentials.get();
			if (!credentials || !captchaToken) throw new Error('There was an error while attempting to reauthenticate account');

			await login(set, get)(
				credentials.username,
				credentials.password,
				captchaToken,
				false,
				true
			);

		} else {
			await getAccountDetails(set, get)();
		}
	

	} catch (err: any | unknown) {
		if (isDebugging) {
			console.log('edit account store error:', err);
		}
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to update account'
		});
	}
};

export default editAccount;