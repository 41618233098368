import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

type Response = {
  userID: string;
  roleID: number;
  userToken: string;
  firstName: string;
  lastName: string;
  message?: string;
};

const authenticateAccountPort = '/authenticateAccount';

const authenticateAccountAPI =
async (usernameOrEmail: string, hashedPassword: string, captchaToken: string): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');
  const res = await api.get(authenticateAccountPort, {
    headers: {
      // remoteip: ipRes.status === 200 ? ipRes.data.IPv4 : '',
      remoteip: '',
      captchaToken: captchaToken
    },
    params: {
      username: usernameOrEmail,
      pword: hashedPassword
    }
  });

  if (isDebugging) {
    console.log(`${authenticateAccountPort} response:`, res);//TESTING
  }

  return res;
};

export default authenticateAccountAPI;
