import {State} from 'application/store';
import {getAdminsAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

/**Stores the admins retrieved from backend */
export const getAdmins = (set: SetState<State>, get: GetState<State>) =>
	async () => {
		const res = await getAdminsAPI();

		if (res === null) return;
		
		set(() => ({
			adminList: res.map((admin) => ({
				id: admin.adminID,
				username: admin.username,
				password: '',
				passwordConfirm: '',
				isSuperAdmin: admin.superUser === 1
			}))
		}));
	};

export default getAdmins;