import ColorControls from './ColorControls';
import BlockControls from './BlockControls';
import InlineControls from './InlineControls';
import React, {
  useState,
  useEffect,
  createRef,
  KeyboardEvent,
  Dispatch,
  SetStateAction,
  FC
} from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  DraftHandleValue,
  getDefaultKeyBinding,
  // convertFromHTML
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {convertFromHTML, convertToHTML} from 'draft-convert';

type Props = {
  className?: string;
  placeholder?: string;
  startingHTML: string;
  setCurrentHTML: (value: string) => void;
};

const Draft: FC<Props> = ({className = '', placeholder = '', startingHTML, setCurrentHTML}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );
  const editorRef = createRef<Editor>();
  const defaultClassName = 'RichEditor-editor';
  const [curClassName, setCurClassName] = useState(defaultClassName);

  const handleKeyCommand = (command: string, curState: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(curState, command);

    if (newState !== null) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const mapKeyToEditorCommand = (e: KeyboardEvent) => {
    const tabKey = 'Tab';
    const maxDepth = 4;

    if (e.key === tabKey) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        maxDepth
      );

      if (editorState !== newEditorState) {
        setEditorState(newEditorState);
      }

      return null;
    }

    return getDefaultKeyBinding(e);
  };

  useEffect(() => {
    const curContent = editorState.getCurrentContent();

    setCurrentHTML(convertToHTML(curContent));
  }, [editorState]);

  useEffect(() => {
    const newState = convertFromHTML(startingHTML);
    const newEditorState = EditorState.createWithContent(newState);

    setEditorState(newEditorState);
  }, [startingHTML]);

  return (
    <div
      className={`RichEditor-root ${className}`}
      onFocus={editorRef?.current?.focus}
    >
      <BlockControls
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <InlineControls
        editorState={editorState}
        setEditorState={setEditorState}
      />
      {/* <ColorControls
        editorState={editorState}
        setEditorState={setEditorState}
      /> */}
      <div className={curClassName}>
        <Editor
          // ref={editorRef}
          // customStyleMap={colorStyleMap}
          editorState={editorState}
          onChange={setEditorState}
          keyBindingFn={mapKeyToEditorCommand}
          handleKeyCommand={handleKeyCommand}
          placeholder={placeholder}
          spellCheck={true}
        />
      </div>
    </div>
  );
};

export default Draft;