import React, {useState} from 'react';
import defaultImg from '../media/DEFAULT_M.png';

type Props = {
  src: string;
  alt: string;
  fallbackSrc?: string;
};

const Image = ({src, alt, fallbackSrc = defaultImg, ...rest}: Props) => {
  const [curSrc, setCurSrc] = useState(src);
  const [hasErrored, setHasErrored] = useState(false);

  const onError = () => {
    if (hasErrored) return;

    setCurSrc(fallbackSrc);
    setHasErrored(true);
  };

  return <img src={curSrc} alt={alt} {...rest} onError={onError}/>;
};

export default Image;