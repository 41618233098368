import getProductInfo from './getProductInfo';
import type SubProduct from '../entities/SubProduct';
import {State} from '../..';
import StoreError from 'application/store/utility/errors/StoreError';
import setAlert from '../../alerts/actions/setAlert';
import {deleteSubProductAPI} from 'application/api';
import {isDebugging} from 'application/utility';
import {SetState, GetState} from 'zustand';

export const deleteSubProduct = (set: SetState<State>, get: GetState<State>) =>
	async (subProduct: Pick<SubProduct, 'id' | 'groupId'>) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

    const req = {
      prod_id: subProduct.id.toString(),
      prod_groupID: subProduct.groupId,
    };

    const res = await deleteSubProductAPI(req);

    if (res !== null) setAlert(set, get)({
      status: 'success',
      header: 'Success',
      text: 'Successfully deleted sub-product'
    });

    getProductInfo(set, get)(productInStore.itemId);
	};

export default deleteSubProduct;