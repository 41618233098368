import SubAccount from '../entities/SubAccount';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {getSubAccountsAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import produce from 'immer';

const getSubAccounts = (set: SetState<State>, get: GetState<State>) => async () => {
  const accountInStore = get().account;

	try {
    if (!accountInStore) throw new Error();

    const res = await getSubAccountsAPI(accountInStore.userId);

    const modifiedSubAccounts: Array<SubAccount> = res.data.map((sub) => ({
      id: sub.ID,
      email: sub.email,
      title: sub.title,
      firstName: sub.fName,
      lastName: sub.lName
    }));

    set(produce((state) => {
      state.account.subAccounts = modifiedSubAccounts
    }));

	} catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get sub-accounts store error:', err);
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to load sub-accounts'
		});
	}
};

export default getSubAccounts;