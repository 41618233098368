import {useStore} from '../../../store';
import {isDebugging} from '../../../utility/index';
import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom'

type Props = {
  title: string;
  superAdmin?: boolean;
};

const AdminRoute = (props: any & Props) => {
  const {
    component: Component,
    title,
    superAdmin = false,
    ...rest
  } = props;
  const admin = useStore((state) => state.admin);

  return (
    <Route {...rest} >
      {
        cProps =>
        isDebugging ?
        <Component {...cProps} /> :
        !admin ||
        !admin.username ||
        (superAdmin && !admin.superUser) ?
        <Redirect to='/admin' /> :
        <Component {...cProps} />
      }
    </Route>
  );
};

export default AdminRoute;