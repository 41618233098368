import { GetState, SetState } from 'zustand';
import { State } from '../..';
import LandingCarousel from '../entities/LandingCarousel';

/**Edits carousel for landing page */
export const editLandingCarousel = (set: SetState<State>, get: GetState<State>) =>
	async (carousels: LandingCarousel[]) => {

    const storeCarousels = get().carousels.landing;
    const newCarousels = storeCarousels.map((c) => ({
      ...c,
      orderNum: carousels.find((car) => car.id === c.id)?.orderNum || 0
    })).sort((a, b) => a.orderNum - b.orderNum)
    
    set({
      carousels: {
        landing: newCarousels
      }
    })
   
	};

export default editLandingCarousel;