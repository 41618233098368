import CartTEST from './CartTEST';
import CartEmpty from './CartEmpty';
import {ReactComponent as LoadingIcon} from '../../media/icon-loading.svg';
import {ReactComponent as RewardsIcon} from '../../media/Rewards_Logo.svg';
import {baseURL} from '../../../api';
import {useStore} from '../../../store';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const CartList = () => {
	const history = useHistory();
	const isAuthenticated = useStore((state) => state.isAuthenticated);
	const account = useStore((state) => state.account);
	const checkout = useStore((state) => state.checkout);
	const isCheckoutFinalized = useStore((state) => state.checkout.isFinalized);
	const isRetrieving = useStore((state) => state.checkout.isRetrieving);
	const dispatchGetCarts = useStore((state) => state.dispatchGetCarts);
	const dispatchGetResidentList = useStore((state) => state.dispatchGetResidentList);
	const isFacility = account?.roleId === 3;
	const stripeUrl = `${baseURL}/stripe_create_checkout_session2/?userID=${account?.userId}&invoiceID=${checkout.invoiceId}${checkout.specialShipping ? '&specialShipping=1' : ''}`;
	const listHeading = `${isFacility ? `Resident's ` : ''}Orders`;

	const redirectOnFinalize = () => {
		if (isFacility) {
			checkout.isPayingWithCard ?
			window.location.href = stripeUrl :
			history.push('/checkout/complete/facility');
		} else {
			window.location.href = stripeUrl;
		}
	};

	const init = async () => {
		if (isAuthenticated) {
			
			if (account && isFacility) {
				await dispatchGetResidentList();
			}

			dispatchGetCarts();
		}
	};

	useEffect(() => {
		init();

		//This was added to keep cart list updated, even if shopping on multiple browser tabs
		window.addEventListener('focus', init);
		return () => window.removeEventListener('focus', init);
	}, []);

	useEffect(() => {
		if (isCheckoutFinalized) redirectOnFinalize();
	}, [isCheckoutFinalized]);

	if (isRetrieving) {
		return <section className='cart-list cart-list--loading'>
			<LoadingIcon />
		</section>;
	}
	
	if (!isAuthenticated || checkout.carts.length < 1) {
		return <CartEmpty />;
	}

	return <section className='cart-list'>
		<h1>{listHeading}</h1>
		{
			isFacility &&
			<div className='cart-list__rewards'>
				{`Rewards Amount: $${account?.company?.rewards}`}
				{/* <RewardsIcon/> */}
				{/* {` Reward Dollars`} */}
			</div>
		}
		{
			checkout.carts.map((cart, index) => 
				<CartTEST residentId={cart.residentId} key={index}/>
			)
		}
	</section>;
};

export default CartList;
