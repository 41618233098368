import getResidentList from './getResidentList';
import Resident from '../entities/Resident';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editResidentAPI, EditResident} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const editResident = (set: SetState<State>, get: GetState<State>) =>
async (editedResident: Resident) => {

  try {
    if (isDebugging) {
      console.log('resident to edit:', editedResident);//TESTING
    }
    
    const modifiedResident: EditResident = {
      residentID: editedResident.id,
      userID: editedResident.userId,
      resident_fName: editedResident.firstName,
      resident_lName: editedResident.lastName,
      resident_gender: editedResident.sex,
      resident_clothing_size: editedResident.clothingSize,
      resident_shoe_size: editedResident.shoeSize,
      resident_budget_amount: editedResident.budget.toString()
    };

    if (isDebugging) {
      console.log('modified resident to edit:', modifiedResident);//TESTING
    }

    await editResidentAPI(modifiedResident);

    if ((editedResident.firstName === '') && (editedResident.lastName === '')) {
      setAlert(set, get)({
        status: 'danger',
        header: 'Resident Deleted',
        text: 'Resident has been deleted'
      });
    }

    getResidentList(set, get)();
    
  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('edit resident store error:', err);//TESTING
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to update resident'
		});
  }
};

export default editResident;
