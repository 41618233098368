import {post} from 'application/api/baseApi';

type Request = {
  prod_groupID: string;
  prod_itemID: string;
  prod_name: string;
  prod_desc: string;
  prod_price: string;
  prod_clothingType?: string;
  prod_nurseTip?: string;
  prod_color?: string;
  taxExempt: string;
  MFB?: string;
  hide: string;
  imageCount: number;
  deptID: string;
  subDeptID: string | 0;
  superSubDeptID: string | 0;
  sched_delivery_ID?: string;
  sched_delivery_options?: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/addProductMain';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const addProductAPI = (req: Request) => post<Response>(path, req);

export default addProductAPI;