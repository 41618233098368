import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
  RewardAmount: number;
  RewardExpirationDate: string;
  policy: string;
};

const getRewardsInfoPost = '/getRewardInfo';

const getRewardsInfo = async (userId: string): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getRewardsInfoPost, {params: {userID: Number(userId)}});

  if (isDebugging) {
    console.log(`${getRewardsInfoPost} response:`, res);//TESTING
  }

  return res;
};

export default getRewardsInfo;