import {post} from 'application/api/baseApi';

type Request = {
  username: string;
  pword: string;
  pwordConfirm: string;
  superAdmin: 1 | 0;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/addAdminAccount';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const addAdminAPI = (req: Request) => post<Response>(path, req);

export default addAdminAPI;