import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Request = Array<{
    id: number;
    image: string;
    link: string;
    hide: number;
    button_text: string;
    headline: string;
    subhead: string;
    orderNum: number;
  }>;

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const editLandingCarouselPort = '/modifyLandingPage_shopLinks_DnD';

const editLandingCarouselAPI = async (request: Request): Promise<AxiosResponse<Response>> => {
  const res = await api.post(editLandingCarouselPort, JSON.stringify(request));
  
  if (isDebugging) {
    console.log(`${editLandingCarouselPort} response:`, res);//TESTING
  }

  return res;
};

export default editLandingCarouselAPI;