import {post} from 'application/api/baseApi';

type Request = {
  userId: string;
  invoiceId: string;
  isProcessed: boolean;
}

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/updateIndReceiptStatus';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const editIndividualInvoiceAPI = (req: Request) => post<Response>(
  path,
  {
    userID: req.userId,
    invoice_ID: req.invoiceId,
    processed: req.isProcessed ? '1' : '0'
  }
);

export default editIndividualInvoiceAPI;