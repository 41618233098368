import EditResident from '../entities/EditResident';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const editResidentPort = '/modifyResident';

const editResidentAPI = async (editedResident: EditResident) => {
  const res = await api.post(editResidentPort, editedResident);

  if (isDebugging) {
    console.log(`${editResidentPort} response:`, res);//TESTING
  }

  return res;
};

export default editResidentAPI;