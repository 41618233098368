import {post} from 'application/api/baseApi';

type Request = {
  productId: number;
  listId: number;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/removeProdDeptMatrix';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const deleteProductDeptAPI = (req: Request) => post<Response>(
  path,
  {
    ID: req.listId,
    prod_ID: req.productId
  }
);

export default deleteProductDeptAPI;