import {ReactComponent as ShoppingCartIcon} from '../../media/icon-shopping-cart.svg';
import React from 'react';
import {useHistory} from 'react-router-dom';

const CartEmpty = () => {
  const history = useHistory();

  const redirectToCatalog = () => history.push('/productList/dept/1');

  return (
    <section className='cart-list cart-list--empty'>
      <div>
        <ShoppingCartIcon />
        <h2>Your Cart is Empty</h2>
        <p>It looks like you haven't added anything to your cart yet</p>
        <button className='btn btn--primary' onClick={redirectToCatalog}>
          Go Shopping
        </button>
      </div>
    </section>
  );
};

export default CartEmpty;