import AdminNav from './AdminNav';
import InvoicesFacility from './InvoicesFacility';
import InvoicesIndividual from './InvoicesIndividual';
import LookupCarousel from './LookupCarousel';
import LookupAdmin from './LookupAdmin';
import LookupFacility from './LookupFacility';
import LookupIndividual from './LookupIndividual';
import LookupProduct from './LookupProduct';
import TaxRates from './TaxRates';
import FacilityDetail from './FacilityDetail';
import DailyEntries from './DailyEntries';
import ProductDetail from './productDetail/ProductDetailView';
import Breadcrumbs from '../navigation/breadcrumbs/BreadcrumbsView';
import AdminRoute from '../../utility/routing/AdminRoute';
import RewardsLog from './RewardsLog';
import logoShort from '../../media/sss_logo-short.png';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React from 'react';
import {Switch, useLocation} from 'react-router-dom';

/**
 * Layout component that adds breadcrumbs and navigation to each admin page.
 * @returns Dashboard layout component
*/
const Dashboard = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    }
  ]);

  return (
    <section className='a-dashboard'>
      <Breadcrumbs />
      <AdminNav currentPath={location.pathname}/>
      <div className='a-dashboard__main'>
        <Switch>
          <AdminRoute
            title='Admin: Invoice Facility'
            exact
            path='/admin/invoices/facility'
            component={InvoicesFacility} 
          />
          <AdminRoute
            title='Admin: Invoice Individual'
            exact
            path='/admin/invoices/individual/:userId?'
            component={InvoicesIndividual} 
          />
          <AdminRoute
            title='Admin: Facilities'
            exact
            path='/admin/lookup/facility'
            component={LookupFacility} 
          />
          <AdminRoute
            title='Admin: Facility'
            exact
            path='/admin/lookup/facility/:userId'
            component={FacilityDetail} 
          />
          <AdminRoute
            title='Admin: Individuals'
            exact
            path='/admin/lookup/individual'
            component={LookupIndividual} 
          />
          <AdminRoute
            title='Admin: Products'
            exact
            path='/admin/lookup/product'
            component={LookupProduct} 
          />
          <AdminRoute
            title='Admin: Product'
            exact
            path='/admin/lookup/product/:productId'
            component={ProductDetail} 
          />
          <AdminRoute
            title='Admin: Product New'
            exact
            path='/admin/product/add'
            component={ProductDetail} 
          />
          <AdminRoute
            title='Admin: Taxes'
            exact
            path='/admin/tax'
            component={TaxRates} 
          />
          <AdminRoute
            title='Admin: Edit'
            exact
            path='/admin/landing'
            component={LookupCarousel} 
          />
          {/* <AdminRoute
            title='Admin: Edit Landing'
            exact
            superAdmin
            path='/admin/test/super'
            component={LandingEdit} 
          /> */}
          <AdminRoute
            title='Admin: Edit Admins'
            exact
            path='/admin/adminList'
            superAdmin
            component={LookupAdmin}
          />
          <AdminRoute
            exact
            path='/admin/dailyEntries'
            component={DailyEntries}
          />
          <AdminRoute
            exact
            path='/admin/rewards'
            component={RewardsLog}
          />
          <AdminRoute
            title='Test:'
            path='/admin/dashboard'
            component={() => <img className='a-dashboard__logo' src={logoShort} alt='SSS Logo'/>}
          />
        </Switch>
      </div>
    </section>
  );
};

export default Dashboard;