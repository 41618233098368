import getTaxRates from './getTaxRates';
import TaxRate from '../entities/TaxRate';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editTaxRateAPI} from '../../../api';
import {SetState, GetState} from 'zustand';

/**Edits tax rate for a given county/state, then updates the store. */
export const editTaxRate = (set: SetState<State>, get: GetState<State>) =>
	async (taxRate: TaxRate) => {

		const res = await editTaxRateAPI({
			state: taxRate.state,
			county: taxRate.county,
			tax_rate: taxRate.tax
		});

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully updated tax rate'
		});
		
		getTaxRates(set, get)();
	};

export default editTaxRate;