import getProductInfo from './getProductInfo';
import type Product from '../entities/Product';
import {State} from '../..';
import StoreError from '../../utility/errors/StoreError';
import setAlert from '../../alerts/actions/setAlert';
import {isDebugging} from 'application/utility';
import {editProductAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const editProduct = (set: SetState<State>, get: GetState<State>) =>
	async (productToEdit: Omit<Product, 'subProducts' | 'depts' | 'MFB'>) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

    const taxExempt: '1' | '0' = productToEdit.isTaxExempt ? '1' : '0';
    const hide: '1' | '0' = productToEdit.isHidden ? '1' : '0';

    const req = {
      prod_id: productToEdit.id.toString(),
      prod_groupID: productToEdit.groupId,
      prod_itemID: productToEdit.itemId,
      prod_name: productToEdit.name,
      prod_desc: productToEdit.desc,
      prod_price: productToEdit.priceRange,
      prod_dept: productInStore.depts.dept,
      prod_subDept: productInStore.depts.subDept,
      prod_superSubDept: productInStore.depts.superSubDept,
      prod_clothingType: productToEdit.clothingType,
      prod_nurseTip: productToEdit.needs,
      prod_color: productToEdit.options,
      prod_shipping: productToEdit.shippingCharge,
      sched_delivery_ID: productToEdit.scheduledDeliveryId,
      sched_delivery_options: productToEdit.scheduledDeliveryOptions,
      MFB: productToEdit.sex,
      imageCount: productToEdit.imageCount.toString(),
      taxExempt: taxExempt,
      hide: hide
    };

    const res = await editProductAPI(req);
    
    if (res !== null) setAlert(set, get)({
      status: 'success',
      header: 'Success',
      text: 'Successfully updated product'
    });

    getProductInfo(set, get)(productToEdit.itemId);
	};

export default editProduct;