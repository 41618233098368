import {sendEmailAPI} from 'application/api';
import Alert from '../entities/Alert';
import {State} from '../..';
import {GetState, SetState} from 'zustand';
import { isDebugging } from 'application/utility';

const setAlert = (set: SetState<State>, get: GetState<State>) => async (alert: Omit<Alert, 'id'>, timeout = 5000, error?: any) => {

  if (error !== undefined || alert.status === 'danger') {
    if (isDebugging) console.log('ERROR: ', error);

    const account = get().account;

    await sendEmailAPI({
      to: 'bpenticuff@seniorshoppingservice.com',
      subject: `User Error: ${alert.header}`,
      body: `<h2>Error</h2>Type: ${alert.header}<BR>Alert Text: ${alert.text}<BR><BR><h3>Account Info:</h3>${account}<BR><BR><h3>Details:</h3>alert text: ${alert.text}<BR>${error}`
    })
  }

  const alertId = (Math.random() * 100) * Math.random();

  set((prev) => ({
    alerts: prev.alerts.concat([
      {
        id: alertId,
        status: alert.status,
        header: alert.header,
        text: alert.text
      }
    ])
  }));

  if (timeout === 0) return;
  
  setTimeout(
    () => set((prev) => ({
      alerts: prev.alerts.filter((alert) => alert.id !== alertId)
    })),
    timeout
  );
};

export default setAlert;