import {post} from 'application/api/baseApi';

type Request = {
  userId: string;
  invoiceId: string;
  facilityId: string;
  isProcessed: boolean;
  isPaid: boolean;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/updateFacilityInvoiceStatus';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const editFacilityInvoiceAPI = (req: Request) => post<Response>(
  path,
  {
    userID: req.userId,
    invoice_ID: req.invoiceId,
    facPO: req.facilityId,
    processed: req.isProcessed ? '1' : '0',
    isPaid: req.isPaid ? '1' : '0'
  }
);

export default editFacilityInvoiceAPI;