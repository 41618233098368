import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = [
  {
    invoice_ID: number;
    userID: number;
    date: Date;
    total_cost: string;
    residentFullName: string;
  }
];

const getInvoiceListPort = '/getInvoiceList';

const getInvoiceListAPI = async (userId: number): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getInvoiceListPort, {
    params: {
      userID: userId
    }
  });

  if (isDebugging) {
    console.log(`${getInvoiceListPort} response:`, res);//TESTING
  }

  return res;
};

export default getInvoiceListAPI;