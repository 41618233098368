import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import {AdminToEditExtended} from '../../components/admin/LookupAdmin';
import {useStore} from '../../../store';
import React, {MouseEvent} from 'react';
import {Link} from 'react-router-dom';

type Props = {
  value: number;
  data: AdminToEditExtended;
};

const DeleteAdminRenderer = (props: Props) => {
  const {data: {id, isNew}} = props;
  const dispatchDeleteAdmin = useStore((state) => state.dispatchDeleteAdmin);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const isConfirmed = window.confirm(`Are you sure you'd like to delete this admin?`);

    if (!isConfirmed) return;

    dispatchDeleteAdmin({id});
  };

  return (
    isNew ?
    <></> :
    <button className='btn' onClick={onClick} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
      <TrashIcon style={{width: '2rem'}}/>
    </button>
  );
};

export default DeleteAdminRenderer;