import {get} from 'application/api/baseApi';

export type Response = [
  {
    invoice_ID: number;
    date: string;
    total_cost: string;
    invoice_type: string;
    facility_PO: string;
    PROCESSED?: 1;
    isPaid: 0 | 1;
    facility_details: [
      {
        userID: number;
        facility_name: string;
      }
    ];
  }
];

const path = '/getFacilityInvoiceList';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getFacilityInvoicesAPI = () => get<Response>(path);

export default getFacilityInvoicesAPI;