import {Product} from '../../../../api';
import React, {useState, useEffect, SyntheticEvent} from 'react';
import {Link} from 'react-router-dom';

export type ProductPreviewProps = {
  product: {
    prod_itemID: string;
    prod_name: string;
    prod_price: string;
  }
};

const ProductPreview = (props: ProductPreviewProps) => {
  const {product} = props;

  const onBrokenImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = `${document.location.origin}/product_images/DEFAULT_M.png`;
  };

  return (
    <section className='product-preview'>
      <Link to={`/productDetail?itemId=${product.prod_itemID}`}>
        <img
          className='product-preview__image'
          src={`${document.location.origin}/product_images/${product.prod_itemID}-1_M.png`}
          onError={onBrokenImage}
          alt={product.prod_name}
        />
        <span className='product-preview__overlay'></span>
      </Link>
      <h4 className='product-preview__name'>{product.prod_name}</h4>
      <h3 className='product-preview__price'>{'$' + product.prod_price.replace('-', ' - $')}</h3>
    </section>
  );
};

export default ProductPreview;
