import getLandingCarousel from './getLandingCarousel';
import {State} from '../..';
import LandingCarousel from '../entities/LandingCarousel';
import setAlert from '../../alerts/actions/setAlert';
import {deleteLandingCarouselAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

/**Edits carousel for landing page */
export const deleteLandingCarousel = (set: SetState<State>, get: GetState<State>) =>
	async (carousel: Pick<LandingCarousel, 'id'>) => {

		try {
			const res = await deleteLandingCarouselAPI({
        id: carousel.id
      });

			getLandingCarousel(set, get)(true);

      setAlert(set, get)({
        status: 'success',
        header: 'Success',
        text: 'Successfully deleted carousel'
      });

		} catch (err: any | unknown) {
      if (isDebugging) {
        console.log(`deleteLandingCarousel error:`, err);//TESTING
      }
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response?.data?.message || 'Failed to edit landing carousel'
			});
		}
	};

export default deleteLandingCarousel;