import reactGA from '../google/reactGA';

const newPageListener = (title: string, pathname: string, search = '') => {
  window.scrollTo(0, 0);
  reactGA.newPage(
    title,
    pathname,
    search
  );
};

export default newPageListener;