export {default as authService} from './authService';
export {default as companyInfo} from '../ui/utility/companyInfo';
export {default as createUniqueId} from './createUniqueId';
export {default as currentDatetime} from './currentDatetime';
export {default as hashPassword} from './hashPassword';
export type {default as StrictUnion} from './entities/StrictUnion';
export type {default as TypedResponse} from './entities/TypedResponse';

export {default as DomainError} from './errors/DomainError';
export type {ErrorConfig, ErrorSeverity} from './errors/DomainError';

export const isDebugging = false;