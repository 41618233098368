import getAdmins from './getAdmins';
import AdminToEdit from '../entities/AdminToEdit';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editAdminAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const editAdmin = (set: SetState<State>, get: GetState<State>) =>
	async (admin: AdminToEdit) => {

		const res = await editAdminAPI(admin.password === '' ?
			{
				targetAdminID: admin.id,
				username: admin.username,
				superAdmin: admin.isSuperAdmin ? 1 : 0
			} :
			{
				targetAdminID: admin.id,
				username: admin.username,
				pword: admin.password,
				pwordConfirm: admin.passwordConfirm,
				superAdmin: admin.isSuperAdmin ? 1 : 0
			}
		);

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully updated admin credentials'
		});

		getAdmins(set, get)();
	};

export default editAdmin;