import {get} from 'application/api/baseApi';

export type Response = [
  {
    deptID: number;
    deptName: string;
    subDept: [
      {
        subDeptID: number,
        subDeptName: string;
        superSubDept: [
          {
            superSubDeptID: number;
            superSubDeptName: string;
          }
        ]
      }
    ]
  }
];

const path = '/getDeptDropDowns';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getDeptMatrixAPI = () => get<Response>(path);

export default getDeptMatrixAPI;