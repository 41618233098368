import CheckboxRenderer from '../../utility/agGrid/CheckboxRenderer';
import DeleteAdminRenderer from '../../utility/agGrid/DeleteAdminRenderer';
import UpdateAdminRenderer from '../../utility/agGrid/UpdateAdminRenderer';
import {useStore, AdminToEdit} from '../../../store';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export type AdminToEditExtended = AdminToEdit & {
  isNew: boolean;
};

/**
 * Custom hook to handle adminList state and actions
 * @returns admin list from the back-end, with one default admin
 */
const useAdminList = () => {
  const adminList = useStore((state) => state.adminList);
  const dispatchGetAdmins = useStore((state) => state.dispatchGetAdmins);
  const [curAdminList, setCurAdminList] = useState<Array<AdminToEditExtended>>([]);
  const defaultAdmin = {
    id: 0,
    username: 'Enter username',
    password: 'Enter password',
    passwordConfirm: 'Confirm Password',
    isSuperAdmin: false,
    isNew: true
  };
  
  useEffect(() => {
    dispatchGetAdmins();
  }, []);
  
  useEffect(() => {
    const modifiedAdminList = adminList.map((admin) => ({
      id: admin.id,
      username: admin.username,
      password: admin.password,
      passwordConfirm: admin.password,
      isSuperAdmin: admin.isSuperAdmin,
      isNew: false
    }));
  
    modifiedAdminList.unshift(defaultAdmin);
  
    setCurAdminList(modifiedAdminList);
  
  }, [adminList]);

  return {curAdminList}
};

/**
 * Page that lists the website admins
 * @returns Page component
*/
const LookupAdmin = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 5,
      name: 'Admins',
      path: location.pathname
    }
  ]);
  const {curAdminList} = useAdminList();
  const defaultColDef = {
    resizable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'delete',
      headerName: 'Delete',
      cellRenderer: DeleteAdminRenderer,
      minWidth: 50,
      maxWidth: 100
    },
    {
      field: 'username',
      headerName: 'Username',
      filter: 'agTextColumnFilter',
      editable: true
    },
    {
      field: 'password',
      headerName: 'Password',
      editable: true
    },
    {
      field: 'passwordConfirm',
      headerName: 'Confirm Password',
      editable: true
    },
    {
      field: 'isSuperAdmin',
      headerName: 'Super Admin',
      cellRenderer: CheckboxRenderer,
      minWidth: 50,
      maxWidth: 150
    },
    {
      field: 'isNew',
      hide: true
    },
    {
      field: 'submit',
      headerName: 'Action',
      cellRenderer: UpdateAdminRenderer,
      minWidth: 50,
      maxWidth: 100
    }
  ];

  return (
    <div className='a-table'>
      <DataGrid
        rowData={curAdminList}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default LookupAdmin;