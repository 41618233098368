import {get} from 'application/api/baseApi';

export type Response = [
  {
    invoice_ID: number;
    date: string;
    total_cost: string;
    PROCESSED?: 1;
    account_details: [
      {
        userID: number;
        firstName: string;
        lastName: string;
      }
    ]
  }
];

const path = '/getIndReceiptList';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getIndividualInvoicesAPI = (userId?: string) => get<Response>(
  path,
  userId ? 
  {
    params: {
      userID: userId
    }
  } : 
  undefined
);

export default getIndividualInvoicesAPI;