import React, {useState} from 'react';

const LabelingView = () => {
  const labelingText =
    `Do you need a name labeled in your clothing items? We offer this service FREE of charge. At check out, please notate on whether to label the items, send labels, or do not mark.`;
  const alterationsText =
    `We offer hemming alterations for an additional $5 fee per alteration. Please notate in the Notes at check out if you need a specific alteration done, and we will add the $5.00 charge on before processing it. If you need something more specific than a hem, please call and talk with our seamstress, and she will assist you.`;

  return (
    <section className='labeling'>
      <h1 className='labeling__heading'>Labeling and Alterations</h1>
      <div className='labeling__group'>
        <h3 className='labeling__group-heading'>Labeling</h3>
        <p className='labeling__group-text'>{labelingText}</p>
      </div>
      <div className='labeling__group'>
        <h3 className='labeling__group-heading'>Alterations</h3>
        <p className='labeling__group-text'>We offer hemming alterations for an additional $5 fee per alteration. Please notate in the Notes at check out if you need a specific alteration done, and we will add the $5.00 charge on before processing it. If you need something more specific than a hem, please call <a href='tel:18003342897'>(800) 334-2897</a> and talk with our seamstress, and she will assist you.</p>
      </div>
    </section>
  );
};

export default LabelingView;
