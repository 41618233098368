import AccountNav from '../navigation/account/AccountNavView';
import InvoiceCellRenderer from '../../utility/agGrid/InvoiceCellRenderer';
import dateComparator from '../../utility/dateComparator';
import {getInvoiceListAPI, GetInvoiceListRes} from '../../../api';
import {useStore} from '../../../store';
import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const InvoiceList = () => {
  const account = useStore((state) => state.account);
  const location = useLocation();
  // const [invoiceList, setInvoiceList] = useState<Array<{
  //   invoiceId: number;
  //   date: Date;
  //   residentFullName: string;
  //   totalCost: string;
  // }>>([]);
  const [rowData, setRowData] = useState<Array<Object>>([]);
  const gridRef: any = useRef();
  // const containerStyle = useMemo(() => ({ width: '100vw', height: 'calc(100vh - 40px)' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  }), []);
  const [columnDefs] = useState([
    {field: 'invoiceId', hide: true},
    {field: 'date', headerName: 'Date Created', filter: 'agNumberColumnFilter', comparator: dateComparator},
    {field: 'fullName', headerName: 'Resident', filter: 'agTextColumnFilter'},
    {field: 'totalCost', headerName: 'Total Cost', filter: 'agNumberColumnFilter'},
    {field: 'link', headerName: 'Link', cellRenderer: InvoiceCellRenderer}
  ]);
  // const [searchValue, setSearchValue] = useState('');

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  
  useEffect(() => {
    if (!account) return;

    getInvoiceListAPI(Number(account.userId)).then((res) => {
      const responseData: GetInvoiceListRes = res.data;

      setRowData(
        responseData.map((invoice) => ({
          id: account.userId,
          invoiceId: invoice.invoice_ID,
          date: invoice.date,
          fullName: invoice.residentFullName,
          totalCost: invoice.total_cost,
          link: 'View Invoice'
        }))
      );
    });
  }, []);
  
  if (!account) return <Redirect to='/' />;

  return (
    <section className='invoice-list'>
      <h1 className='invoice-list__heading'>Invoices</h1>
      <div className='ag-theme-alpine' style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          onFirstDataRendered={onFirstDataRendered}
          paginationAutoPageSize={true}
          pagination={true}
        />
      </div>
    </section>
  );
};

export default InvoiceList;
