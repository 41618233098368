import handleReCAPTCHA from '../../utility/google/handleReCAPTCHA';
import {ReactComponent as LoadingIconSmall} from '../../media/icon-loading-small.svg';
import {useStore} from '../../../store';
import {authService} from '../../../utility';
import {useRecaptchaContext} from 'application/ui/context/recaptcha';
import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {TextField} from '@mui/material';

/**
 * Page to login to admin side of the website.
 * @returns Page component
*/
const LoginAdmin = () => {
  const history = useHistory();
  const admin = useStore((state) => state.admin);
  const dispatchLoginAdmin = useStore((state) => state.dispatchLoginAdmin);
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRecaptchaContext();

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const token = await handleReCAPTCHA(recaptchaRef);
		if (!token) return;

    await dispatchLoginAdmin(
      formData.username,
      authService.password.hash(formData.password),
      token
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (admin && admin.username) {
      history.push('/admin/dashboard');
    }
  }, [admin]);

  return (
    <section className='a-login'>
      <h1>Login</h1>
      <form onSubmit={onSubmit}>
        <TextField
					className='login__username'
					name='username'
					label='Username/Email'
					value={formData.username}
					onChange={onChangeInput}
					required
				/>
        <TextField
					className='login__username'
          type='password'
					name='password'
					label='Password'
					value={formData.password}
					onChange={onChangeInput}
					required
				/>
        <button
          className='btn btn--primary login__submit'
          type='submit'
          disabled={isLoading}
        >
          {
            isLoading ?
            <LoadingIconSmall /> :
            'Login'
          }
        </button>
      </form>
    </section>
  );
};

export default LoginAdmin;