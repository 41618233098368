import getLandingCarousel from './getLandingCarousel';
import {State} from '../..';
import LandingCarousel from '../entities/LandingCarousel';
import setAlert from '../../alerts/actions/setAlert';
import {addLandingCarouselAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

/**Edits carousel for landing page */
export const addLandingCarousel = (set: SetState<State>, get: GetState<State>) =>
	async (carousel: Omit<LandingCarousel, 'id'>) => {

		try {
			const res = await addLandingCarouselAPI({
        image: carousel.image,
        link: carousel.link,
        button_text: carousel.buttonText,
        headline: carousel.headerText,
        subhead: carousel.subHeaderText,
        hide: carousel.isHidden ? 1 : 0,
        orderNum: 0
      });

			getLandingCarousel(set, get)(true);
   
      setAlert(set, get)({
        status: 'success',
        header: 'Success',
        text: 'Successfully added carousel'
      });

		} catch (err: any | unknown) {
      if (isDebugging) {
        console.log(`addLandingCarousel error:`, err);//TESTING
      }
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response?.data?.message || 'Failed to add landing carousel'
			});
		}
	};

export default addLandingCarousel;