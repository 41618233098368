import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Request = {
  userID: string;
  adminID: number;
  reward_amount: number;
  reward_note?: string;
  sendEmail: 1 | undefined;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
  newRewardAmount: number;
  newRewardExpirationDate: string;
};

const addRewardsPost = '/addReward';

const addRewards = async (request: Request): Promise<AxiosResponse<Response>> => {
  const res = await api.post(addRewardsPost, request);

  if (isDebugging) {
    console.log(`${addRewardsPost} response:`, res);//TESTING
  }

  return res;
};

export default addRewards;