import NewResident from '../entities/NewResident';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const addResidentPort = '/addResident';

const addResidentAPI = async (newResident: NewResident) => {
  const res = await api.post(addResidentPort, newResident);

  if (isDebugging) {
    console.log(`${addResidentPort} response:`, res);//TESTING
  }

  return res;
};

export default addResidentAPI;