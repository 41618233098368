import RegisterUser from '../entities/RegisterUser';
import RegisterFacility from '../entities/RegisterFacility';
import api from '../../baseApi';
import {StrictUnion, isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  userID: string;
  roleID: number;
  userToken: string;
  firstName: string;
  lastName: string;
};

const registerAccountPort = '/registerAccount';

const registerAccountAPI =
async (newAccount: StrictUnion<RegisterUser | RegisterFacility>, captchaToken: string): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');
  let body;

  if (newAccount.roleId === 2) {
    body = {
      username: newAccount.username,
      pword: newAccount.password.hashed,
      pwordConfirm: newAccount.password.hashedConfirm,
      roleID: newAccount.roleId,
      firstName: newAccount.firstName,
      lastName: newAccount.lastName,
      email: newAccount.email,
      phoneNumber: newAccount.phoneNumber,
      address1: newAccount.shipping.address1,
      address2: newAccount.shipping.address2,
      city: newAccount.shipping.city, 
      state: newAccount.shipping.state,
      zip: newAccount.shipping.zip,
      country: newAccount.shipping.country,
      newsletter: newAccount.newsletter === true ? '1' : null
    };
  } else {
    body = {
    username: newAccount.username,
    pword: newAccount.password.hashed,
    pwordConfirm: newAccount.password.hashedConfirm,
    roleID: newAccount.roleId,
    firstName: newAccount.firstName,
    lastName: newAccount.lastName,
    email: newAccount.email,
    phoneNumber: newAccount.phoneNumber,
    facName: newAccount.company?.name,
    facPhone: newAccount.company?.phoneNumber,
    facAdmin: newAccount.company?.admin,
    facFax: newAccount.company?.fax,
    facAddress1: newAccount.shipping.address1,
    facAddress2: newAccount.shipping.address2,
    facOfficeMgr: newAccount.company.officeManager.name,
    facOfficeMgrEmail: newAccount.company.officeManager.email,
    facCity: newAccount.shipping.city,
    facCounty: newAccount.shipping.county,
    facState: newAccount.shipping.state,
    facZip: newAccount.shipping.zip,
    facCountry: newAccount.shipping.country,
    newsletter: newAccount.newsletter === true ? '1' : null
    }
  }

  if (isDebugging) {
    console.log('account to register:', body);//TESTING
  }

  const res = api.post(
    registerAccountPort,
    body, 
    {
      headers: {
        remoteip: '',
        captchaToken: captchaToken
      }
    }
  );


  if (isDebugging) {
    console.log(`${registerAccountPort} response:`, res);//TESTING
  }

  return res;
};

export default registerAccountAPI;