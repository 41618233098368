import React from 'react';

type Props = {
  author?: string;
  location?: string;
  quote: string;
}

const Quote = ({author, location, quote}: Props) => {
  return (
    <span className='quote'>
      <p>{`"${quote}"`}</p>
      {
        author &&
        <h5>{author}</h5>
      }
      {
        location &&
        <h5>{location}</h5>
      }
    </span>
  );
};

export default Quote;