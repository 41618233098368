import bcrypt from 'bcryptjs';
import crypto from 'crypto';

const hashPassword = (plainPassword: string) => {
	const saltRounds = 0;
	// const hashedPassword = bcrypt.hashSync(plainPassword, saltRounds);
	const hashedPassword = crypto.createHash('sha256').update(plainPassword).digest('hex');

	return hashedPassword;
};

export default hashPassword;