import {get} from 'application/api/baseApi';

export type Response = [
  {
    userID: number;
    facility_PO: string;
    facility_name: string;
    fname: string;
    lname: string;
    facility_address1: string;
    facility_city: string;
    facility_state: string;
    residentList: [
      {
        name: string;
      }
    ]
  }
];

const path = '/facilityLookup';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param search Optional search string
 * @returns Expected response or null
 */
const lookupFacilityAPI = (search?: string) => get<Response>(
  path,
  {
    params: {
      value: search || ''
    }
  }
);

export default lookupFacilityAPI;