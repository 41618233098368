import newPageListener from './newPageListener';
import {useStore} from '../../../store';
import React, {useEffect} from 'react';
import {Route, Redirect, useLocation, useParams} from 'react-router-dom';

const PrivateRoute = (props: any) => {
  const {component: Component, title, ...rest} = props;
  const location = useLocation();
  const params = useParams();
  const isAuthenticated = useStore((state) => state.isAuthenticated);

  useEffect(
    () => newPageListener(title, location.pathname),
    [title]
  );

  return (
    <Route {...rest} >
      {
        cProps => !isAuthenticated ? 
        <Redirect to='/register' /> :
        <Component {...cProps} />
      }
    </Route>
  );
};

export default PrivateRoute;