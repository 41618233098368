import OrderToEdit from '../entities/OrderToEdit';
import getCarts from './getCarts';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editCartAPI, EditedOrder} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const editCart = (set: SetState<State>, get: GetState<State>) =>
async (orderToEdit: OrderToEdit) => {

  /*
    If any of the below fields have been edited,
    reflect those changes in the store and then return out of the function
  */
  if (
    orderToEdit.editedFields.status ||
    orderToEdit.editedFields.labelOption || 
    orderToEdit.editedFields.comment ||
    orderToEdit.editedFields.comment === ''
  ) {
    const carts = get().checkout.carts;

    if (isDebugging) {
      console.log('orderToEdit:', orderToEdit);//TESTING
    }

    const modifiedCarts = carts.map((cart) => {
      let modifiedCart = cart;
      if (cart.residentId === orderToEdit.residentId && orderToEdit.editedFields.status) {
        modifiedCart = {
          ...cart,
          status: orderToEdit.editedFields.status || 'Still shopping'
        };
      }

      if (orderToEdit.editedFields.labelOption) {
        modifiedCart = {
          ...cart,
          labelOption: orderToEdit.editedFields.labelOption
        };
      }

      if (orderToEdit.editedFields.comment || orderToEdit.editedFields.comment === '') {
        modifiedCart = {
          ...cart,
          comment: orderToEdit.editedFields.comment
        };
      }

      return modifiedCart;
    });

    set((state) => ({
      checkout: {
        ...state.checkout,
        carts: modifiedCarts
      }
    }));

    return;
  }

  try {
    const userId = get().account?.userId;
    if (!userId) throw new Error();

    const editedOrder: EditedOrder = {
      userID: Number(userId),
      residentID: orderToEdit.residentId || 0,
      orderID: orderToEdit.orderId,
      ...orderToEdit.editedFields
    };

    await editCartAPI(editedOrder);

    await getCarts(set, get)();

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('edit cart store error:', err);
    }
    setAlert(set, get)({
      status: 'danger',
      header: 'Error',
      text: err.response.data.message || 'Failed to update cart'
    });
  }
};

export default editCart;
