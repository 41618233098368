import logout from './logout';
import getAccountDetails from './getAccountDetails';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {registerAccountAPI, RegisterUser, RegisterFacility} from '../../../api';
import {authService, isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const register = (set: SetState<State>, get: GetState<State>) =>
	async (account: RegisterUser | RegisterFacility, captchaToken: string) => {
		const accountInStore = get().account;
		
		try {
			if (accountInStore) {
				logout(set);
				
				throw new Error('User is already authenticated');
			}

			const res = await registerAccountAPI(account, captchaToken);
    	const {userID, roleID, userToken, firstName, lastName} = res.data;

			authService.token.set(userToken);

      set({
				isAuthenticated: true,
        account: {
          userId: userID,
          roleId: roleID,
          firstName: firstName,
					lastName: lastName
        }
      });

			setAlert(set, get)({
				status: 'success',
				header: 'Registration Successful',
				text: 'Registration Successful'
			});

			await getAccountDetails(set, get)();

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log('register store error:', err);
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err?.response?.data?.message || 'Failed to register account'
			});
		}
	};

export default register;