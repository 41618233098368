// import returnExchangePdf from '../../media/ReturnsExchangeForm.pdf';
import ReturnsController from './ReturnsController';
import React, {useState, useEffect} from 'react';

type PolicySectionProps = {
  id: string;
  header: string;
  text: JSX.Element;
}

export const ReturnsView = () => {
  const {
    init,
    bannerImage,
    windowWidth,
    onWidthEvent,
    onClickQuestion
  } = ReturnsController();

  const policy = {
    pdf: 'ReturnsExchangeForm',
    shipping: {
      name: 'Senior Shopping Service',
      address: '319 Anderson Road',
      city: 'Chesterfield',
      state: 'IN',
      zip: '46017'
    },
    desc: `We recommend that you send it via a trackable service (ie. UPS, FedEx, or Priority Mail through the post office). Be sure to examine your shipment within three days of receipt of goods to report any damage.\n
      We do always guarantee our merchandise will hold up in your industrial laundry for at least thirty days, whether it is labeled or not. If you happen to have any problems, we will cheerfully replace the item free of charge. Please call us for a shipping label, in this instance.\n
      Even if labeled, you may exchange your merchandise, as long as the item has not been laundered, soiled or altered.\n
      Furniture or Electronic items should be returned with all original packaging, padding, and manual or warranty. Or if item is no longer needed, we can work with you to see if another resident within your facility could use the product. It is the customer's responsibility for shipping returns. Please call when returning furniture items.`
  };

  const PolicySection = ({id, header, text}: PolicySectionProps) => 
  <li className='return-policy__section' onClick={() => onClickQuestion(id)}>
    <h3 className='return-policy__header'>{header}</h3>
    <p id={id} className='return-policy__text-block'>{text}</p>
  </li>;

  useEffect(init, []);

  useEffect(onWidthEvent, [windowWidth]);

  return (
    <section className='return-policy'>
      <div className='return-policy__banner'>
        <img src={bannerImage} alt='banner'/>
        {/* <h1>Return/Exchange Policy</h1> */}
      </div>
      <div className='return-policy__main'>
        <p className='return-policy__preface'>At Senior Shopping Service, we strive to ensure your satisfaction with every purchase. We understand that occasionally, you may need to return or exchange an item. To make this process as smooth as possible, please review our Returns/Exchanges Policy outlined below: </p>
        <PolicySection
          id='p1'
          header='Eligibility'
          text={<>To be eligible for a return/exchange, items must be unused, in the same condition that you received them, and in their original packaging. 
          <br/><br/>Returns/exchanges must be initiated within 3 days from the date of delivery receipt. </>}
        />
        <PolicySection
          id='p2'
          header='Non-Eligible Items'
          text={<>Certain items are not eligible for return/exchange, including, but not limited to: <br/>Furniture, appliances, beds, recliners, lift chairs, wheelchairs, cushions, cuddlers, &  electronics. </>}
        />
        <PolicySection
          id='p3'
          header='Returns'
          text={<>To initiate a return, please contact our customer service team at <a href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a>  to obtain a Return Merchandise Authorization (RMA) number. 
                  <br/><br/>Once you receive your RMA number, please securely package the item(s), and include the RMA number on the package. 
                  <br/><br/>Ship the item(s) to <b>319 Anderson Rd, Chesterfield, IN  46017</b> 
                  <br/><br/>Once we receive and inspect the returned item(s), we will process your refund within seven (7) business days. Please note that shipping charges are non-refundable. </>}
        />
        <PolicySection
          id='p4'
          header='Exchanges'
          text={<>To initiate an exchange, please contact our customer service team at <a href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a>,  to obtain a Return Merchandise Authorization (RMA) number. 
                  <br/><br/>Once you receive your RMA number, please securely package the item(s) you wish to exchange, complete the Return/Exchange Form, and include the RMA number on the package. 
                  <br/><br/>Ship the item(s) to <b>319 Anderson Rd, Chesterfield, IN  46017</b>
                  <br/><br/>Upon receiving the exchanged item(s), we will process the exchange and ship the replacement item(s) to you free of charge. </>}
        />
        <PolicySection
          id='p5'
          header='Defective or Damaged Items'
          text={<>If you receive a defective or damaged item, please contact our customer service team at <a href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a> immediately and include photos of the damage for further assistance. </>}
        />
        <PolicySection
          id='p6'
          header='Shipping Costs'
          text={<>Customers are responsible for shipping costs associated with returns/exchanges unless the item(s) received were defective or damaged. </>}
        />
        <PolicySection
          id='p7'
          header='Policy Changes'
          text={<>Senior Shopping Service reserves the right to update this Returns/Exchanges Policy at any time without prior notice. Any changes will apply to all orders (web, email, catalog, phone, fax, etc.) and will be effective immediately upon posting on our website.  
                  <br/><br/>By making a purchase from Senior Shopping Service, you agree to abide by the terms and conditions outlined in this Returns/Exchanges Policy. 
                  <br/><br/>If you have any questions or concerns regarding our policy, please don't hesitate to contact our customer service team at <a href='mailto:service@seniorshopping.com'>service@seniorshopping.com</a>.  We are here to assist you! </>}
        />
      </div>
    </section>
  );
};

export default ReturnsView;
