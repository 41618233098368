import getCarts from './getCarts';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {emptyCartAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const emptyCart = (set: SetState<State>, get: GetState<State>) =>
async (residentId = 0) => {
  try {
    const userId = get().account?.userId;
    if (!userId) throw new Error('No user id found');

    await emptyCartAPI(Number(userId), residentId);

    getCarts(set, get)();

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('empty carts store error:', err);
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to empty cart'
		});  
  }
};

export default emptyCart;