import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {authService} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const logoutAdmin = (set: SetState<State>, get: GetState<State>) =>
	async () => {

    set({
      admin: undefined
    });

    authService.credentials.clear();

    setAlert(set, get)({
      status: 'neutral',
      header: 'Logged out',
      text: 'Logged out'
    });
	};

export default logoutAdmin;