import {ReactComponent as FacebookLogo} from '../../../media/facebook-logo.svg';
import {ReactComponent as TwitterLogo} from '../../../media/twitter-logo.svg';
import {ReactComponent as InstagramLogo} from '../../../media/instagram-logo.svg';
import {ReactComponent as YoutubeLogo} from '../../../media/youtube-logo.svg';
import {ReactComponent as PintrestLogo} from '../../../media/pintrest-logo.svg';
import companyInfo from '../../../utility/companyInfo';
import React from 'react';

const ContactView = () => {

  return (
    <section className='contact'>
      <h1 className='contact__heading'>Contact Us</h1>
      <p className='contact__subheading'>
        We make dressing E-Z! We encourage your comments and appreciate YOU, our valued customer!
        <br />
        Click here to Tell Us What You Think, or just give us a call! :)
      </p>
      <div className='contact__group'>
        <h3 className='contact__group-heading'>{`Hours (${companyInfo.hours.timeZone}):`}</h3>
        <p>Mon-Fri:</p>
        <p>{`${companyInfo.hours.weekdays.start} - ${companyInfo.hours.weekdays.end}`}</p>
      </div>
      <div className='contact__group'>
        <h3 className='contact__group-heading'>Contact Information:</h3>
        <p>Toll Free:</p>
        <a href={`tel:${companyInfo.phone.main}`}>{companyInfo.phone.main}</a>
        <p>Direct:</p>
        <a href={`tel:${companyInfo.phone.direct}`}>{companyInfo.phone.direct}</a>
        <p>Fax:</p>
        <a href={`tel:${companyInfo.phone.fax}`}>{companyInfo.phone.fax}</a>
        <p>Email (Customer Service):</p>
        <a href={`mailto:${companyInfo.email.customerService}`}>{companyInfo.email.customerService}</a>
        <p>Email (Accounting):</p>
        <a href={`mailto:${companyInfo.email.accounting}`}>{companyInfo.email.accounting}</a>
        <p>Corporate Office:</p>
        <span>
          <p>{companyInfo.shipping.name}</p>
          <p>{companyInfo.shipping.address}</p>
          <p>{`${companyInfo.shipping.city}, ${companyInfo.shipping.state} ${companyInfo.shipping.zip}`}</p>
        </span>
        <p>Social Media:</p>
        <span className='contact__social-links'>
          <a href={companyInfo.social.facebook}><FacebookLogo /></a>
          <a href={companyInfo.social.twitter}><TwitterLogo /></a>
          <a href={companyInfo.social.youtube}><YoutubeLogo /></a>
          <a href={companyInfo.social.pintrest}><PintrestLogo /></a>
          <a href={companyInfo.social.instagram}><InstagramLogo /></a>
        </span>
      </div>
    </section>
  );
};

export default ContactView;
