import {State} from 'application/store';
import {getProductDetailAPI, lookupProductAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const getProductInfo = (set: SetState<State>, get: GetState<State>) =>
	async (itemId: string) => {

    set({
      productInfo: undefined
    });

    const res = await getProductDetailAPI(itemId);

    if (res === null) return;

    const {
      prod_id,
      prod_groupID,
      prod_itemID,
      prod_name,
      prod_color,
      prod_price,
      prod_shipping,
      prod_desc,
      prod_clothingType,
      prod_nurseTip,
      prod_dept,
      prod_subDept,
      prod_superSubDept,
      MFB,
      taxExempt,
      hide,
      imageCount,
      subProds,
      sched_delivery_ID,
      sched_delivery_options
    } = res[0];

    const res2 = await lookupProductAPI(undefined, prod_id);

    if (res2 === null) return;

    set((state) => ({
      productInfo: {
        id: prod_id,
        groupId: prod_groupID,
        itemId: prod_itemID,
        name: prod_name.replace('\'', `'`),
        options: prod_color.replace('\'', `'`),
        priceRange: prod_price,
        shippingCharge: prod_shipping,
        desc: prod_desc.replace('\'', `'`),
        clothingType: prod_clothingType.replace('\'', `'`),
        needs: prod_nurseTip,
        scheduledDeliveryId: sched_delivery_ID || '',
        scheduledDeliveryOptions: sched_delivery_options || '',
        sex: MFB,
        isTaxExempt: taxExempt === 1 ? true : false,
        isHidden: hide === 1 ? true : false,
        imageCount: imageCount || 0,
        depts: {
          dept: prod_dept,
          subDept: prod_subDept,
          superSubDept: prod_superSubDept,
          list: res2 && res2.length > 0 ? res2.map((dept) => ({
            id: dept.ID,
            dept: dept.deptName,
            subDept: dept.subDeptName,
            superSubDept: dept.superSubDeptName
          })) : []
        },
        subProducts: subProds && subProds.length > 0 ? subProds.map((prod) => ({
          id: prod.prod_id,
          itemId: prod.prod_itemID,
          groupId: prod.prod_groupID,
          name: prod.prod_name,
          size: prod.prod_size,
          price: prod.prod_price
        })) : []
      }
    }));
	};

export default getProductInfo;