import type AddRewards from '../entities/AddRewards';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {addRewardsAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import produce from 'immer';

export const addAdmin = (set: SetState<State>, get: GetState<State>) =>
	async (addReward: Omit<AddRewards, 'id'>) => {

		try {
      const admin = get().admin;

      if (!admin) throw new Error('invalid admin credentials');
			
			const res = await addRewardsAPI({
				adminID: admin.id,
        userID: addReward.userID,
        reward_amount: addReward.amount,
        reward_note: addReward.note,
				sendEmail: addReward.shouldSendEmail ? 1 : undefined
			});

      const newRewardAmount: string = res.data.newRewardAmount.toString();
      // const newRewardExpirationDate: string = res.data.newRewardExpirationDate;

      set(produce((state) => {
        state.account.company.rewards = newRewardAmount;
        // state.account.company.rewardsExpiration = newRewardExpirationDate; WAITING FOR BRANDON TO FIX
      }));

			setAlert(set, get)({
				status: 'success',
				header: 'Success',
				text: 'Successfully added rewards'
			});
  
		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log(`addAdmin error:`, err);//TESTING
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to add admin'
			});
		}
	};

export default addAdmin;