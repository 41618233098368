import getResidentList from './getResidentList';
import Resident from '../entities/Resident';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {addResidentAPI, NewResident} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const addResident = (set: SetState<State>, get: GetState<State>) =>
async (newResident: Omit<Resident, 'id'>) => {

  try {
    const modifiedResident: NewResident = {
      userID: newResident.userId,
      resident_fName: newResident.firstName,
      resident_lName: newResident.lastName,
      resident_gender: newResident.sex,
      resident_clothing_size: newResident.clothingSize,
      resident_shoe_size: newResident.shoeSize,
      resident_budget_amount: newResident.budget.toString()
    };

    await addResidentAPI(modifiedResident);

    getResidentList(set, get)();

    setAlert(set, get)({
      status: 'success',
      header: 'Resident Added',
      text: 'Resident has been added'
    });

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('edit resident store error:', err);//TESTING
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to add resident'
		});
  }
};

export default addResident;
