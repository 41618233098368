import {LabelOption} from '../../../api';
import {useStore, OrderToEdit, Resident, CartStatus, CartToFinalize} from '../../../store';
import regex from '../../../utility/regex';
import {ReactComponent as ShoppingCartIcon} from '../../media/icon-shopping-cart.svg';
import {ReactComponent as ImportantIcon} from '../../media/icon-important.svg';
import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import {ReactComponent as CloseIcon} from '../../media/icon-close.svg';
import {ReactComponent as LoadingSmallIcon} from '../../media/icon-loading-small.svg';
import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {
	TextField,
	FormControl,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	InputLabel,
	SelectChangeEvent
} from '@mui/material';

type Props = {
	residentId: number;
};

const Cart = (props: Props) => {
	const {residentId} = props;
	const history = useHistory();
	const account = useStore((state) => state.account);
	const carts = useStore((state) => state.checkout.carts);
	const residents = useStore((state) => state.account?.residents);
	const isProcessing = useStore((state) => state.checkout.isProcessing);
	const dispatchEditCart = useStore((state) => state.dispatchEditCart);
	const dispatchEmptyCart = useStore((state) => state.dispatchEmptyCart);
	const dispatchFinalizeCarts = useStore((state) => state.dispatchFinalizeCarts);
	const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const labelOptions: Array<LabelOption> = [
		'Do not label',
		'Send Labels',
		'Label Garments'
	];
	const [statusOptions, setStatusOptions]  = useState<Array<CartStatus>>([
		'Pay from Trust Account',
		'Bill Facility Directly',
		'Credit Card',
		'Rewards'
	]);
	const defaultStatus: CartStatus = account?.roleId === 3 ? 
	'Still Shopping' : 
	'Credit Card';
	const remainingPaymentOptions: Array<CartStatus> = statusOptions.filter(
		(option) => option !== 'Rewards'
	);
	const [cart, setCart] = useState(carts.find((cart) => cart.residentId === residentId));
	const [cartResident, setCartResident] = useState<Resident | undefined>(
		residents?.find((resident) => resident.id === cart?.residentId)
	);
	const [isAcceptingSubs, setIsAcceptingSubs] = useState(cart?.isAcceptingSubs || false);
	const [selectedStatus, setSelectedStatus] = useState<CartStatus>(defaultStatus);
	const [remainingPayment, setRemainingPayment] = useState<CartStatus | undefined>();
	const [toggleRemPayment, setToggleRemPayment] = useState(false);
	const [selectedLabelOption, setSelectedLabelOption] = useState<LabelOption | undefined>(undefined);
	const [labelName, setLabelName] = useState('');
	const [isSpecialShipping, setIsSpecialShipping] = useState(false);
	const [comment, setComment] = useState('');
	const [rewardsUsed, setRewardsUsed] = useState('');
	const [curTax, setCurTax] = useState(cart?.totals?.tax)
	const [curGrandTotal, setCurGrandTotal] = useState(cart?.totals.grandTotal);
	
	const reset = () => {
		const newCartResident = residents?.find((res) => res.id === residentId);
		const newCart = carts.find((cart) => cart.residentId === residentId);
		
		setCartResident(newCartResident);
		setCart(newCart);
	};
	
	useEffect(reset, [carts, residents, residentId]);

	useEffect(() => {
		if (Number(account?.company?.rewards) <= 0) {
			setStatusOptions([
				'Pay from Trust Account',
				'Bill Facility Directly',
				'Credit Card',
			]);
		}
	}, [account?.company?.rewards]);

	useEffect(() => {
		if (selectedStatus !== 'Rewards' && rewardsUsed !== '') {
			calcRewards('0');
		}
	}, [selectedStatus]);

	if (!cart) return <></>;

	const toggleIsAcceptingSubs = () => setIsAcceptingSubs(!isAcceptingSubs);

	const onChangeQuantity = (newQuantity: number, orderId: number) => {
		if (newQuantity === 0) {
			const message = `Are you sure you'd like to remove this item?`;
			const isConfirmed = window.confirm(message);
			if (!isConfirmed) return;
		}
		const orderToEdit: OrderToEdit = {
			orderId: orderId,
			residentId: cart.residentId,
			editedFields: {
				quantity: newQuantity
			}
		};
		dispatchEditCart(orderToEdit);
	};
	
	const onDelete = () => {
		const message = `Are you sure you want to clear this cart?`;
		const deleteConfirm = window.confirm(message);
		if (deleteConfirm) dispatchEmptyCart(cart.residentId);
	};

	const onContinueShopping = () => {
		if (cartResident) {
			useStore.setState({
				selectedResident: cartResident
			});
		}

		history.push(`/productList/dept/${cartResident?.sex === 'F' ? '1' : '2'}`);
	};

	const onChangePayment = (e: SelectChangeEvent<unknown>, type: 'primary' | 'remaining') => {
		const valueAsNum = Number(e.target.value);
		let newSelection;

		if (type === 'primary') {
			newSelection = statusOptions[valueAsNum]
			setSelectedStatus(newSelection)
		} else {
			newSelection = remainingPaymentOptions[valueAsNum]
			setRemainingPayment(newSelection)
		}

		if (newSelection === 'Rewards' && curGrandTotal) {
			calcRewards(curGrandTotal)
		}
	}

	const onChangeInputRewards = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		calcRewards(e.target.value);
	};

	const onBlurInputRewards = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		
		if (rewardsUsed.endsWith('.')) {
			const correctedValue = rewardsUsed.replaceAll('.', '');
			setRewardsUsed(correctedValue);
		}
		
		if (regex.hasPartialCents(rewardsUsed)) {
			const correctedValue = rewardsUsed + '0';
			setRewardsUsed(correctedValue);
		}
	};
	
	const calcRewards = (curRewardsString: string) => {
		
		if (!curRewardsString || curRewardsString === '') {
			setRewardsUsed('');
			calcTax('0');
			return;
		}
		
		if (!regex.isValidCurrency(curRewardsString)) return;
	
		const curRewards = Number(curRewardsString);
		const maxRewards = Number(account?.company?.rewards);
		const subTotal = Number(cart.totals.subTotal);
		const shipping = Number(cart.totals.shipping);
		const totalBeforeTax = subTotal + shipping;

		const validAmount = Math.min(
			curRewards,
			maxRewards,
			totalBeforeTax
		);
	
		const newCurRewards = curRewards === validAmount ?
		curRewardsString : validAmount.toString();
		
		setRewardsUsed(newCurRewards);
		calcTax(newCurRewards);
	};

	const calcTax = (newRewardsString: string) => {
		if (!cart || cart.totals.taxrate === undefined) return dispatchSetAlert({
			status: 'danger',
			header: 'Error',
			text: 'There was an error calculating tax. Please try again later.'
		}, undefined, `taxrate: ${cart.totals.taxrate}`);

		let subTotal = Number(cart.totals.subTotal);
		const shipping = Number(cart.totals.shipping);
		const newRewards = Number(newRewardsString);

		// Additional error checks in case of type conversion errors
		if (
			isNaN(subTotal) ||
			isNaN(shipping) ||
			isNaN(newRewards)
		) return dispatchSetAlert(
			{
				status: 'danger',
				header: 'Invalid Input',
				text: 'There was an error calculating tax amount, please refresh the page and try again.'
			},
			undefined,
			`
				subTotal: ${subTotal}
				shipping: ${shipping}
				selectedRewards: ${newRewards}
			`
		);
		
		const taxRate = cart.totals.taxrate;

		if (newRewards > shipping) {
			const rewardsAfterShipping = newRewards - shipping;
			subTotal -= rewardsAfterShipping;
		}
		
		const newTax = subTotal * taxRate;
		const newTaxRounded = Math.round((newTax + Number.EPSILON) * 100) / 100;
		
		setCurTax(newTaxRounded.toString());
		calcGrandTotal(newRewardsString, newTaxRounded.toString());
	};
	
	const calcGrandTotal = (newRewardsString: string, newTaxString: string) => {
		if (!cart) return dispatchSetAlert({
			status: 'danger',
			header: 'Error',
			text: 'There was an error calculating the total. Please try again later.'
		}, undefined, `cart: ${cart}`);
		
		const subTotal = Number(cart.totals.subTotal);
		const shipping = Number(cart.totals.shipping);
		const newRewards = Number(newRewardsString);
		const newTax = Number(newTaxString);
		
		// Additional error checks in case of type conversion errors
		if (
			isNaN(subTotal) ||
			isNaN(shipping) ||
			isNaN(newRewards) ||
			isNaN(newTax)
		) return dispatchSetAlert(
			{
				status: 'danger',
				header: 'Invalid Input',
				text: 'There was an error calculating total amount, please refresh the page and try again.'
			},
			undefined,
			`
				selectedRewards: ${newRewards}
				calcTax: ${newTax}
				subTotal: ${subTotal}
				shipping: ${shipping}
			`
		);

		const newGrandTotal = subTotal + shipping - newRewards + newTax;
		const newGrandTotalRounded = Math.round((newGrandTotal + Number.EPSILON) * 100) / 100

		setCurGrandTotal(newGrandTotalRounded.toString());

		if (newGrandTotal > 0) {
			setToggleRemPayment(true);

		} else {
			setToggleRemPayment(false);
		}
	};
	

	const onFinalize = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (account?.roleId === 3 && !cartResident) return dispatchSetAlert({
			status: 'danger',
			header: 'Error',
			text: 'Missing cart resident, please reload page and try again.'
		}, undefined, `cart resident: ${cartResident}`);

		if (selectedStatus === 'Still Shopping') return dispatchSetAlert({
			status: 'warning',
			header: 'Invalid Input',
			text: 'Please select a payment type.'
		});

		if (rewardsUsed.endsWith('.')) return dispatchSetAlert({
			status: 'warning',
			header: 'Invalid Input',
			text: 'Please provide a valid dollar reward amount'
		});
		
		if (!curTax) return dispatchSetAlert(
			{
				status: 'danger',
				header: 'Checkout Error',
				text: 'There was an error calculating tax. Please try again later.'
			},
			undefined,
			`Calculated tax: ${curTax}`
		);
		
		if (!curGrandTotal) return dispatchSetAlert({
			status: 'danger',
			header: 'Error',
			text: 'There was an error calculating the total. Please try again later.'
		}, undefined, `grandTotal: ${curGrandTotal}`);

		if (
			(selectedStatus === 'Credit Card' || remainingPayment === 'Credit Card') &&
			Number(curGrandTotal) < 1
		) {
			return dispatchSetAlert({
				status: 'warning',
				header: 'Error',
				text: 'Credit card payments must be $1 or more.'
			});
		}

		const usingRewards = selectedStatus === 'Rewards' || remainingPayment === 'Rewards';

		if (usingRewards && Number(rewardsUsed) <= 0) {
			return dispatchSetAlert({
				status: 'warning',
				header: 'Error',
				text: 'If selecting rewards, amount must be greater than 0.'
			})
		}
		
		const cartToFinalize: CartToFinalize = {
			residentId: cartResident?.id || 0,
			status: remainingPayment || selectedStatus,
			isAcceptingSubs: isAcceptingSubs,
			labelOption: selectedLabelOption,
			labelName: labelName,
			comment: comment,
			orders: cart.orders,
			totals: {
				...cart.totals,
				tax: curTax,
				shipping: cart.totals.shipping,
				grandTotal: curGrandTotal
			},
			specialShipping: isSpecialShipping,
			rewardsUsed: usingRewards ? rewardsUsed : undefined
		};

		dispatchFinalizeCarts(cartToFinalize);
	};

	return (
		<div className='cart'>
			<div className='cart__main'>

				<div className='cart__banner'>
					<div className='cart__banner-left'>
						<span className='cart__clear'>
							<button onClick={onDelete}>
								<CloseIcon />
							</button>
						</span>
						{
							cart &&
							cart.residentId !== 0 &&
							cartResident &&
							<div className='cart__resident'>
								<ShoppingCartIcon />
								<span className='cart__resident-name'>
									<h5>
										{`${cartResident.firstName} ${cartResident.lastName}${cartResident.lastName.endsWith('s') ? `'` : `'s`}`}
									</h5>
									<h6>shopping cart</h6>
								</span>
							</div>
						}
						<div className='cart__substitution-prompt'>
							<FormControlLabel
								label='Substitutions Acceptable'
								control={
									<Checkbox
										name='substitutions'
										checked={isAcceptingSubs}
										onChange={toggleIsAcceptingSubs}
									/>
								}
							/>
						</div>
					</div>
					<div className='cart__banner-right'>
						{
							cart &&
							cart.residentId !== 0 &&
							cartResident &&
							<>
								<div className='cart__resident-info'>
									<h5 className='cart__total-label'>Cart Total:</h5>
									<h5 className='cart__total cart__total--banner'>
										<span>$</span>
										{Number(cart.totals.grandTotal).toLocaleString('en-US')}
									</h5>
									<h6 className='cart__budget-label'>Budget:</h6>
									<h6 className='cart__budget'>
										<span>{cartResident.budget ? '$' : ''}</span>
										{cartResident.budget ? Number(cartResident.budget).toLocaleString('en-US') : '--'}
									</h6>
								</div>
								{
									cartResident.budget &&
									Number(cart.totals.grandTotal) > cartResident.budget ?
									<span className='cart__flag cart__flag--danger'>
										<ImportantIcon />
										<h5>Over Budget</h5>
									</span> :
									<></>
								}
							</>
						}
					</div>
				</div>

				<div className='cart__main-info'>
					<h6 className='cart__product-id-label hide--small'>Item No.</h6>
					<h6 className='cart__product-desc-label'>Description</h6>
					<h6 className='cart__product-size-label hide--small'>Size/Info</h6>
					<h6 className='cart__product-quantity-label'>Quantity</h6>
					<h6 className='cart__product-price-label'>Price</h6>
					<h6 className='cart__product-total-label'>Total</h6>
					{
						cart.orders && cart.orders.map((order, index) =>
							<>
								<p className='cart__product-id hide--small'>{order.product.id}</p>
								<p className='cart__product-name'>{`${order.product.name}${order.product.option ? ` (${order.product.option})`: ''}`}</p>
								<p className='cart__product-size hide--small'>{order.product.size || '--'}</p>
								<div className='cart__product-quantity'>
									<span>
										<button onClick={() => onChangeQuantity(order.product.quantity - 1, order.id)}>-</button>
										<p>{order.product.quantity}</p>
										<button onClick={() => onChangeQuantity(order.product.quantity + 1, order.id)}>+</button>
									</span>
									<button className='cart__product-delete' onClick={() => onChangeQuantity(0, order.id)}>
										<TrashIcon />
									</button>
								</div>
								<p className='cart__product-price'>
									<span>$</span>{Number(order.product.price).toLocaleString('en-US')}
								</p>
								<p className='cart__product-total'>
									<span>$</span>{Number(order.lineTotal).toLocaleString('en-US')}
								</p>
							</>
						)
					}
					<div className='cart__subtotals'>
						<h6>
							SubTotal:
						</h6>
						<p>
							<span>$</span>
							{cart?.totals.subTotal}
						</p>
						<h6 className='cart__tax'>
							{`Sales Tax${account?.roleId !== 3 ? '*' : ''}:`}
							{
								account?.roleId !== 3 &&
								<p>(Estimate)</p>
							}
						</h6>
						<p>
							<span>$</span>
							{curTax}
						</p>
						<h6 className='cart__shipping'>
							{`Shipping${account?.roleId !== 3 ? '*' : ''}:`}
							{
								account?.roleId !== 3 &&
								<p>(Estimate)</p>
							}
						</h6>
						<p>
							<span>$</span>
							{cart?.totals.shipping}
						</p>
						{
							rewardsUsed !== '' &&
							rewardsUsed !== '0' &&
							<>
								<h6>Rewards:</h6>
								<p>
									<span>$</span>
									{'-' + rewardsUsed}
								</p>
							</>
						}
					</div>
					<div className='cart__grandtotal'>
						<h4>Total:</h4>
						<p>
							<span><strong>$</strong></span>
							<strong>{curGrandTotal}</strong>
						</p>
					</div>
				</div>
			</div>

			<aside className='cart__aside'>
					<button
						className='btn btn--secondary hide--medium'
						onClick={onContinueShopping}
					>
						Continue Shopping
					</button>
					<form className='cart__checkout' onSubmit={onFinalize}>
						{
							cartResident &&
							<span className='cart__status'>
								<FormControl>
									<InputLabel id='status-label'>Payment:</InputLabel>
									<Select
										labelId='status-label'
										label='Payment:'
										onChange={(e) => onChangePayment(e, 'primary')}
										defaultValue={''}
										required
									>
										{
											statusOptions.map((option, index) =>
												<MenuItem
													value={index}
													key={index}
												>
													{option}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
							</span>
						}
						{
							selectedStatus === 'Rewards' &&
							<span>
								<TextField
									InputLabelProps={{ required: false }}
									label='Apply Rewards'
									type='text'
									placeholder='$0.00'
									value={rewardsUsed}
									onChange={onChangeInputRewards}
									onBlur={onBlurInputRewards}
									required
								/>
							</span>
						}
						{
							toggleRemPayment &&
							<span className='cart__status'>
								<FormControl>
									<InputLabel id='status-label'>Remaining Payment:</InputLabel>
									<Select
										labelId='status-label'
										label='Remaining Payment:'
										onChange={(e) => onChangePayment(e, 'remaining')}
										required
									>
										{
											remainingPaymentOptions.map((option, index) =>
												<MenuItem
													value={index}
													key={index}
												>
													{option}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
							</span>							
						}
						<span>
							<FormControl>
								<InputLabel id='instruction-label'>Labeling:</InputLabel>
								<Select
									labelId='instruction-label'
									label='Labeling:'
									onChange={(e) => setSelectedLabelOption(labelOptions[Number(e.target.value)])}
									defaultValue={''}
								>
									{
										labelOptions.map((option, index) =>
											<MenuItem value={index} key={index}>{option}</MenuItem>
										)
									}
								</Select>
							</FormControl>
						</span>
						{
							!cartResident &&
							<span className='cart__label-name'>
								<TextField
									label='Label Name:'
									value={labelName}
									onChange={(e) => setLabelName(e.target.value)}
								/>
							</span>
						}
						{
							(!account || account.roleId !== 3) &&
							<span>
								<FormControl>
									<InputLabel id='special-shipping-label'>Shipping to Alaska or Hawaii?</InputLabel>
									<Select
										labelId='special-shipping-label'
										label='Shipping to Alaska or Hawaii?'
										onChange={(e) => setIsSpecialShipping(e.target.value === 'T' ? true : false)}
										value={isSpecialShipping ? 'T' : 'F'}
										required
									>
										<MenuItem value='F'>No</MenuItem>
										<MenuItem value='T'>Yes</MenuItem>
									</Select>
								</FormControl>								
							</span>
						}
						<span>
							<TextField
								label='Comments:'
								multiline
								maxRows={4}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							/>
						</span>
						<button
							className='btn btn--primary cart__submit'
							disabled={
								isProcessing ||
								(cartResident && !selectedStatus) ||
								(
									!cartResident &&
									selectedLabelOption &&
									selectedLabelOption !== 'Do not label' &&
									!labelName
								)
							}
							type='submit'
						>
							{
								isProcessing ?
								<LoadingSmallIcon /> :
								cartResident ?
								`Finalize ${cartResident.firstName}${cartResident.firstName.endsWith('s') ? `'` : `'s`} Cart` :
								'Check Out'
							}
						</button>
					</form>
			</aside>
			{/* {
				(!account ||
				account?.roleId !== 3) &&
				<span className='cart__notice'>
					*Values are estimates
				</span>
			} */}
		</div>
	);
};

export default Cart;
