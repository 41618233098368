import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const subscribeToNewsletterPort = '/addNewsletterSignup';

const subscribeToNewsletterAPI = async (email: string, captchaToken: string): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');
  const res = await api.post(
    subscribeToNewsletterPort,
    {
      email: email
    },
    {
      headers: {
        remoteip: '',
        captchaToken: captchaToken
      }
    }
  );

  if (isDebugging) {
    console.log(`${subscribeToNewsletterPort} response:`, res);//TESTING
  }

  return res;
};

export default subscribeToNewsletterAPI;