import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Response = [
  {
    ID: number;
    bannerText: string;
  }
];

const getBannerText = '/getBannerText';

/**Retrieves the text shown in the notification bar. */
const getBannerTextAPI = async (): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getBannerText);

  if (isDebugging) {
    console.log(`${getBannerText} response:`, res);//TESTING
  }

  return res;
};

export default getBannerTextAPI;