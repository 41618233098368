import getIndividualInvoices from './getIndividualInvoices';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editIndividualInvoiceAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const editIndividualInvoice = (set: SetState<State>, get: GetState<State>) =>
	async (userId: string, invoiceId: string, isProcessed: boolean) => {

		const req = {
			userId: userId,
			invoiceId: invoiceId,
			isProcessed: isProcessed
		};
		
		const res = await editIndividualInvoiceAPI(req);
		
		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully updated invoice'
		});

		getIndividualInvoices(set, get)();
	};

export default editIndividualInvoice;