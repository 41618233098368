import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const getNavigationPort = '/getNavigation';

const getNavigationAPI = async () => {
  const res = await api.get(getNavigationPort);
  
  if (isDebugging) {
    console.log(`${getNavigationPort} response:`, res);//TESTING
  }
  
  return res;
};

export default getNavigationAPI;