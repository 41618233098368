import {post} from 'application/api/baseApi';

type Request = {
  headline: string;
  subhead: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyLandingPage';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const editHeroTextAPI = (req: Request) => post<Response>(path, req);

export default editHeroTextAPI;