import SizingController from './SizingController';
import {useEffect} from 'react';

const SizingView = () => {
  const {
    init,
    selectedNav,
    onChangeNav,
    windowWidth
  } = SizingController();

  useEffect(init, []);

  return (
    <section className='size-guide'>
      <span className='size-guide__nav'>
        <button
          className={`size-guide__nav-item ${
            selectedNav.index === 1 ? 'size-guide__nav-item--selected' : ''
          }`}
          onClick={() => onChangeNav(1)}
        >
          Women's Size Chart
        </button>
        <button
          className={`size-guide__nav-item ${
            selectedNav.index === 2 ? 'size-guide__nav-item--selected' : ''
          }`}
          onClick={() => onChangeNav(2)}
        >
          Measuring Women
        </button>
        <button
          className={`size-guide__nav-item ${
            selectedNav.index === 3 ? 'size-guide__nav-item--selected' : ''
          }`}
          onClick={() => onChangeNav(3)}
        >
          Men's Size Chart
        </button>
        <button
          className={`size-guide__nav-item ${
            selectedNav.index === 4 ? 'size-guide__nav-item--selected' : ''
          }`}
          onClick={() => onChangeNav(4)}
        >
          Measuring Men
        </button>
      </span>
      {
        windowWidth < 1000 ?
        <div className='size-guide__main size-guide__main--mobile'>
          <img src={selectedNav.mobileImage} alt={selectedNav.imgAlt}/>
        </div> :
        <div className='size-guide__main'>
          <img src={selectedNav.desktopImage} alt={selectedNav.imgAlt}/>
        </div>
      }
    </section>
  );
};

export default SizingView;
