import {CarouselItem} from '../../utility/Carousel';
import {useStore, LandingCarousel} from '../../../store';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

const LandingController = () => {
  const account = useStore((state) => state.account);
  const storeCarousel = useStore((state) => state.carousels.landing);
  const dispatchGetHeroText = useStore((state) => state.dispatchGetHeroText);
  const dispatchGetLandingCarousel = useStore((state) => state.dispatchGetLandingCarousel);
  const history = useHistory();
  const [isPopupActive, setIsPopupActive] = useState(false);
  const imageLocation = `${document.location.origin}/product_images/cover/`;
  const cachedCarousel = localStorage.getItem('landingCarousel');
  const defaultCarousel: Array<LandingCarousel> = cachedCarousel ?
  JSON.parse(cachedCarousel) :
  [
    {
      id: 1,
      image: '1.png',
      link: '/productList/dept/3/9/46',
      buttonText: 'Shop Now',
      headerText: 'Shop for Multiple Residents!',
      subHeaderText: 'One easy pay using your RFMS',
      isHidden: false
    },
    {
      id: 2,
      image: '2.png',
      link: '/productList/dept/5/19',
      buttonText: 'Shop Now',
      headerText: 'Shop for Multiple Residents!',
      subHeaderText: 'One easy pay using your RFMS',
      isHidden: false
    }
  ];
  const [carousel, setCarousel] = useState(
    storeCarousel.length > 0 ?
    storeCarousel : 
    defaultCarousel
  );
  const [curCarousel, setCurCarousel] = useState(carousel[0]);
  const [curCarouselIndex, setCurCarouselIndex] = useState(0);
  
  const init = () => {
    dispatchGetHeroText();
    dispatchGetLandingCarousel(false);
  };

  const onChangeCurCarouselIndex = () => {
    const newCarousel = carousel[curCarouselIndex];
    setCurCarousel(newCarousel);
  };

  const onAccountChange = () => setIsPopupActive(!account || account.roleId !== 3);

  const onClosePopup = () => setIsPopupActive(false);

  return {
    account,
    isPopupActive,
    curCarousel,
    curCarouselIndex,
    imageLocation,
    storeCarousel,
    carousel,
    init,
    onAccountChange,
    onClosePopup,
    setCurCarousel,
    setCurCarouselIndex,
    setCarousel,
    onChangeCurCarouselIndex
  };
};

export default LandingController;