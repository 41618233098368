import handleReCAPTCHA from '../../../utility/google/handleReCAPTCHA';
import {useRecaptchaContext} from '../../../context/recaptcha';
import {useStore} from '../../../../store';
import {orderCatalogAPI} from '../../../../api';
import React, {useState, ChangeEvent, FormEvent} from 'react';
import {useLocation} from 'react-router-dom';
import {allCountries, CountryData, Region} from 'country-region-data';
import {SelectChangeEvent} from '@mui/material';

const FreeCatalogController = () => {
  const account = useStore((state) => state.account);
  const dispatchGetAccountDetails = useStore((state) => state.dispatchGetAccountDetails);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phoneNumber: '',
    email: '',
    quantity: 1
  });
	const [selectedCountry, setSelectedCountry] = useState<CountryData | undefined>();
	const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();
  const [submitButtonAttributes, setSubmitButtonAttributes] = useState({
    disabled: false,
    text: 'Get Free Catalog'
  });
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRecaptchaContext();

  const updateAccountInfo = () => {
    if (!account || account.roleId !== 3) return;

    setFormData({
      ...formData,
      firstName: account.firstName || '',
      lastName: account.lastName || '',
      companyName: account.company?.name || '',
      address1: account.shipping?.address1 || '',
      address2: account.shipping?.address2 || '',
      city: account.shipping?.city || '',
      zip: account.shipping?.zip || '',
      phoneNumber: account.phoneNumber || '',
      email: account.email || ''
    });

    const matchingCountry = allCountries.find(
      (country) => country[1] === account.shipping?.country
    );

    if (matchingCountry) {
      setSelectedCountry(matchingCountry);

      const matchingRegion = matchingCountry[2].find(
        (region) => region[1] === account.shipping?.state
      );

      if (matchingRegion) setSelectedRegion(matchingRegion);
    }
  };

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) =>
  setFormData({
    ...formData,
    [event.target.name]: event.target.value
  });

  const onChangeState = (e: SelectChangeEvent<string>) =>
  setFormData({
    ...formData,
    country: e.target.value
  });

  const onChangeCountry = (e: SelectChangeEvent<string>) => {
    setSelectedCountry(allCountries.find((country) => country[1] === e.target.value));
    setFormData({...formData, country: e.target.value});
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

		if (!selectedCountry) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Please select a country'
			});
			return;
		}
		if (!selectedRegion) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Please select a region'
			});
			return;
		}    
    
    const token = await handleReCAPTCHA(recaptchaRef);
    if (!token) return;

    const request = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      phoneNumber: formData.phoneNumber,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      country: selectedCountry[1],
      state: selectedRegion[1],
      zip: formData.zip,
      email: formData.email,
      catalogCount: formData.quantity.toString()
    };

    const res = await orderCatalogAPI(request, token);

      if (res.status === 200) {
        dispatchSetAlert({
          status: 'success',
          header: 'Success',
          text: 'Your request for catalogs have been sent!'
        });

        setSubmitButtonAttributes({
          disabled: true,
          text: 'Request Sent'
        });
      } else {
        dispatchSetAlert({
          status: 'danger',
          header: 'Failure',
          text: 'Oops, there was a problem submitting your request'
        });
      }

      setFormData({
        ...formData,
        quantity: 1
      });

      setIsLoading(false);
  };

  return {
    account,
    formData,
    updateAccountInfo,
    location,
    selectedCountry,
    selectedRegion,
    setSelectedCountry,
    setSelectedRegion,
    onChangeInput,
    onChangeState,
    onChangeCountry,
    onSubmit,
    isLoading,
    submitButtonAttributes
  };
};

export default FreeCatalogController;