import {useStore} from '../../../store';
import React, {useEffect, Dispatch, SetStateAction} from 'react';
import {allCountries, CountryData} from 'country-region-data';
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';

interface Props extends SelectProps {
  selectedCountry: CountryData | undefined;
  setSelectedCountry: Dispatch<SetStateAction<CountryData | undefined>>;
};

const SelectCountry = ({selectedCountry, setSelectedCountry, required = true,...rest}: Props) => {
  // const {selectedCountry, setSelectedCountry, required = true} = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const unitedStates = allCountries.find((country) => country[1] === 'US');

  const onChangeSelectedCountry = (e: SelectChangeEvent<unknown>) => {
    const newCountry = allCountries.find((country) => country[1] === e.target.value);

    if (newCountry) {
      setSelectedCountry(newCountry);
    } else {
      dispatchSetAlert({
        status: 'danger',
        header: 'Error',
        text: 'Error occured while attempting to change country'
      });
    }
  };

  useEffect(() => {
    if (!selectedCountry && unitedStates) {
      setSelectedCountry(unitedStates);
    }
  }, [selectedCountry]);

  return (
    <FormControl>
      <InputLabel id='country-label'>Country</InputLabel>
      <Select
        label='Country'
        labelId='country-label'
        id='country'
        value={selectedCountry ? selectedCountry[1] : ''}
        onChange={onChangeSelectedCountry}
        required={required}
        {...rest}
      >
        <MenuItem value='' selected disabled>Select country</MenuItem>
        {
          allCountries.map((country, index) =>
            <MenuItem value={country[1]} key={index}>{country[0]}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
};

export default SelectCountry;