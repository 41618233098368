import React, {MouseEvent, ChangeEvent} from 'react';
import {ICellRendererParams} from 'ag-grid-community';

const CheckboxRenderer = (props: ICellRendererParams) => {
  const {value, node, column} = props;

  const onClick = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    const colId = column?.getColId();

    if (!colId) return;
    
    node.setDataValue(colId, checked);
  };

  return (
    <span style={{display: 'flex', marginLeft: '-40%', marginTop: '20%'}}>
      <input
        type='checkbox'
        style={{marginRight: 'auto'}}
        checked={value}
        onChange={onClick}
      />
    </span>
  );
};

export default CheckboxRenderer;