import {Breadcrumbs, useStore} from 'application/store';
import {useLocation} from 'react-router-dom';
import React, {useEffect} from 'react';

/**
 * Custom hook to handle breadcrumb state
 * @param breadcrumbs Breadcrumbs to display
 */
const useBreadcrumbs = (breadcrumbs: Breadcrumbs) => {
  const dispatchCreateBreadcrumbs = useStore((state) => state.dispatchCreateBreadcrumbs);

  useEffect(
    () => dispatchCreateBreadcrumbs(breadcrumbs),
    []
  );
};

export default useBreadcrumbs;