import {useStore} from '../../../store';
import React from 'react';

/**
 * Component that indicates to the user that they are on the admin side of the website.
 * Also displays any alerts sent throughout the website.
 * @returns Banner component
*/
const AdminBanner = () => {
  const alerts = useStore((state) => state.alerts);

  return <>
    {
      alerts &&
      alerts.length > 0 ?
      <div className={`banner banner--${alerts[alerts.length - 1].status}`}>
        <h5 className='banner__alert'>
          {alerts[alerts.length - 1].text}
        </h5>
      </div> :
      <div className='banner'>
        <h5 className='banner__alert'>
          Admin
        </h5>
      </div>
    }
  </>
};

export default AdminBanner;
