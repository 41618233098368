import {useStore} from 'application/store';

export type ErrorSeverity = 'high' | 'low';

export type ErrorConfig = {
  display?: boolean;
  log?: boolean;
  severity?: ErrorSeverity;
};

/**Custom domain error that includes custom properties and methods*/
export default class DomainError extends Error {
  data: any;
  severity: ErrorSeverity;

  /**
   * Creates a new DomainError instance.
   * @param error
   * @param message 
   * @param config Optional config to alter behavior 
   * @returns New DomainError instance
   */
  constructor(
    error: any,
    message: string,
    config?: ErrorConfig
  ) {
    super();

    this.name = 'DomainError'
    this.message = message;
    this.data = { error };
    this.severity = config?.severity || 'high';

    if (config) {
      if (config.log) this.log();
      if (config.display) this.display();
    }
  }

  log() {
    console.log(this.name, this.message, this.data);
  }

  display() {
    const {dispatchSetAlert} = useStore.getState();

    dispatchSetAlert({
      status: this.severity === 'high' ? 'danger' : 'warning',
      header: this.name,
      text: this.message
    });
  }
};