import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = [
  {
    ID: number;
    email: string;
    fName: string;
    lName: string;
    title: string;
    createdDate: string;
  }
];

const getSubAccountsPort = '/getSubAccountList';

const getSubAccountsAPI = async (userId: string): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getSubAccountsPort, {
    params: {
      userID: userId
    }
  });

  if (isDebugging) {
    console.log(`${getSubAccountsPort} response:`, res);//TESTING
  }

  return res;
};

export default getSubAccountsAPI;