import thankYouImage from '../../media/ThankYou.png';
import {useStore} from '../../../store';
import {checkoutCompleteAPI, checkoutCancelAPI} from '../../../api';
import React, {Fragment, useState, useEffect} from 'react';
import {useLocation, Link} from 'react-router-dom'

const PostCheckout = () => {
  const search = useLocation().search;
  const account = useStore((state) => state.account);
  const checkoutFinalized = useStore((state) => state.checkout.isFinalized);
  // const history = useHistory();
  const result = new URLSearchParams(search).get('result');
  const userID = new URLSearchParams(search).get('userID');
  const invID = new URLSearchParams(search).get('invID');
  const session_id = new URLSearchParams(search).get('session_id');
  const successStatus = userID && invID && session_id && result === 'success';
  const cancelStatus = userID && invID && session_id && result === 'cancel';

  const resetIsFinalized = () => useStore.setState((state) => ({
    checkout: {
      ...state.checkout,
      isFinalized: false
    }
  }));

  // if (!userID || !invID || !session_id) history.push('/');

  if (successStatus) {
    checkoutCompleteAPI(userID, invID, session_id);
  } else {
    checkoutCancelAPI(userID || '', invID || '', session_id || '');
  }

  useEffect(() => {
    resetIsFinalized();
  }, [])

  return (
    <section className='post-checkout'>
      {
        successStatus ?
        <>
          <img src={thankYouImage} alt='Thank You'/>
          <span>
            <h3 className='post-checkout__desc'>Thank you for your order!</h3>
            {
              account && account.roleId === 3 ?
              <p className='post-checkout__facility'>A copy of your invoice has been sent to your Business Office Manager's email.</p> :
              <p className='post-checkout__facility'>A copy of your invoice has been sent to your email.</p>
            }
            <p>
              If you have any questions, please contact us
              at <a href='tel:8003342897'>(800) 334-2897</a>.
            </p>
          </span>
          <Link to={`/invoice/view/${userID}/${invID}`}>
            To view the invoice now, click here.
          </Link>
        </> :
        cancelStatus ?
        <>
          <h1 className='post-checkout__heading'>Checkout was canceled</h1>
          <h3 className='post-checkout__desc'></h3>
        </> :
        <>
          <h1 className='post-checkout__heading'>There was an error while processing the order</h1>
        </>
      }
    </section>
  );
};

export default PostCheckout;


