import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {getNavigationAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const getNavigation = (set: SetState<State>, get: GetState<State>) => async () => {

  try {
    const res = await getNavigationAPI();

    set({
      navigation: res.data
    });

    localStorage.setItem('navigation', JSON.stringify(res.data));

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get navigation store error:', err);
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to retrieve navigation'
		});
  }
};

export default getNavigation;