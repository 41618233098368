import {post} from 'application/api/baseApi';

type Request =   {
  state: string;
  county: string;
  tax_rate: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyTaxRate';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const addSubProductAPI = (req: Request) => post<Response>(path, req);

export default addSubProductAPI;