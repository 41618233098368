import Banner from './Banner';
import Register from './register';
import Login from './login';
import HelpCenter from '../helpCenter';
import registerImage from '../../../media/Register_Image.jpg';
import React from 'react';
import {useLocation} from 'react-router-dom';

const PreAuth = () => {
	const location = useLocation();

	return (
		<section className='auth'>
			<img src={registerImage} alt='two happy seniors'/>
			<div className='auth__main'>
				<Banner />
				{
					location.pathname === '/register' ?
					<Register /> :
					location.pathname === '/login' ?
					<Login /> :
					<HelpCenter />
				}
			</div>
		</section>
	);
};

export default PreAuth;