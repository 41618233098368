import EditAccount from './EditAccount';
import ChangePassword from './ChangePassword';
import InvoiceList from './InvoiceList';
import EditResidents from './EditResidents';
import Rewards from './Rewards';
import FacilityForms from '../info/facForms/FacFormsView';
import FreeCatalog from '../info/freeCatalog/FreeCatalogView';
import AccountNav from '../navigation/account/AccountNavView';
import PrivateRoute from '../../utility/routing/PrivateRoute';
import PublicRoute from '../../utility/routing/PublicRoute';
import {
	useLocation,
	Switch
} from 'react-router-dom';

/**Edit Account Page */
const AccountDashboard = () => {
	const location = useLocation();

	return (
		<section className='acc-dashboard'>
			<AccountNav currentPath={location.pathname}/>
			<div className='acc-dashboard__main'>
				<Switch>
					<PrivateRoute
						title='Edit Account'
						exact
						path='/account/edit'
						component={EditAccount}
					/>
					<PrivateRoute
						title='Manage Residents'
						exact
						path='/account/residents'
						component={EditResidents}
					/>
					<PrivateRoute
						title='Invoices'
						exact
						path='/account/invoices'
						component={InvoiceList}
					/>
          <PrivateRoute
						title='Rewards'
						exact
						path='/account/rewards'
						component={Rewards}
					/>
					<PublicRoute
						title='Free Catalog'
						exact
						path='/account/freeCatalog'
						component={FreeCatalog}
					/>
					<PublicRoute
						title='Facility Forms'
						exact
						path='/account/facilityForms'
						component={FacilityForms}
					/>
					<PrivateRoute
						title='Change Password'
						exact
						path='/account/password'
						component={ChangePassword}
					/>
				</Switch>
			</div>
		</section>
	);
};

export default AccountDashboard;
