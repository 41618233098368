import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const resetPasswordPort = '/recoverAccount';

const resetPasswordAPI = async (
  token: string,
  newHashedPassword: string,
  newHashedPasswordConfirm: string,
  captchaToken: string
): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');

  const res = await api.get(resetPasswordPort, {
    headers: {
      remoteip: '',
      captchaToken: captchaToken
    },
    params: {
      rtype: 'processReset',
      reqcode: token,
      newPword: newHashedPassword,
      newPwordConfirm: newHashedPasswordConfirm
    }
  });

  if (isDebugging) {
    console.log(`${resetPasswordPort} response:`, res);//TESTING
  }

  return res;
};

export default resetPasswordAPI;