import AccountNavController from './AccountNavController';
import {Link} from 'react-router-dom';

const AccountNavView = (props: {currentPath: string}) => {
  const {links} = AccountNavController(props.currentPath);

  return (
    <ul className='account-nav'>
      {
        links.map((link) =>
          <Link
            className={`account-nav__link ${link.selected ? 'account-nav__link--selected' : ''}`}
            to={link.path}
          >
            {link.name}
          </Link>
        )
      }
    </ul>
  );
};

export default AccountNavView;