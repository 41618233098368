
type LinkData = {
  name: string;
  path: string;
  selected?: boolean;
};

const AccountNavController = (currentPath: string) => {
  let links: Array<LinkData> = [
    {
      name: 'Invoices',
      path: '/account/invoices'
    },
    {
      name: 'Residents',
      path: '/account/residents'
    },
    {
      name: 'Account',
      path: '/account/edit',
    },
    {
      name: 'Rewards',
      path: '/account/rewards'
    },
    {
      name: 'Change Password',
      path: '/account/password',
    },
    // {
    //   name: 'Shopping Cart',
    //   path: '/checkout/carts'
    // },
    {
      name: 'Facility Forms',
      path: '/account/facilityForms'
    },
    {
      name: 'Free Catalogs',
      path: '/account/freeCatalog'
    }
  ];

  links = links.map((link) => ({
    ...link,
    selected: link.path === currentPath
  }));

  return {links};
};

export default AccountNavController;