import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Request = {
  id: number;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const editLandingCarouselPort = '/modifyLandingPage_shopLinks';

/**Edits the text shown in the notification bar. */
const editLandingCarouselAPI = async (request: Request): Promise<AxiosResponse<Response>> => {
  const res = await api.post(
    editLandingCarouselPort,
    {
      id: request.id,
      image: '',
      link: '-',
      hide: 1,
      button_text: '-',
      headline: '-',
      subhead: '-',
      orderNum: 0
    }
  );
  
  if (isDebugging) {
    console.log(`${editLandingCarouselPort} response:`, res);//TESTING
  }

  return res;
};

export default editLandingCarouselAPI;