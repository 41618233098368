import facilityPromoSummary from '../../media/FacilityPromoSummary.png';
import facilityPromoDetails from '../../media/FacilityPromoDetails.png';
import facilityPromoMain from '../../media/Fac_Promo.png';
import facilityPromoMobile from '../../media/Fac_Promo-Mobile.png';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const FacilityPromo = () => {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  return (
    <section className='fac-promo'>
      {
        window.innerWidth < 1000 ?
        <div className='fac-promo__main fac-promo__main--mobile'>
          <img src={facilityPromoMobile} alt='facility promotion'/>
          <button className='btn btn--primary'>
            Register
          </button>
        </div> :
        <div className='fac-promo__main fac-promo__main--desktop'>
          <img src={facilityPromoMain} alt='facility promotion'/>
          <button className='btn btn--primary' onClick={() => history.push('/register')}>
            Click Here<br/>
            to Register
          </button>
        </div>
      }
    </section>
  );
};

export default FacilityPromo;