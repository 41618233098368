import newPageListener from './newPageListener';
import React, {useEffect} from 'react';
import {Route, useLocation} from 'react-router-dom';

const PublicRoute = (props: any) => {
  const {component: Component, title, ...rest} = props;
  const location = useLocation();

  useEffect(
    () => newPageListener(title, location.pathname, location.search),
    [title]
  );

  return (
    <Route {...rest} >
      {
        cProps => <Component {...cProps} />
      }
    </Route>
  );
};

export default PublicRoute;