import {get} from 'application/api/baseApi';

export type Response = [
  {
    adminID: number;
    username: string;
    superUser: 1 | 0;
  }
];

const path = '/listAdmins';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getAdminsAPI = () => get<Response>(path);

export default getAdminsAPI;