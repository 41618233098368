import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {subscribeToNewsletterAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const subscribeToNewsletter = (set: SetState<State>, get: GetState<State>) =>
	async (email: string, captchaToken: string) => {

		try {
			const res = await subscribeToNewsletterAPI(email, captchaToken);
    	const {status, message} = res.data;

			setAlert(set, get)({
        status: 'success',
        header: status,
        text: message
      });

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log('newsletter error:', err);//TESTING
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to subscribe to newsletter'
			});
		}
	};

export default subscribeToNewsletter;