import {getInvoiceAPI, GetInvoiceRes} from '../../../api';
import {useStore} from '../../../store';
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

export type InvoicePDFParams = {
  userId: string;
  invoiceId: string;
};

export const Invoice = () => {
  const {userId, invoiceId} = useParams<InvoicePDFParams>();
  const [base64, setBase64] = useState<string | undefined>(undefined);

  useEffect(() => {
    getInvoiceAPI(Number(userId),Number(invoiceId))
    // getInvoiceAPI(3828, 42)//TEMPORARY
    .then((res) => {
      const responseData: GetInvoiceRes = res.data;

      if (responseData.status === 'SUCCESS') {
        setBase64(responseData.invoicePDF);
      }
    })
  }, []);

  if (base64) {
    return <embed className='invoice-pdf' src={`data:application/pdf;base64,${base64}`}  type='application/pdf' width='100%'></embed>;
  } else return <div>Loading...</div>;
};

export default Invoice;
