import InvoiceCellRenderer from '../../utility/agGrid/InvoiceCellRenderer';
import CheckboxRenderer from '../../utility/agGrid/CheckboxRenderer';
import LinkToFacilityRenderer from '../../utility/agGrid/LinkToFacilityRenderer';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import {useStore} from '../../../store';
import React, {useState, useEffect} from 'react';

type FacInvoice = {
  id: number;
  invoiceId: number;
  facilityId: string;
  date: string;
  facilityName: string;
  total: string;
  invoiceType: string;
  isProcessed: boolean;
  isPaid: boolean;
};

/**
 * Custom hook to handle facility invoice state and actions
 * @returns invoice list state and an action to edit them
 */
const useFacilityInvoices = () => {
  const invoices = useStore((state) => state.invoices.facility);
  const dispatchGetFacilityInvoices = useStore((state) => state.dispatchGetFacilityInvoices);
  const dispatchEditFacilityInvoice = useStore((state) => state.dispatchEditFacilityInvoice);
  const [curInvoices, setCurInvoices] = useState<Array<FacInvoice>>([]);
  
  useEffect(() => {
    setCurInvoices(invoices.map((inv) => ({
      id: inv.user.id,
      invoiceId: inv.id,
      date: inv.date,
      facilityName: inv.facility.name,
      total: inv.totalCost,
      invoiceType: inv.invoiceType,
      facilityId: inv.facility.id,
      isProcessed: inv.isProcessed,
      isPaid: inv.isPaid
    })))
  }, [invoices]);
  
  useEffect(() => {
    dispatchGetFacilityInvoices();
  }, []);

  return {curInvoices, dispatchEditFacilityInvoice};
};

/**
 * Page that lists facility invoices
 * @returns Page component
*/
const InvoicesFacility = () => {
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 2,
      name: 'Facility Invoices',
      path: '/admin/invoices/facility'
    }
  ]);
  const {curInvoices, dispatchEditFacilityInvoice} = useFacilityInvoices();
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'id',
      headerName: 'User ID',
      filter: 'agTextColumnFilter',
      minWidth: 50,
      maxWidth: 115,
      hide: true
    },
    {
      field: 'date',
      headerName: 'Date',
      filter: 'agDateColumnFilter',
      minWidth: 50,
      maxWidth: 125
    },
    {
      field: 'invoiceId',
      headerName: 'Invoice ID',
      filter: 'agNumberColumnFilter',
      minWidth: 50,
      maxWidth: 115,
      cellRenderer: InvoiceCellRenderer
    },
    {
      field: 'facilityId',
      headerName: 'Facility ID',
      filter: 'agNumberColumnFilter',
      cellRenderer: LinkToFacilityRenderer,
      editable: true,
      minWidth: 50,
      maxWidth: 125
    },
    {
      field: 'facilityName',
      headerName: 'Facility Name',
      filter: 'agTextColumnFilter',
      // cellRenderer: LinkToFacilityRenderer
    },
    {
      field: 'invoiceType',
      headerName: 'Bill Type',
      filter: 'agTextColumnFilter',
      minWidth: 100,
      maxWidth: 225
    },
    {
      field: 'total',
      headerName: 'Total',
      filter: 'agNumberColumnFilter',
      minWidth: 100,
      maxWidth: 150
    },
    {
      field: 'isProcessed',
      headerName: 'Processed',
      cellRenderer: CheckboxRenderer,
      minWidth: 50,
      maxWidth: 140
      // editable: true,
      // cellEditor: ProcessedEditor
    },
    {
      field: 'isPaid',
      headerName: 'Paid',
      cellRenderer: CheckboxRenderer,
      minWidth: 50,
      maxWidth: 140
      // editable: true,
      // cellEditor: ProcessedEditor
    }
  ];

  const onCellValueChanged = (e: any) => {
    const invoice: FacInvoice = e.data;

    dispatchEditFacilityInvoice(
      invoice.id.toString(),
      invoice.invoiceId.toString(),
      invoice.facilityId,
      invoice.isProcessed,
      invoice.isPaid
    );
  };

  return (
    <section className='a-table'>
      <DataGrid
        rowData={curInvoices}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onCellValueChanged={onCellValueChanged}
      />
    </section>
  );
};

export default InvoicesFacility;