import {get} from 'application/api/baseApi';

export type Response = [
  {
    headline: string;
    subhead: string;
  }
];

const path = '/getLandingPage';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getHeroTextAPI = () => get<Response>(path);

export default getHeroTextAPI;