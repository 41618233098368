import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {getBannerTextAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

/**Retrieves the text shown in the notification bar, then updates the store. */
export const getBannerText = (set: SetState<State>, get: GetState<State>) =>
	async () => {

		try {
			const res = await getBannerTextAPI();
    	
      set(() => ({
        bannerText: res.data.map((text) => ({
          id: text.ID,
          text: text.bannerText
        }))
      }));

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log(`getBannerText error:`, err);//TESTING
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to retrieve banner text'
			});
		}
	};

export default getBannerText;