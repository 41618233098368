import FacilityFields from './entities/FacilityFields';
import RegisterAgreement from './RegisterAgreement';
import NewsletterCheckbox from './NewsletterCheckbox';
import {ReactComponent as LoadingIconSmall} from '../../../../media/icon-loading-small.svg';
import {useStore} from '../../../../../store';
import SelectCountry from '../../../../utility/location/SelectCountry';
import SelectRegion from '../../../../utility/location/SelectRegion';
import SelectCounty from '../../../../utility/location/SelectCounty';
import {CountryData, Region} from 'country-region-data';
import React, 
{
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  FormEvent
} from 'react'
import {TextField} from '@mui/material';

type Props = {
  facFields: FacilityFields;
  setFacFields: Dispatch<SetStateAction<FacilityFields>>;
  registerProgress: number;
  setRegisterProgress: Dispatch<SetStateAction<number>>;
  isSubmitting: boolean;
};

const FacilityForm = (props: Props) => {
  const {
    facFields,
    setFacFields,
    registerProgress,
    setRegisterProgress,
    isSubmitting
  } = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const [selectedCountry, setSelectedCountry] = useState<CountryData | undefined>();
	const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();
	const [selectedCounty, setSelectedCounty] = useState<string | undefined>();
  // const [stateCountyList, setStateCountyList] = useState<GetStateCountyListRes | undefined>(undefined);
	const countySensitiveRegions = [
		'AR',
		'FL',
		'IL',
		'KS',
		'OH'
	];
  const [wantsNewsletter, setWantsNewsletter] = useState<boolean>(true);
	const [hasAgreed, setHasAgreed] = useState(false);

	const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		setFacFields({
			...facFields,
			[e.target.name]: e.target.value
		});
	};

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedCountry) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Please select a country'
			});
			return;
		}
		if (!selectedRegion) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Please select a region'
			});
			return;
		}
		if (countySensitiveRegions.includes(selectedRegion[1]) && !selectedCounty) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Invalid',
				text: 'Please select a county'
			});
			return;
		}

    setFacFields({
      ...facFields,
      country: selectedCountry[1],
      state: selectedRegion[1],
      county: selectedCounty,
      wantsNewsletter: wantsNewsletter
    });

    setRegisterProgress(registerProgress + 1);
  };

  return (
    <form className='register__form' onSubmit={onSubmit}>
      <p className='register__fac-heading'>Let's create your facility</p>
      <TextField
        label='Facility Name'
        name='companyName'
        value={facFields.companyName}
        onChange={onChangeInput}
        required
      />
      <TextField
        label='Administrator'
        name='admin'
        value={facFields.admin}
        onChange={onChangeInput}
        required
      />            
      <TextField
        label='Business Office Manager Name'
        name='bom'
        value={facFields.bom}
        onChange={onChangeInput}
        required
      />
      <TextField
        label='Business Office Manager Email'
        name='bomEmail'
        value={facFields.bomEmail}
        onChange={onChangeInput}
        required
      />
      <TextField
        label='Phone Number'
        name='companyPhoneNumber'
        value={facFields.companyPhoneNumber}
        onChange={onChangeInput}
        required
      />
      <TextField
        label='Fax'
        name='fax'
        value={facFields.fax}
        onChange={onChangeInput}
      />
      <TextField
        label='Address 1'
        name='address1'
        value={facFields.address1}
        onChange={onChangeInput}
        required
      />
      <TextField
        label='Address 2'
        name='address2'
        value={facFields.address2}
        onChange={onChangeInput}
      />
      <TextField
        label='City'
        name='city'
        value={facFields.city}
        onChange={onChangeInput}
        required
      />
      <SelectRegion
        selectedCountry={selectedCountry}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
      />
      <SelectCounty
        selectedCountry={selectedCountry}
        selectedRegion={selectedRegion}
        selectedCounty={selectedCounty}
        setSelectedCounty={setSelectedCounty}
      />
      <TextField
        label='Zip Code'
        name='zip'
        value={facFields.zip}
        inputProps={{minLength: 5}}
        onChange={onChangeInput}
        required
      />
      <SelectCountry
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
      />
      <NewsletterCheckbox
        wantsNewsletter={wantsNewsletter}
        setWantsNewsletter={setWantsNewsletter}
      />
      <RegisterAgreement
        hasAgreed={hasAgreed}
        setHasAgreed={setHasAgreed}
      />
      <button
        className='btn btn--primary register__submit'
        type='submit'
        disabled={!hasAgreed || isSubmitting}
      >
        {
          isSubmitting ?
          <LoadingIconSmall /> :
          'Create Your Account'
        }
      </button>
    </form>
  );
};

export default FacilityForm;