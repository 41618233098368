import DeleteSubProductRenderer from '../../../utility/agGrid/DeleteSubProductRenderer';
import DeleteDeptRenderer from '../../../utility/agGrid/DeleteDeptRenderer';
import {useStore, Dept, SubDept, SuperSubDept, Product, SubProduct} from '../../../../store';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect, useCallback, useRef, useMemo, ChangeEvent, FormEvent, SyntheticEvent} from 'react';
import {useParams, useLocation, useHistory} from 'react-router-dom';
import {SelectChangeEvent} from '@mui/material';

/**
 * Custom hook to manage state for the dept matrix.
 * @returns Dept matrix state
 */
const useDeptMatrix = () => {
  const deptMatrix = useStore((state) => state.deptMatrix);
  const dispatchGetDeptMatrix = useStore((state) => state.dispatchGetDeptMatrix);

  const getDeptMatrix = async () => {
    await dispatchGetDeptMatrix();
  };

  useEffect(() => {
    if (!deptMatrix) getDeptMatrix();
  }, [deptMatrix]);

  return {deptMatrix};
};

type Params = {
  productId: string;
};

/**
 * Controller for the ProductDetail Page.
 * @remark Preferrably this would be refactored to look more like the other admin components, but this will be time consuming.
 * @returns All methods used on the ProductDetail Page
 */
const ProductDetailController = () => {
  const location = useLocation();
  const history = useHistory();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 7,
      name: 'Products',
      path: '/admin/invoices/product'
    }
  ]);
  const {deptMatrix} = useDeptMatrix();
  const storeProductInfo = useStore((state) => state.productInfo);
  const dispatchGetProductInfo = useStore((state) => state.dispatchGetProductInfo);
  const dispatchAddProduct = useStore((state) => state.dispatchAddProduct);
  const dispatchDeleteProduct = useStore((state) => state.dispatchDeleteProduct);
  const dispatchEditProduct = useStore((state) => state.dispatchEditProduct);
  const dispatchEditSubProduct = useStore((state) => state.dispatchEditSubProduct);
  const dispatchAddSubProduct = useStore((state) => state.dispatchAddSubProduct);
  const dispatchAddProductDept = useStore((state) => state.dispatchAddProductDept);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const [productId, setProductId] = useState(useParams<Params>().productId);
  const [isNew, setIsNew] = useState(!productId);
  const [isLoading, setIsLoading] = useState(true);
  const defaultImage = `${document.location.origin}/product_images/DEFAULT.png`;
  const defaultThumbnail = ``;
  const [mainImage, setMainImage] = useState(defaultImage);
  const [thumbnails, setThumnails] = useState(new Array<JSX.Element>());
  const [productInfo, setProductInfo] =
  useState<Omit<Product, 'subProducts' | 'depts' | 'desc'>>({
    id: 0,
    groupId: '',
    itemId: '',
    name: '',
    options: '',
    priceRange: '',
    shippingCharge: '',
    clothingType: '',
    needs: '',
    scheduledDeliveryId: '',
    scheduledDeliveryOptions: '',
    sex: 'F',
    imageCount: 0,
    isTaxExempt: false,
    isHidden: false
  });
  const [prodDesc, setProdDesc] = useState('');
  const [subProductInfo, setSubProductInfo] = useState<Omit<SubProduct, 'id' | 'groupId'>>({
    itemId: '',
    name: '',
    size: '',
    price: ''
  });
  const [selectedDept, setSelectedDept] = useState<Dept | undefined>();
  const [selectedSubDept, setSelectedSubDept] = useState<SubDept | undefined>();
  const [selectedSuperSubDept, setSelectedSuperSubDept] = useState<SuperSubDept | undefined>();
  const [rowDataSubProds, setRowDataSubProds] = useState<Array<any>>([]);
  const [rowDataDepts, setRowDataDepts] = useState<Array<Object>>([]);
  const gridRefSubProds: any = useRef();
  const gridRefDepts: any = useRef();
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    minWidth: 100
  }), []);
  const [columnDefsSubProds] = useState([
    {field: 'groupId', hide: true},
    {field: 'itemId', headerName: 'Item ID', filter: 'agNumberColumnFilter'},
    {field: 'name', headerName: 'Name', filter: 'agTextColumnFilter', editable: true},
    {field: 'size', headerName: 'Size', filter: 'agTextColumnFilter', editable: true},
    {field: 'price', headerName: 'Price', filter: 'agTextColumnFilter', editable: true},
    {field: 'delete', headerName: 'Delete', maxWidth: 100, cellRenderer: DeleteSubProductRenderer}
  ]);
  const [columnDefsDepts] = useState([
    {field: 'productId', hide: true},
    {field: 'dept', headerName: 'Dept', filter: 'agTextColumnFilter'},
    {field: 'subDept', headerName: 'Sub Dept', filter: 'agTextColumnFilter'},
    {field: 'superSubDept', headerName: 'Super Sub Dept', filter: 'agTextColumnFilter'},
    {field: 'delete', headerName: 'Delete', maxWidth: 100, cellRenderer: DeleteDeptRenderer},
  ]);
  
  const onChangeProductInfo = (event: ChangeEvent<HTMLInputElement>) =>
  setProductInfo({
    ...productInfo,
    [event.target.name]: event.target.value
  });
  
  const onChangeSubProductInfo = (event: ChangeEvent<HTMLInputElement>) =>
  setSubProductInfo({
    ...subProductInfo,
    [event.target.name]: event.target.value
  });

  const onChangeSex = (e: SelectChangeEvent<'M' | 'F' | 'B'>) => {
    setProductInfo({
      ...productInfo,
      sex: e.target.value === 'F' ? 'F' :
      e.target.value === 'M' ? 'M' :
      'B'
    })
  };

  const toggleIsTaxExempt = () => setProductInfo({
    ...productInfo,
    isTaxExempt: !productInfo.isTaxExempt
  });
  
  const toggleIsHidden = () => setProductInfo({
    ...productInfo,
    isHidden: !productInfo.isHidden
  });

  const onChangeDept = (e: SelectChangeEvent<any>) => {
    setSelectedDept(deptMatrix && deptMatrix.find((dept) => dept.id === e.target.value));
    setSelectedSubDept(undefined);
    setSelectedSuperSubDept(undefined);
  };

  const onChangeSubDept = (e: SelectChangeEvent<number>) => {
    setSelectedSubDept(selectedDept?.subDepts.find((subDept) => subDept.id === e.target.value));
    setSelectedSuperSubDept(undefined);
  };

  const onChangeSuperSubDept = (e: SelectChangeEvent<number>) => {
    setSelectedSuperSubDept(
      selectedSubDept?.superSubDepts.find(
        (superSubDept) => superSubDept.id === e.target.value
      )
    )
  };

  const onFirstDataRendered = useCallback((grid: 'sub' | 'dept') => {
    
    switch (grid) {
      case 'sub':
        gridRefSubProds.current.api.sizeColumnsToFit();
        break;
        
      case 'dept':
        gridRefDepts.current.api.sizeColumnsToFit();
        break;
    
      default:
        break;
      }
  }, []);
  
  const onSubmitProductInfo = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    //some checks

    if (isNew) {
      if (!selectedDept) {
        dispatchSetAlert({
          status: 'warning',
          header: 'No Selected Dept',
          text: 'Please select a department'
        });
        return;
      }
      //ADD PRODUCT
      dispatchAddProduct(
        {
          ...productInfo,
          desc: prodDesc
        },
        {
          deptId: selectedDept.id.toString(),
          subDeptId: selectedSubDept?.id.toString(),
          superSubDeptId: selectedSuperSubDept?.id.toString()
        }
      );
      setProductId(productInfo.itemId);
      setIsNew(false);
      setIsLoading(true);
      
    } else {
      const productToEdit = {
        ...productInfo,
        desc: prodDesc
      };
      
      await dispatchEditProduct(productToEdit);
    }
  };

  const onSubProductValueChanged = useCallback((event) => {
    const modifiedSubProduct: SubProduct = {
      id: event.data.id,
      groupId: event.data.groupId,
      itemId: event.data.itemId,
      name: event.data.name,
      size: event.data.size,
      price: event.data.price
    };

    dispatchEditSubProduct(modifiedSubProduct);
  }, []);

  const onSubmitNewSubProduct = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!storeProductInfo) return;

    dispatchAddSubProduct({
      ...subProductInfo,
      groupId: storeProductInfo.groupId
    });
    setSubProductInfo({
      itemId: '',
      name: '',
      size: '',
      price: ''
    });
  };

  const onSubmitNewDept = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !storeProductInfo ||
      !selectedDept
    ) return;

    const newDept = {
      productId: storeProductInfo.id.toString(),
      deptId: selectedDept.id.toString(),
      subDeptId: selectedSubDept ? selectedSubDept.id.toString() : '0',
      superSubDeptId: selectedSuperSubDept ? selectedSuperSubDept.id.toString() : '0'
    };

    dispatchAddProductDept(newDept);
    setSelectedDept(undefined);
    setSelectedSubDept(undefined);
    setSelectedSuperSubDept(undefined);
  };

  const onDeleteProduct = () => {
    if (!storeProductInfo) return;
    const isConfirmed = window.confirm(`Are you sure you want to delete this product?`);

    if (!isConfirmed) return;
    dispatchDeleteProduct(storeProductInfo.id, storeProductInfo.groupId);

    history.push('/admin/lookup/product');
  };

  const changeMainImage = (altId: number) => {
    if (!storeProductInfo) return;

    const thumbnails = document.querySelectorAll('.a-prod-detail__thumbnail');
    thumbnails.forEach((thumbnail) => 
      thumbnail.classList.remove('a-prod-detail__thumbnail--active')
    );

    const selectedThumbnail = document.querySelector(`#thumbnail-${altId}`);
    selectedThumbnail?.classList.add('a-prod-detail__thumbnail--active');

    setMainImage(`${document.location.origin}/product_images/${storeProductInfo.itemId}-${altId}_L.png`);
  };

	const onBrokenThumbnail = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultThumbnail;
  };

  const onBrokenImage = () => {
		setMainImage(defaultImage);
	};

  const generateThumbnails = (altId: number) => {
    if (!storeProductInfo) return;

    let newThumbnails = [];

		for (let i = 1; i <= storeProductInfo.imageCount; i++) {
			newThumbnails.push(
				<img
					className={`a-prod-detail__thumbnail ${i === altId && 'a-prod-detail__thumbnail--active'}`}
					id={`thumbnail-${i}`}
					src={`${document.location.origin}/product_images/${storeProductInfo.itemId}-${i}_TH.png`}
					alt={`${storeProductInfo.name} thumbnail`}
					onError={onBrokenThumbnail}
					onClick={() => changeMainImage(i)}
				/>
			);
		}

    setThumnails(newThumbnails);
	};

  const resetOptions = () => {
    setSelectedDept(undefined);
    setSelectedSubDept(undefined);
    setSelectedSuperSubDept(undefined);
  };

  const productInfoListener = () => {
    if (!storeProductInfo || isNew) return;

    setIsLoading(true);

    setProductInfo({
      id: storeProductInfo.id,
      groupId: storeProductInfo.groupId,
      itemId: storeProductInfo.itemId,
      name: storeProductInfo.name,
      options: storeProductInfo.options,
      priceRange: storeProductInfo.priceRange,
      shippingCharge: storeProductInfo.shippingCharge,
      clothingType: storeProductInfo.clothingType,
      needs: storeProductInfo.needs,
      scheduledDeliveryId: storeProductInfo.scheduledDeliveryId,
      scheduledDeliveryOptions: storeProductInfo.scheduledDeliveryOptions,
      sex: storeProductInfo.sex,
      imageCount: storeProductInfo.imageCount,
      isTaxExempt: storeProductInfo.isTaxExempt,
      isHidden: storeProductInfo.isHidden
    });
    
    setRowDataSubProds(storeProductInfo.subProducts ? storeProductInfo.subProducts.map((prod) => ({
      id: prod.id,
      groupId: prod.groupId,
      itemId: prod.itemId,
      name: prod.name,
      size: prod.size,
      price: prod.price
    })) : []);

    setRowDataDepts(
      storeProductInfo.depts.list.length > 0 ?
      storeProductInfo.depts.list.map((dept) => ({
        id: dept.id,
        productId: storeProductInfo.id,
        dept: dept.dept,
        subDept: dept.subDept,
        superSubDept: dept.superSubDept,
      })) : []
    );

    changeMainImage(1);
    generateThumbnails(1);
    resetOptions();
    setIsLoading(false);
  };

  const init = async () => {
    setIsLoading(true);
    
    if (isNew) {
      setIsLoading(false);

    } else {
      await dispatchGetProductInfo(productId);
    }
  };

  return {
    storeProductInfo,
    location,
    isLoading,
    productInfo,
    isNew,
    gridRefSubProds,
    gridRefDepts,
    columnDefsSubProds,
    columnDefsDepts,
    defaultColDef,
    rowDataSubProds,
    rowDataDepts,
    subProductInfo,
    deptMatrix,
    selectedDept,
    selectedSubDept,
    mainImage,
    thumbnails,
    init,
    productInfoListener,
    onSubProductValueChanged,
    onFirstDataRendered,
    onSubmitProductInfo,
    onSubmitNewSubProduct,
    onSubmitNewDept,
    onChangeProductInfo,
    onChangeSubProductInfo,
    onChangeSex,
    onChangeDept,
    onChangeSubDept,
    onChangeSuperSubDept,
    toggleIsTaxExempt,
    toggleIsHidden,
    onDeleteProduct,
    onBrokenImage,

    setProdDesc//TEMP
  };
};

export default ProductDetailController;