import {State} from 'application/store';
import StoreError from 'application/store/utility/errors/StoreError';
import getProductInfo from 'application/store/admin/actions/getProductInfo';
import setAlert from 'application/store/alerts/actions/setAlert';
import {addProductDeptAPI} from 'application/api';
import {isDebugging} from 'application/utility';
import {SetState, GetState} from 'zustand';

export type NewDept = {
  productId: string;
  deptId: string;
  subDeptId: string;
  superSubDeptId: string;
};

export const addProductDept = (set: SetState<State>, get: GetState<State>) =>
	async (newDept: NewDept) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

		const req = {
			prod_ID: newDept.productId,
			dept_ID: newDept.deptId,
			subDept_ID: newDept.subDeptId,
			superSubDept_ID: newDept.superSubDeptId
		};

		const res = await addProductDeptAPI(req);

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully added department'
		});

		getProductInfo(set, get)(productInStore.itemId);
	};

export default addProductDept;