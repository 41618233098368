import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const forgotUsernamePort = '/recoverAccount';

const forgotUsernameAPI =
async (email: string, captchaToken: string): Promise<AxiosResponse<Response>> => {
  // const ipRes = await axios.get('https://geolocation-db.com/json/');
  const res = await api.get(forgotUsernamePort, {
    params: {
      rtype: 'username',
      email: email
    },
    headers: {
      // remoteip: ipRes.status === 200 ? ipRes.data.IPv4 : '',
      remoteip: '',
      captchaToken: captchaToken
    }
  });

  if (isDebugging) {
    console.log(`${forgotUsernamePort} response:`, res);//TESTING
  }

  return res;
};

export default forgotUsernameAPI;
