import BreadcrumbsController from './BreadcrumbsController';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

const BreadcrumbsView = () => {
  const {
    breadcrumbs,
    init
  } = BreadcrumbsController();

  useEffect(init, []);

  return <>
    {
      breadcrumbs &&
      breadcrumbs.length > 0 &&
      <ul className='breadcrumbs'>
        {
          breadcrumbs.map((breadcrumb, index) =>
            <li key={index}>
              <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
            </li>
          )
        }
      </ul>
    }
  </>;
};

export default BreadcrumbsView;
