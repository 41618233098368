import ImageSelection, {
	Img,
	Thumbnail,
} from './ImageSelection';
import ResidentSelection from '../../auth/ResidentSelection';
import ProductPreview from '../productPreview/ProductPreview';
import Breadcrumbs from '../../navigation/breadcrumbs/BreadcrumbsView';
import DeptNav from '../../navigation/depts/DeptNavView';
import {ReactComponent as SwatchIcon} from '../../../media/icon-swatch.svg';
import {ReactComponent as LoadingIcon} from '../../../media/icon-loading.svg';
import {ReactComponent as LoadingSmallIcon} from '../../../media/icon-loading-small.svg';
import {getProductDetailAPI, SubProduct} from '../../../../api';
import {useStore, OrderToCreate} from '../../../../store';
import React, {
	useState,
	useEffect,
	ChangeEvent
} from 'react';
import {Link, useParams, useLocation} from 'react-router-dom';
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	SelectChangeEvent
} from '@mui/material';
import reactGA from '../../../utility/google/reactGA';
import parse from 'html-react-parser';

export type ProductDetailParams = {
	itemId: string;
};

const ProductDetail = () => {
	const location = useLocation();
	const itemId = new URLSearchParams(location.search).get('itemId');
	const account = useStore((state) => state.account);
	const selectedResident = useStore((state) => state.selectedResident);
	const dispatchAddToCart = useStore((state) => state.dispatchAddToCart);
	const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const dispatchGetResidentList = useStore((state) => state.dispatchGetResidentList);
	const [isLoading, setIsLoading] = useState(true);
	const [isAddingToCart, setIsAddingToCart] = useState(false);
	const defaultProductData = {
		id: '',
		groupId: '',
		name: '',
		image: '',
		imageCount: 1,
		sizes: new Array<string>(),
		colors: new Array<string>(),
		desc: '',
		price: '',
		shipping: 0,
		subProducts: new Array<SubProduct>(),
		scheduledDeliveryId: '',
		scheduledDeliveryOptions: new Array<string>()
	};
	const [productData, setProductData] = useState(defaultProductData);
	const [quantity, setQuantity] = useState(1);
	const [selectedColor, setSelectedColor] = useState('');
	const [selectedSize, setSelectedSize] = useState('');
	const [selectedSubProduct, setSelectedSubProduct] = useState<SubProduct | undefined>();
	const [selectedDelivery, setSelectedDelivery] = useState('');
  const [carouselItems, setCarouselItems] = useState(new Array<{
    prod_itemID: string;
    prod_name: string;
    prod_price: string;
	}>());
	const [images, setImages] = useState<Array<Img>>([]);
	const defaultImg: Img = {
		id: `${itemId}-1`,
		alt: 'default',
		src: `${document.location.origin}/product_images/DEFAULT.png`
	};
	const [thumbnails, setThumbnails] = useState<Array<Thumbnail>>([]);
	const defaultThumbnail: Thumbnail = {
		id: `T`,
		imageId: `${itemId}-1`,
		alt: 'default Thumbnail',
		src: `${document.location.origin}/product_images/DEFAULT_T.png`
	};

	const generateImages = () => {
		let newImages: Array<Img> = [];
		let newThumbnails: Array<Thumbnail> = [];

		for (let i = 0; i < productData.imageCount; i++) {
			const imgNum = i + 1;

			console.log('product:', productData);//TESTING
			
			newImages.push({
				id: `${imgNum}`,
				src: `${document.location.origin}/product_images/${productData.id}-${imgNum}_L.png`,
				alt: productData.name
			});
			
			newThumbnails.push({
				id: `${productData.imageCount + i}`,
				imageId: `${imgNum}`,
				src: `${document.location.origin}/product_images/${productData.id}-${imgNum}_TH.png`,
				alt: `${productData.name} thumbnail`
			});
    }
		
		console.log('newImages:', newImages);//TESTING
		console.log('newThumbnails:', newThumbnails);//TESTING

		setImages(newImages);
		setThumbnails(newThumbnails);
	};

	const resetOptions = () => {
		setQuantity(1);
		setSelectedColor('');
		setSelectedSize('');
		setSelectedSubProduct(undefined);
		setSelectedDelivery('');
	};

	const onChangeQuantity = (amount: number) => {
		const isScheduledDeliveryProduct = productData.scheduledDeliveryOptions.length > 0;
		const min = isScheduledDeliveryProduct ? 2 : 1;
		const max = 99;
    amount = Math.max(min, Math.min(max, amount));

		setQuantity(amount);
	};
	
	const onChangeSubProduct = (e: SelectChangeEvent<string>) => {
		const productId = e.target.value;
		const subProduct = productData.subProducts.find((product) => product.prod_itemID === productId);
		setSelectedSubProduct(subProduct);

		if (subProduct?.prod_price) {
			setProductData({
				...productData,
				price: subProduct?.prod_price
			});
		}
	};

	const onChangeDelivery = (e: SelectChangeEvent<string>) => {
		setSelectedDelivery(e.target.value);
	};

	const addToCart = async () => {
		if (account && account.roleId === 3 && !selectedResident) {
			dispatchSetAlert({
				status: 'warning',
				header: 'No Resident Selected',
				text: 'Please select a resident'
			});
			return;
		}
		
		if (quantity < 1 || quantity > 99999) {
			dispatchSetAlert({
				status: 'warning',
				header: 'Out of Range',
				text: 'Please enter a valid quantity (1 - 99,999)'
			});
			return;
		}

		const orderToCreate: OrderToCreate =
		account && account.roleId === 3 && selectedResident ?
		{
			residentId: selectedResident.id,
			product: {
				id: selectedSubProduct?.prod_itemID || productData.id,
				name: productData.name,
				size: selectedSubProduct?.prod_size || '',
				option: selectedColor,
				price: Number(selectedSubProduct?.prod_price) || Number(productData.price),
				quantity: quantity,
				shipping: productData.shipping || 0,
				scheduledDelivery: selectedDelivery
			}
		} : {
			residentId: 0,
			product: {
				id: selectedSubProduct?.prod_itemID || productData.id,
				name: productData.name,
				size: selectedSubProduct?.prod_size || '',
				option: selectedColor,
				price: Number(selectedSubProduct?.prod_price) || Number(productData.price),
				quantity: quantity,
				shipping: productData.shipping || 0,
				scheduledDelivery: selectedDelivery
			}
		}

		setIsAddingToCart(true);

		await dispatchAddToCart(orderToCreate);

		setIsAddingToCart(false);
	};

	const getProductDetails = async () => {
		setIsLoading(true);

		try {
			if (!itemId) throw new Error();
			const res = await getProductDetailAPI(itemId);

			if (res === null) throw new Error();

			const {
				prod_itemID,
				prod_name,
				prod_size,
				prod_color,
				prod_desc,
				prod_price,
				relatedProds,
				subProds,
				prod_shipping,
				prod_groupID,
				imageCount,
				sched_delivery_ID,
				sched_delivery_options
			} = res[0];

			resetOptions();

			let colorArray: Array<string> = [];
			let sizeArray: Array<string> = [];
			let deliveryOptionsArray: Array<string> = [];
			const fixedName = prod_name.replaceAll(`\"`, `"`);
			const fixedDesc = prod_desc.replaceAll(`\"`, `"`);
			const shippingNum = Number(prod_shipping);
			const defaultImg = `${document.location.origin}/product_images/${prod_itemID}-1_L.png`;
			
			if (prod_color) {
				colorArray = JSON.stringify(prod_color)
				//	.replace(/\s+/g, '')
				.replace(`\"`, `"`)
				.slice(1, -1)
				.split(',') || '';
			}
			
			if (prod_size) {
				sizeArray = JSON.stringify(prod_size)
				.replace(`\"`, `"`)
				.slice(1, -1)
				.split(',') || '';
			}
			
			if (sched_delivery_options) {
				deliveryOptionsArray = JSON.stringify(sched_delivery_options)
				.replace(`\"`, `"`)
				.slice(1, -1)
				.split(',') || '';

				// When item has scheduled delivery options, default (and min) quantity is 2
				setQuantity(2)
			}

			setProductData({
				id: prod_itemID,
				groupId: prod_groupID,
				name: fixedName,
				image: defaultImg,
				imageCount: imageCount || 1,
				sizes: sizeArray,
				colors: colorArray,
				desc: fixedDesc,
				price: prod_price,
				shipping: shippingNum,
				subProducts: subProds || [],
				scheduledDeliveryId: sched_delivery_ID || '',
				scheduledDeliveryOptions: deliveryOptionsArray
			});
			
			setCarouselItems(relatedProds);

		} catch (err) {
			dispatchSetAlert({
				status: 'danger',
				header: 'Error',
				text: 'There was an error while retrieving the product, please try again later'
			});
		}

		setIsLoading(false);
	};

	useEffect(() => {getProductDetails()}, [itemId]);
	useEffect(generateImages, [productData.id]);
	useEffect(dispatchGetResidentList, []);

	if (isLoading) {
		return (
			<section className='product-detail'>
				<LoadingIcon />
			</section>
		);
	}
	
	return (
		<section className='product-detail'>
			<Breadcrumbs />
			<aside className='product-detail__left-gutter'>
				{
					account?.roleId === 3 &&
					account.residents &&
					account.residents.length > 0 &&
					<ResidentSelection />
				}
				<DeptNav />
			</aside>
			<div className='product-detail__main'>
				<ImageSelection
					images={images}
					defaultImg={defaultImg}
					thumbnails={thumbnails}
					defaultThumbnail={defaultThumbnail}
				/>
				<div className='product-detail__info'>
					<h1 className='product-detail__name'>{productData.name}</h1>
					<span className='product-detail__group-id'>
						<h3>Group Product ID:</h3>
						<p>{productData.groupId}</p>
					</span>
					<div className='product-detail__dropdowns'>
						{productData.sizes.length > 1 &&
							<span className='product-detail__sizes'>
								<FormControl>
									<InputLabel id='size-label'>Sizes:</InputLabel>
									<Select
										labelId='size-label'
										onChange={(e) => setSelectedSize(e.target.value)}
										defaultValue={''}
									>
										<MenuItem value='' disabled selected>---Please Select---</MenuItem>
										{
											productData.sizes.map((size) =>
												<MenuItem
													className='product-detail__size'
													value={size}
												>
													{size}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
							</span>
						}
						{productData.colors.length > 1 &&
							<span className='product-detail__colors'>
								<FormControl>
									<InputLabel id='color-label'>Options:</InputLabel>
									<Select
										labelId='color-label'
										label='Options:'
										onChange={(e) => setSelectedColor(e.target.value)}
										value={selectedColor}
									>
										<MenuItem value='' disabled>---Please Select---</MenuItem>
										{
											productData.colors.map((color) =>
												<MenuItem
													className='product-detail__color'
													value={color}
												>
													{color}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
							</span>
						}
						{productData.subProducts.length > 0 &&
							<span className='product-detail__subproducts'>
								<FormControl>
									<InputLabel id='subproduct-label'>Size or Specifics:</InputLabel>
									<Select
										labelId='subproduct-label'
										label='Size or Specifics:'
										onChange={onChangeSubProduct}
										value={selectedSubProduct?.prod_itemID || ''}
									>
										<MenuItem value='' disabled selected>---Please Select---</MenuItem>
										{
											productData.subProducts.map((subProduct) =>
												<MenuItem
													value={subProduct.prod_itemID}
													selected={subProduct.prod_itemID === selectedSubProduct?.prod_itemID}
												>
													{`${subProduct.prod_size} - $${subProduct.prod_price}`}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
								<Link to='/info/sizeGuide' target='_blank' rel='noopener noreferrer'>
									<SwatchIcon /> Size Guide
								</Link>
							</span>
						}
						{productData.scheduledDeliveryOptions.length > 0 &&
							<span className='product-detail__del-options'>
								<FormControl>
									<InputLabel id='size-label'>Delivery:</InputLabel>
									<Select
										labelId='size-label'
										label='Delivery:'
										onChange={onChangeDelivery}
										value={selectedDelivery}
									>
										<MenuItem value='' disabled selected>---Please Select---</MenuItem>
										{productData.scheduledDeliveryOptions.map((option) =>
											<MenuItem
												className='product-detail__size'
												value={option}
											>
												{option}
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</span>
						}
					</div>
					<span className='product-detail__price'>
						<h3>Price:</h3>
						<p>{`$${productData.price}`}</p>
					</span>
					<div className='product-detail__add-to-cart'>
						<span>
							<span className='product-detail__qty'>
								<button onClick={() => onChangeQuantity(quantity - 1)}>
									-
								</button>
								<TextField
									name='product-quantity'
									type='number'
									label='Qty'
									value={quantity}
									onChange={(e) => onChangeQuantity(Number(e.target.value))}
									inputProps={{min: '1', max: '99'}}
									required
								/>
								<button onClick={() => onChangeQuantity(quantity + 1)}>
									+
								</button>
							</span>
							<button
								className='btn product-detail__submit-button'
								onClick={addToCart}
								disabled={
									isAddingToCart ||
									(productData.sizes.length > 1 && !selectedSize) ||
									(productData.colors.length > 1 && !selectedColor) ||
									(productData.subProducts.length > 0 && !selectedSubProduct)
								}
							>
								{
									isAddingToCart ?
									<LoadingSmallIcon /> :
									'Add to Cart'
								}
							</button>
						</span>
					</div>
				</div>
				{
					productData.scheduledDeliveryId &&
					<span className='product-detail__sched-del-link'>
						<p>Interested in scheduled delivery of this product?</p>
						<Link to={`/productDetail/?itemId=${productData.scheduledDeliveryId}`}>
							Click here
						</Link>
					</span>
				}
				<span className='product-detail__desc'>
					<h3>Description:</h3>
					<span>{
						productData.desc.startsWith('<') && productData.desc.endsWith('>') ?
						parse(productData.desc.replaceAll('\n', '<br />')) :
						productData.desc
					}</span>
				</span>
			</div>
      <aside className='product-detail__right-gutter hide--desktop'>
        <p>Customers Also Purchased:</p>
        {
					carouselItems.map((item) =>
						<ProductPreview product={item} />
					)
				}
      </aside>
		</section>
	);
};

export default ProductDetail;
