import getAdmins from 'application/store/admin/actions/getAdmins';
import AdminToEdit from '../entities/AdminToEdit';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {deleteAdminAPI} from '../../../api';
import {SetState, GetState} from 'zustand';

export const deleteAdmin = (set: SetState<State>, get: GetState<State>) =>
	async (admin: Pick<AdminToEdit, 'id'>) => {

		const res = await deleteAdminAPI({
			targetAdminID: admin.id
		});
		
		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully deleted admin'
		});

		getAdmins(set, get)();
	};

export default deleteAdmin;