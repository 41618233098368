import {useState, ChangeEvent, FormEvent} from 'react';
import {useHistory} from 'react-router-dom';

const SearchbarController = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  // const [searching, setSearching] = useState(false);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSearchValue('');
    history.push(`/productList/search/${searchValue}`);
  };

  return {
    searchValue,
    onChangeInput,
    onSubmit
  };
};

export default SearchbarController;