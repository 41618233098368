const companyInfo = {
  phone: {
    main: '1-800-334-2897',
    fax: '1-765-378-7758',
    direct: '1-765-378-5207'
  },
  email: {
    customerService: 'service@seniorshoppingservice.com',
    accounting: 'accounting@seniorshoppingservice.com'
  },
  shipping: {
    name: 'Senior Shopping Service',
    address: '319 Anderson Road',
    city: 'Chesterfield',
    state: 'IN',
    zip: '46017'
  },
  hours: {
    timeZone: 'EST',
    weekdays: {
      start: '9am',
      end: '5pm'
    }
  },
  social: {
    facebook: 'https://www.facebook.com/SeniorShoppingService',
    twitter: 'https://twitter.com/srshoppingsvc15',
    youtube: 'http://www.youtube.com/seniorshoppingservice',
    pintrest: 'https://www.pinterest.com/seniorshopping',
    instagram: 'https://www.seniorshoppingservice.com/'
  }
};

export default companyInfo;