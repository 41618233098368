import {resendSubAccountInviteAPI} from '../../../api';
import {useStore} from '../../../store';
import React from 'react';

type Props = {
  value: number;
  data: {
    id: number;
    email: string;
  }
};

const InvoiceCellRenderer = (props: Props) => {
  const {data} = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);

  const handleResendInvite = () => {
    resendSubAccountInviteAPI(data.id, data.email).then((res) => {
      if (res.status === 200) {
        dispatchSetAlert({
          status: 'success',
          header: 'Success',
          text: 'Invite sent'
        });
      } else {
        dispatchSetAlert({
          status: 'danger',
          header: 'Failure',
          text: 'Invite failed to send'
        });
      }
    })
  };

  return (
    <button className='' onClick={handleResendInvite}>Resend Invite</button>
  );
};

export default InvoiceCellRenderer;