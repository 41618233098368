import EditedOrder from '../entities/EditedOrder';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const editCartPort = '/modifyCart';

const editCartAPI = async (editedOrder: EditedOrder) => {
  const res = await api.post(editCartPort, editedOrder);

  if (isDebugging) {
    console.log(`${editCartPort} response:`, res);//TESTING
  }

  return res;
};

export default editCartAPI;