import StyleButton from './StyleButton';
import {inlineStyles} from './draftConfig';
import ControlsComponent from './entities/ControlsComponent';
import React from 'react';
import {RichUtils} from 'draft-js';

const InlineControls: ControlsComponent = (props) => {
  const {editorState, setEditorState} = props;
  const currentStyle = editorState.getCurrentInlineStyle();
  
  const toggleInline = (toggledInline: string) => setEditorState(
    RichUtils.toggleInlineStyle(
      editorState,
      toggledInline
    )
  );

  return (
    <div className='RichEditor-controls'>
      {inlineStyles.map((option) =>
        <StyleButton
          key={option.label}
          active={currentStyle.has(option.style)}
          label={option.label}
          onToggle={toggleInline}
          style={option.style}
          type='inline'
        />
      )}
    </div>
  );
};

export default InlineControls;