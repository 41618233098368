import {State} from '../..';
import {SetState, GetState} from 'zustand';

const setAlert = (set: SetState<State>, get: GetState<State>) =>
  (alertId: number) => {
    const alerts = get().alerts;

    const modifiedAlerts = alerts.filter((alert) => alert.id !== alertId);

    set({
      alerts: modifiedAlerts
    });
  };

export default setAlert;