import React from 'react';
import {Link} from 'react-router-dom';

type Props = {
  value: number;
  data: {
    productId: string;
  }
};

const ResidentCellRenderer = (props: Props) => <Link to={`/admin/lookup/product/${props.data.productId}`}>{props.value}</Link>;

export default ResidentCellRenderer;