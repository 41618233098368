import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridOptions} from 'ag-grid-community';
import React, {useState, useRef, useMemo, useCallback} from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

type DataGridProps = {
  rowData: Array<Object>;
  colDefs: Array<ColDef>;
  defaultColDef?: ColDef;
  onCellValueChanged?: (e: any) => void;
};

/**
 * Component used to display a grid of provided data.
 * @param rowData
 * @param colDefs list of definitions for each column
 * @param defaultColDef
 * @param onCellValueChanged Optional function that runs each time a cell value has changed
 * @returns Data grid component
 */
const DataGrid = (props: Omit<GridOptions, 'gridRef'>) => {
  const gridRef: any = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  
  const defaultOnFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const {
    pagination = true,
    paginationAutoPageSize = true,
    onFirstDataRendered = defaultOnFirstDataRendered
  } = props;

  return (
    <div className='ag-theme-alpine' style={gridStyle}>
      <AgGridReact
        ref={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        paginationAutoPageSize={paginationAutoPageSize}
        pagination={pagination}
        {...props}
      />
    </div>
  );
};

export default DataGrid;