import countySensitiveRegions from './countySensitiveRegions';
import {getStateCountyListAPI, GetStateCountyListRes} from '../../../api';
import {useStore} from '../../../store';
import React, {useState, useEffect, Dispatch, SetStateAction} from 'react';
import {allCountries, CountryData, Region} from 'country-region-data';
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';

interface Props extends SelectProps {
  selectedCountry: CountryData | undefined;
  selectedRegion: Region | undefined;
  selectedCounty: string | undefined;
  setSelectedCounty: Dispatch<SetStateAction<string | undefined>>;
};

const SelectCounty = (props: Props) => {
  const {
    selectedCountry,
    selectedRegion,
    selectedCounty,
    setSelectedCounty,
    required = true,
    ...rest
  } = props;
  const [stateCountyList, setStateCountyList] = useState<GetStateCountyListRes | undefined>();
  const [curCounties, setCurCounties] = useState<Array<string> | undefined>();

    const getStateCountyList = async () => {
    const res = await getStateCountyListAPI();

    setStateCountyList(res.data);
  };

  const onChangeSelectedCounty = (e: SelectChangeEvent<unknown>) => {
    const newCounty = e.target.value;
    if (typeof newCounty !== 'string' && typeof newCounty !== 'undefined') return;
    setSelectedCounty(newCounty);
  };

  const shouldHide = () => 
  !selectedCountry ||
  selectedCountry[1] !== 'US' ||
  !selectedRegion ||
  !stateCountyList ||
  !countySensitiveRegions.includes(selectedRegion[1]);

  const updateCountyValues = (setDefaultCounty: boolean) => {
    if (!selectedRegion || !stateCountyList) return;

    const newRegion = stateCountyList.find(
      (state) => state.state === selectedRegion[1]
    );
      
    if (newRegion) {
      const newCounties = newRegion.county.split(',');
        
      setCurCounties(newCounties);
      
      if (setDefaultCounty) {
        setSelectedCounty(newCounties[0]);
        
      } else {
        setSelectedCounty(newCounties.find(c => c === selectedCounty))
      }
    }
  }

  useEffect(() => {getStateCountyList()}, []);
  useEffect(() => updateCountyValues(false), [stateCountyList])
  useEffect(() => updateCountyValues(false), [selectedRegion]);

  if (shouldHide()) return <></>;

  return (
    <FormControl>
      <InputLabel id='county-label'>
        County
      </InputLabel>
      <Select
        labelId='county-label'
        label='County'
        onChange={onChangeSelectedCounty}
        value={selectedCounty || ''}
        required={required}
        {...rest}
      >
        <MenuItem value='' disabled selected>
          Select county
        </MenuItem>
        {
          curCounties &&
          curCounties.length > 0 &&
          curCounties.map((county, index) =>
            <MenuItem value={county} key={index}>
              {county}
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
};

export default SelectCounty;