import React, {Dispatch, SetStateAction} from 'react';

type Props = {
  hasAgreed: boolean;
  setHasAgreed: Dispatch<SetStateAction<boolean>>;
};

const RegisterAgreement = (props: Props) => {
  const {hasAgreed, setHasAgreed} = props;

  const onChangeCheckbox = () => setHasAgreed(!hasAgreed);

  return (
    <div className='register__agreement'>
      <span className='switch'>
        <input
          id='agreemencheckbox'
          className='switch__checkbox'
          type='checkbox'
          checked={hasAgreed}
          onChange={onChangeCheckbox}
          required
          />
        <label htmlFor='agreemencheckbox' className='switch__label' />
      </span>
      I understand that terms are net amount due ten days from when we receive the order.
      The facility is responsible for all orders originated by the authorized facility 
      staff members indicated on this screen.
    </div>
  );
};

export default RegisterAgreement;