import {get} from 'application/api/baseApi';

type Response = [
  {
    prod_id: number,
    prod_groupID: string;
    prod_itemID: string;
    prod_name: string;
    prod_size: string;
    prod_desc: string;
    prod_price: string;
    prod_shipping: string;
    prod_dept: string;
    prod_subDept: string;
    prod_superSubDept: string;
    prod_clothingType: string;
    prod_nurseTip: string;
    prod_color: string;
    prod_picLG: string;
    prod_picAlt: string;
    prod_picMain: string;
    prod_picSugg: string;
    prod_picThumb: string;
    prod_picThumbA: string;
    sched_delivery_ID?: string;
    sched_delivery_options?: string;
    taxExempt: 0 | 1,
    MFB: 'M' | 'F' | 'B',
    hide: 0 | 1,
    imageCount?: number;
    subProds: [
      {
        prod_id: number;
        prod_groupID: string;
        prod_itemID: string;
        prod_name: string;
        prod_size: string;
        prod_desc: string;
        prod_price: string;
      }
    ],
    relatedProds: [
      {
        prod_itemID: string;
        prod_name: string;
        prod_price: string;
      }
    ]   
  }
];

const path = '/getProduct';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.\
 * @param productId Product id string
 * @returns Expected response or null
 */
const getProductDetailAPI = (productId: string) => get<Response>(
  path,
  {
    params: {
      prod_itemID: productId
    }
  }
);

export default getProductDetailAPI;