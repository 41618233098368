import {get} from 'application/api/baseApi';

export type Response = [
  {
    dateTime: string;
    admin: string;
    type: string;
    facility_name: string;
    amount: string;
    note: string;
  }
];

const path = '/getRewardsLogs';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @returns Expected response or null
 */
const getRewardsLogAPI = () => get<Response>(path);

export default getRewardsLogAPI;