import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE',
  username: string;
  roleID: '1' | '2' | '3';
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phoneNumber: string;
  facName: string;
  facPhone: string;
  facAdmin: string;
  facOfficeMgr: string;
  facOfficeMgrEmail: string;
  facFax: string;
  facAddress1: string;
  facAddress2: string;
  facCity: string;
  facState: string;
  facZip: string;
  facCounty: string;
  facCountry: string;
  facPO: string;
  facRewards: string;
  facRewardsExpiration: string;
  newsletter?: string;
  taxrate: string;
  userToken: string;
};

const getAccountDetailsPort = '/getAccountInfo';

const getAccountDetailsAPI = async (userId: string): Promise<AxiosResponse<Response>> => {
  const res = await api.get(
    getAccountDetailsPort,
    {
      params: {
        userID: userId
      }
    }
  );

  if (isDebugging) {
    console.log(`${getAccountDetailsPort} response:`, res);//TESTING
  }

  return res;
};

export default getAccountDetailsAPI;