import AccountNav from '../navigation/account/AccountNavView';
import {ReactComponent as LoadingIcon} from '../../media/icon-loading.svg';
import {ReactComponent as LoadingIconSmall} from '../../media/icon-loading-small.svg';
import {authService} from '../../../utility';
import {useStore} from '../../../store';
import {EditedAccount} from '../../../api';
import React, {useState, useEffect, ChangeEvent, FormEvent, useRef} from 'react';
import {Redirect, useLocation, useHistory} from 'react-router-dom';
import {TextField} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

const ChangePassword = () => {
  const dispatchGetAccountDetails = useStore((state) => state.dispatchGetAccountDetails);
  const dispatchEditAccount = useStore((state) => state.dispatchEditAccount);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const dispatchResetSubPassword = useStore((state) => state.dispatchResetSubPassword);
  const location = useLocation();
  const history = useHistory();
  const account = useStore((state) => state.account);
  const [formData, setFormData] = useState({
    oldPlainPassword: '',
    newPlainPassword: '',
    newPlainPasswordConfirm: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const initialize = async () => {
    await dispatchGetAccountDetails();
    setIsLoading(false);
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
			[event.target.name]: event.target.value
		});
	};
  
  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (
      !account ||
      !account.username ||
      !account.email ||
      !account.lastName ||
      !account.phoneNumber ||
      !account.shipping
    ) {
      dispatchSetAlert({
        status: 'danger',
        header: 'Server Error',
        text: 'There was a server issue, please try again later'
      });
    } else {
      const editedAccount: EditedAccount = {
        userID: account.userId,
        roleID: account.roleId,
        username: account.username,
        email: account.email,
        firstName: account.firstName,
        lastName: account.lastName,
        title: account.title || '',
        password: {
            hashedOldPassword: authService.password.hash(formData.oldPlainPassword),
            hashedNewPassword: authService.password.hash(formData.newPlainPassword),
            hashedNewPasswordConfirm: authService.password.hash(formData.newPlainPasswordConfirm)
          },
        phoneNumber: account.phoneNumber,
        company: account.roleId === 3 ?
          account.company :
          undefined,
        shipping: account.shipping,
        newsletter: account.newsletter ? '1' : undefined
      };

      try {
        await dispatchEditAccount(editedAccount);
        setFormData({
          oldPlainPassword: '',
          newPlainPassword: '',
          newPlainPasswordConfirm: ''
        });
        setIsSubmitting(false);
        history.goBack();
      } catch {
        return;
      }
    }
  };

  const resetSubPassword = (subId: number, email: string) => {
    dispatchResetSubPassword(subId, email);
  };

  useEffect(() => {
    initialize();
  }, [])

  if (!account) return <Redirect to='/' />;

  return isLoading ?
    <section className='change-password'>
      <LoadingIcon />
    </section> :
    <section className='change-password'>
      <h1 className='change-password__heading'>Change Primary Account Password</h1>
      <form className='form' onSubmit={onSubmit}>
        <TextField
          type='password'
          label='Old Password'
          name='oldPlainPassword'
          value={formData.oldPlainPassword}
          onChange={onChangeInput}
          required
        />
        <TextField
          type='password'
          label='New Password'
          name='newPlainPassword'
          value={formData.newPlainPassword}
          onChange={onChangeInput}
          inputProps={{minLength: 8}}
          required
        />
        <TextField
          type='password'
          label='New Password Confirm'
          name='newPlainPasswordConfirm'
          value={formData.newPlainPasswordConfirm}
          onChange={onChangeInput}
          inputProps={{minLength: 8}}
          required
        />
        <button
          className='btn btn--primary change-password__submit'
          type='submit'
          disabled={isSubmitting}
        >
          {
            isSubmitting ?
            <LoadingIconSmall /> :
            'Submit'
          }
        </button>
        <ReCAPTCHA
          className='recaptcha'
          ref={recaptchaRef}
          size='invisible'
          sitekey={authService.recaptcha.siteKey}
        />
      </form>
      {
        account &&
        account.subAccounts &&
        account.subAccounts.length > 0 &&
        <div className='change-password__sub-accounts'>
          <h3>Change Password for Authorized Users</h3>
          {
            account.subAccounts.map((sub) =>
              <span>
                <p>{`${sub.firstName} ${sub.lastName} (${sub.email})`}</p>
                <button onClick={() => resetSubPassword(sub.id, sub.email)}>
                  Email Password Reset
                </button>
              </span>
            )
          }
        </div>
      }
    </section>;
};

export default ChangePassword;
