import SearchType from '../entities/SearchType';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const getProductListPort = '/getProductList';

const getProductListAPI = async (searchType: SearchType, value: string | number) => {
  const res = await api.get(getProductListPort, {
    params: {
      type: searchType,
      value: value
    }
  });

  if (isDebugging) {
    console.log(`${getProductListPort} response:`, res);//TESTING
  }

  return res;
};

export default getProductListAPI;