import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Response = [
  {
    residentID: number;
    userID: number;
    fName: string;
    lName: string;
    clothing_size: string;
    shoe_size: string;
    budget_amount: string;
    sex: 'M' | 'F';
  }
];

const getResidentListPort = '/getResidentList';

const getResidentListAPI = async (userId: number): Promise<AxiosResponse<Response>> => {
  const res = await api.get(getResidentListPort, {params: {userID: userId}});

  if (isDebugging) console.log(`${getResidentListPort} response:`, res);//TESTING

  return res;
};

export default getResidentListAPI;