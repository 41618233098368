import {useStore} from '../../../../store';
import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';

const DeptNavController = () => {
  const navigation = useStore((state) => state.navigation);
  const breadcrumbs = useStore((state) => state.breadcrumbs);

  return {
    navigation,
    breadcrumbs
  };
};

export default DeptNavController;