import {get} from 'application/api/baseApi';

export type Response = [
  {
    ID: number;
    prod_ID: number;
    prod_name: string;
    prod_price: string;
    prod_itemID: string;
    dept_ID: number;
    deptName: string;
    subDept_ID: number;
    subDeptName: string;
    superSubDept_ID: number;
    superSubDeptName: string;
  }
];

const path = '/getProdDeptMatrix';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param search Optional search string
 * @param productId Optional product id number
 * @returns Expected response or null
 */
const lookupProductAPI = (search?: string, productId?: number) => get<Response>(
  path,
  {
    params: {
      prod_ID: productId,
      searchValue: search
    }
  }
);

export default lookupProductAPI;