import {useStore} from '../../../store';
import React from 'react';

type Props = {
  value: number;
  data: {
    id: number;
    email: string;
  }
};

const ResetSubPwordRenderer = (props: Props) => {
  const {data} = props;
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const dispatchResetSubPassword = useStore((state) => state.dispatchResetSubPassword);

  const handleResetPword = async () => {
    try {
      await dispatchResetSubPassword(data.id, data.email)

      dispatchSetAlert({
        status: 'success',
        header: 'Success',
        text: 'Sub password successfully reset'
      });
    } catch (err: any) {
      dispatchSetAlert({
        status: 'danger',
        header: 'Failure to Reset Sub Password',
        text: 'Failed to reset password'
      });
    }
  }

  return (
    <button className='' onClick={handleResetPword}>Reset Password</button>
  );
};

export default ResetSubPwordRenderer;