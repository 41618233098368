import getHeroText from './getHeroText';
import HeroText from '../entities/HeroText';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editHeroTextAPI} from '../../../api';
import {SetState, GetState} from 'zustand';

export const editHeroText = (set: SetState<State>, get: GetState<State>) =>
async (heroText: HeroText) => {

	const res = await editHeroTextAPI({
		headline: heroText.header,
		subhead: heroText.subheader
	});

	if (res !== null) setAlert(set, get)({
		status: 'success',
		header: 'Success',
		text: 'Successfully updated hero text'
	});
	
	getHeroText(set, get)();
};

export default editHeroText;