import {Request as EditSubAccountReq} from './editSubAccount';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type SubAccountToDelete = EditSubAccountReq;

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const deleteSubAccountPort = '/modifySubAccount';

const deleteSubAccountAPI = async (subAccount: SubAccountToDelete): Promise<AxiosResponse<Response>> => {
  const res = await api.post(deleteSubAccountPort, {
    ...subAccount,
    email: ''
  });

  if (isDebugging) {
    console.log(`${deleteSubAccountPort} response:`, res);//TESTING
  }

  return res;
};

export default deleteSubAccountAPI;