import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {resendSubAccountInviteAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

const resetSubPassword = (set: SetState<State>, get: GetState<State>) => async (subId: number, email: string) => {

	try {
    await resendSubAccountInviteAPI(subId, email, true);

    setAlert(set, get)({
      status: 'success',
      header: 'Success',
      text: `User has been emailed a link to reset password`
    });

	} catch (err: any | unknown) {
    if (isDebugging) {
      console.log('reset sub-account password store error:', err);
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to send email'
		});
	}
};

export default resetSubPassword;