import AccountNav from '../navigation/account/AccountNavView';
import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import {ReactComponent as LoadingIconSmall} from '../../media/icon-loading-small.svg';
import {useStore, Resident} from '../../../store';
import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import {Redirect, useLocation, useHistory} from 'react-router-dom';
import produce from 'immer';
import {TextField, MenuItem, SelectChangeEvent} from '@mui/material';

const EditResidents = () => {
	const dispatchGetResidentList = useStore(
		(state) => state.dispatchGetResidentList
	);
	const dispatchEditResident = useStore((state) => state.dispatchEditResident);
	const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
	const dispatchAddResident = useStore((state) => state.dispatchAddResident);
	const isAuthenticated = useStore((state) => state.isAuthenticated);
	const residents = useStore((state) => state.account?.residents);
	const account = useStore((state) => state.account);
	const location = useLocation();
	const history = useHistory();
	const [residentList, setResidentList] = useState<Array<Resident>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const defaultNewResident: Omit<Resident, 'id'> = {
		userId: Number(account?.userId) || 0,
		firstName: '',
		lastName: '',
		sex: 'F',
		clothingSize: '',
		shoeSize: '',
		budget: 0
	};
	const [newResident, setNewResident] = useState<Omit<Resident, 'id'>>(defaultNewResident);

	const onChangeResidentInput = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<'M' | 'F'>,
		residentIndex: string
	) => {
		const isRequiredField =
			event.target.name === 'firstName' || event.target.name === 'lastName';
		const isEmptyValue = event.target.value === '';

		if (isRequiredField && isEmptyValue) {
			dispatchSetAlert({
				header: 'Invalid Input',
				text: `${
					event.target.name === 'firstName' ? 'First name' : 'Last name'
				} cannot be empty`,
				status: 'warning'
			});
		} else {
			const changedResidentList = residentList.map((resident, index) => {
				let changedResident;
				if (index === Number(residentIndex)) {
					changedResident = {
						...resident,
						[event.target.name]: event.target.value
					};
				}
				return changedResident || resident;
			});

			setResidentList(changedResidentList || []);
		}
	};

	const onDeleteResident = (residentIndex: string) => {
		const residentToDelete = residentList[Number(residentIndex)];

		if (residentToDelete && window.confirm('Are you sure you want to delete this resident?')) {
			const modifiedResidentToDelete = {
				...residentToDelete,
				firstName: '',
				lastName: ''
			};

			dispatchEditResident(modifiedResidentToDelete);
		}
	};

	const onSaveResident = (residentIndex: string) => {
		const residentToSave = residentList[Number(residentIndex)];
		const invalidFirstName = residentToSave.firstName === '';
		const invalidLastName = residentToSave.lastName === '';

		if (invalidFirstName || invalidLastName) {
			dispatchSetAlert({
				status: 'warning',
				header: 'Invalid Input',
				text: `${invalidFirstName ? 'First Name' : 'Last Name'} cannot be empty`
			});
		} else {
			dispatchEditResident(residentList[Number(residentIndex)]);
			dispatchSetAlert({
				status: 'success',
				header: 'Resident Change Successful',
				text: 'Resident successfully saved'
			});
		}
	};

	const onChangeNewResidentInput = 
	(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<'M' | 'F'>) => {
		setNewResident({
			...newResident,
			[event.target.name]: event.target.value
		});
	};

	const onAddResident = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (newResident.firstName === '' || newResident.lastName === '') {
			dispatchSetAlert({
				header: 'Invalid Input',
				status: 'warning',
				text: `First and last name cannot be empty`
			});
			return;
		}

		setIsLoading(true);
		await dispatchAddResident(newResident);
		setIsLoading(false);
	};

	useEffect(() => dispatchGetResidentList, []);

	useEffect(() => {
		setResidentList(residents || []);
		setNewResident(defaultNewResident);
	}, [residents]);

	if (!isAuthenticated || !account || account.roleId !== 3) return <Redirect to='/' />;

	return (
		<section className='edit-residents'>
			<span className='edit-residents__header'>
				<button className='btn btn--secondary' onClick={() => history.push('/productList/dept/1')}>
					Go Shopping
				</button>
				<h1>Residents</h1>
			</span>
			<span className='edit-residents__labels hide--medium'>
				<h6 className='edit-residents__fname-label'>Residents</h6>
				{/* <h6 className='edit-residents__lname-label'>Last Name</h6>
				<h6 className='edit-residents__gender-label'>Gender</h6>
				<h6 className='edit-residents__clothing-label'>Clothing Size</h6>
				<h6 className='edit-residents__shoe-label'>Shoe Size</h6>
				<h6 className='edit-residents__budget-label'>Budget</h6> */}
			</span>
			{
				residentList ?
				residentList.map((resident, index) =>
					<span className='edit-residents__resident' key={index}>
						<button
							className='edit-residents__delete'
							onClick={() => onDeleteResident(index.toString())}
						>
							<TrashIcon />
						</button>
						<TextField
							label='First Name'
							id={`first-name-${index}`}
							name='firstName'
							variant='standard'
							value={residentList[index].firstName}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
							disabled
						/>
						<TextField
							label='Last Name'
							id={`last-name-${index}`}
							name='lastName'
							variant='standard'
							value={residentList[index].lastName}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
							disabled
						/>
						<TextField
							label='Sex'
							id='sex-new'
							name='sex'
							variant='standard'
							select
							value={residentList[index].sex}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
						>
							<MenuItem value='F'>F</MenuItem>
							<MenuItem value='M'>M</MenuItem>
						</TextField>
						<TextField
							label='Clothing Size'
							id={`clothing-size-${index}`}
							name='clothingSize'
							variant='standard'
							value={residentList[index].clothingSize}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
						/>
						<TextField
							label='Shoe Size'
							id={`shoe-size-${index}`}
							name='shoeSize'
							variant='standard'
							value={residentList[index].shoeSize}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
						/>
						<TextField
							label='Budget'
							type='number'
							id={`budget-${index}`}
							name='budget'
							variant='standard'
							value={residentList[index].budget}
							onChange={(e) => onChangeResidentInput(e, index.toString())}
							focused
						/>
						{/* <button
							className='resident__delete'
							id={index.toString()}
							onClick={() => onDeleteResident(index.toString())}
						>
							Delete
						</button> */}
						<button
							className='btn btn--secondary edit-residents__save'
							onClick={() => onSaveResident(index.toString())}
						>
							Save
						</button>
					</span>
				) : 
				<p>No Residents</p>
			}
			<form
				className='edit-residents__resident edit-residents__resident--new'
				onSubmit={onAddResident}
			>
				<span className='hide--medium'></span>
				<TextField
					label='First Name'
					id='first-name-new'
					name='firstName'
					variant='standard'
					value={newResident.firstName}
					onChange={onChangeNewResidentInput}
					focused
					required
				/>
				<TextField
					label='Last Name'
					id='last-name-new'
					name='lastName'
					variant='standard'
					value={newResident.lastName}
					onChange={onChangeNewResidentInput}
					focused
					required
				/>
				<TextField
					label='Gender'
					name='sex'
					variant='standard'
					select
					value={newResident.sex}
					onChange={onChangeNewResidentInput}
					focused
					required
				>
					<MenuItem value='F'>F</MenuItem>
					<MenuItem value='M'>M</MenuItem>
				</TextField>
				<TextField
					label='Clothing Size'
					id='clothing-size-new'
					name='clothingSize'
					variant='standard'
					value={newResident.clothingSize}
					onChange={onChangeNewResidentInput}
					focused
				/>
				<TextField
					label='Shoe Size'
					id='shoe-size-new'
					name='shoeSize'
					variant='standard'
					value={newResident.shoeSize}
					onChange={onChangeNewResidentInput}
					focused
				/>
				<TextField
					label='Budget'
					type='number'
					id='budget-new'
					name='budget'
					variant='standard'
					value={newResident.budget}
					onChange={onChangeNewResidentInput}
					focused
				/>
				<button
					className='btn btn--secondary edit-residents__add'
					disabled={isLoading}
					type='submit'
				>
					{
						isLoading ?
						<LoadingIconSmall /> :
						'Add'
					}
				</button>
			</form>
		</section>
	);
};

export default EditResidents;
