import {State} from '../../index';
import {defaultCheckout} from '../../checkout/entities/Checkout';
import {authService} from '../../../utility';
import {SetState} from 'zustand';

const logout = (set: SetState<State>) => () => {
  set({
    isAuthenticated: false,
    account: undefined,
    checkout: defaultCheckout,
    selectedResident: undefined
  });
  authService.credentials.clear();
  localStorage.removeItem('selectedResident');
  localStorage.removeItem('userToken');
};

export default logout;