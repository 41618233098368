import {DailyEntries as DailyEntriesReq, addDailyEntriesAPI} from '../../../api';
import {useStore} from '../../../store';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {ChangeEvent, FormEvent, useState} from 'react';
import {TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {Dayjs} from 'dayjs';

/**
 * Page form for admins to enter daily statistics
 * @returns Page component
*/
const DailyEntries = () => {
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 11,
      name: 'Daily Entries',
      path: '/admin/dailyEntries'
    }
  ]);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const defaultSelectedDate = dayjs(new Date());
  const [entryDate, setEntryDate] = useState<Dayjs | null>(defaultSelectedDate)
  const defaultDailyEntries = {
    nonWebOrders: '',
    nonWebOrderAmount: '',
    nonWebInvoices: '',
    nonWebInvoiceAmount: ''
  };
  const [dailyEntries, setDailyEntries] = useState(defaultDailyEntries);

  const onChangeSelectedDate = (newDate: Dayjs | null) => {
    setEntryDate(newDate);
  };

  const onChangeCurrencyAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    const value = e.target.value;

    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setDailyEntries({
        ...dailyEntries,
        [field]: value
      });
    }
  };
  
  const onChangeInteger = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    const value = e.target.value;
  
    if (!value || value.match(/^\d{1,}?$/)) {
      setDailyEntries({
        ...dailyEntries,
        [field]: value
      });
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!entryDate) return dispatchSetAlert({
      status: 'danger',
      header: 'Invalid input',
      text: 'A valid date is required'
    });

    const dailyEntriesToNum: DailyEntriesReq = {
      entryDate: entryDate.format('YYYY-MM-DD'),
      nonWebOrders: Number(dailyEntries.nonWebOrders),
      nonWebOrderAmount: Number(dailyEntries.nonWebOrderAmount),
      nonWebInvoices: Number(dailyEntries.nonWebInvoices),
      nonWebInvoiceAmount: Number(dailyEntries.nonWebInvoiceAmount)
    };

    const res = await addDailyEntriesAPI(dailyEntriesToNum);
    if (res !== null) dispatchSetAlert({
      status: 'success',
      header: 'Success',
      text: 'Daily entries have been submitted'
    });

  };

  return (
    <section className='a-daily-entries' onSubmit={onSubmit}>
      <form>
        <h3>Daily Entries</h3>
        <DatePicker
          className='a-daily-entries__date-picker'
          value={entryDate}
          onChange={onChangeSelectedDate}
          disableFuture
        />
        <TextField
          type='text'
          label='# of Non-Web Orders'
          name='nonWebOrders'
          value={dailyEntries.nonWebOrders}
          onChange={onChangeInteger}
        />
        <TextField
          type='text'
          label='$ of Non-Web Orders'
          name='nonWebOrderAmount'
          value={dailyEntries.nonWebOrderAmount}
          onChange={onChangeCurrencyAmount}
        />
        <TextField
          type='text'
          label='# of Non-Web Invoices'
          name='nonWebInvoices'
          value={dailyEntries.nonWebInvoices}
          onChange={onChangeInteger}
        />
        <TextField
          type='text'
          label='$ of Non-Web Invoices'
          name='nonWebInvoiceAmount'
          value={dailyEntries.nonWebInvoiceAmount}
          onChange={onChangeCurrencyAmount}
        />
        <input
          className='btn btn--primary'
          type='submit'
          value='Submit'
          disabled={
            entryDate === null ||
            dailyEntries.nonWebOrders === '' ||
            dailyEntries.nonWebOrderAmount === '' ||
            dailyEntries.nonWebInvoices === '' ||
            dailyEntries.nonWebInvoiceAmount === ''
          }
        />
      </form>
    </section>
  );
};

export default DailyEntries;