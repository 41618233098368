import getProductInfo from './getProductInfo';
import type Product from '../entities/Product';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {addProductAPI} from '../../../api';
import {SetState, GetState} from 'zustand';

export type InitialDept = {
	deptId: string;
	subDeptId?: string;
	superSubDeptId?: string;
};

export const addProduct = (set: SetState<State>, get: GetState<State>) =>
	async (newProduct: Omit<Product, 'id' | 'depts' | 'subProducts' | 'MFB'>, initialDept: InitialDept) => {

		const res = await addProductAPI({
			prod_itemID: newProduct.itemId,
			prod_groupID: newProduct.groupId,
			prod_name: newProduct.name,
			prod_desc: newProduct.desc,
			prod_color: newProduct.options,
			prod_clothingType: newProduct.clothingType,
			prod_nurseTip: newProduct.needs,
			prod_price: newProduct.priceRange,
			sched_delivery_ID: newProduct.scheduledDeliveryId,
			sched_delivery_options: newProduct.scheduledDeliveryOptions,
			MFB: newProduct.sex,
			taxExempt: newProduct.isTaxExempt ? '1' : '0',
			hide: newProduct.isHidden ? '1' : '0',
			imageCount: newProduct.imageCount,
			deptID: initialDept.deptId,
			subDeptID: initialDept.subDeptId || 0,
			superSubDeptID: initialDept.superSubDeptId || 0
		});

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully added product'
		});

		getProductInfo(set, get)(newProduct.itemId);
	};

export default addProduct;