import OrderToAdd from '../entities/OrderToAdd';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const addToCartPort = '/addCart';

const addToCartAPI = async (orderToAdd: OrderToAdd) => {
  const res = await api.post(addToCartPort, orderToAdd);

  if (isDebugging) {
    console.log(`${addToCartPort} response:`, res);//TESTING
  }

  return res;
};

export default addToCartAPI;