import getProductInfo from './getProductInfo';
import type SubProduct from '../entities/SubProduct';
import {State} from '../..';
import StoreError from 'application/store/utility/errors/StoreError';
import setAlert from '../../alerts/actions/setAlert';
import {editSubProductAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const editSubProduct = (set: SetState<State>, get: GetState<State>) =>
	async (subProduct: SubProduct) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

    const req = {
      prod_id: subProduct.id.toString(),
      prod_groupID: subProduct.groupId,
      prod_itemID: subProduct.itemId,
      prod_name: subProduct.name,
      prod_size: subProduct.size,
      prod_price: subProduct.price,
      prod_shipping: ''
    };

    const res = await editSubProductAPI(req);

    if (res !== null) setAlert(set, get)({
      status: 'warning',
      header: 'Success',
      text: 'Successfully updated sub-product'
    });

    getProductInfo(set, get)(productInStore.itemId);
	};

export default editSubProduct;