import {State} from 'application/store';
import setAlert from 'application/store/alerts/actions/setAlert';
import {authenticateAdminAPI} from 'application/api';
import {authService} from 'application/utility';
import {SetState, GetState} from 'zustand';

export const loginAdmin = (set: SetState<State>, get: GetState<State>) =>
	async (usernameOrEmail: string, password: string, captchaToken: string) => {

		const res = await authenticateAdminAPI({
			username: usernameOrEmail,
			password: password,
			captchaToken: captchaToken
		});

		if (res === null) return;

		const {username, superUser, userToken, adminID} = res;

		authService.token.set(userToken);

		set({
			admin: {
				id: adminID,
				username: username,
				superUser: superUser === '1' ? true : false
			}
		});

		setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully logged in'
		});
	};

export default loginAdmin;