import hashPassword from './hashPassword';
import {isDebugging} from './';
import * as bcrypt from 'bcryptjs';

const authService = {
	password: {
    hash: hashPassword,
		isEqualToHash: (plainPassword: string, hashedPassword: string) => {
			const isEqual = bcrypt.compareSync(plainPassword, hashedPassword);

      if (isDebugging) {
        console.log('isEqualToHash');
      }
      
			return isEqual;
		}
	},

  token: {
    get: () => {
      const authToken = localStorage.getItem('userToken');
      return authToken || 'XXXGUESTTOKENXXX';
    },
    set: async (token?: string) => {
      //unfortunately this is the cleanest implementation, since 'typeof' doesn't recognize null
      if (token === null) token = undefined;

      switch (typeof token) {
        case 'string':
          await localStorage.setItem('userToken', token);
          break;
        case 'undefined':
          await localStorage.removeItem('userToken');
          break;
        default:
          throw new Error('type of token was not recognized');
      }
    }
  },

  credentials: {
    get: (): {username: string; password: string} | undefined => {
      const credentials = localStorage.getItem('credentials');
      if (credentials) return JSON.parse(credentials);
    },
    set: (username: string, hashedPassword: string) => {
      localStorage.setItem('credentials', JSON.stringify(
        {username: username, password: hashedPassword}
      ));
    },
    clear: () => localStorage.removeItem('credentials')
  },

  recaptcha: {
    siteKey: '6LdWV9ggAAAAAA3wSf47d8vsgthLo_SE94GHJCED'
    // siteKey: '6Lc6DegeAAAAAERTFDEOLU75KFpTir84ako3Uksm'
  }
};

export default authService;