import EditedAccount from '../entities/EditedAccount';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const editAccountPort = '/modifyAccount';

const editAccountAPI = async (editedAccount: EditedAccount): Promise<AxiosResponse<Response>> => {
  let body;

  if (editedAccount.roleID === 2) {
    body = {
      userID: editedAccount.userID,
      username: editedAccount.username,
      currentPword: editedAccount.password ?
      editedAccount.password.hashedOldPassword : 
      undefined,
      newPword: editedAccount.password ?
      editedAccount.password.hashedNewPassword :
      undefined,
      newPwordConfirm: editedAccount.password ?
      editedAccount.password.hashedNewPasswordConfirm :
      undefined,
      roleID: 2,
      firstName: editedAccount.firstName,
      lastName: editedAccount.lastName,
      title: editedAccount.title,
      email: editedAccount.email,
      phoneNumber: editedAccount.phoneNumber,
      address1: editedAccount.shipping.address1,
      address2: editedAccount.shipping.address2,
      city: editedAccount.shipping.city,
      state: editedAccount.shipping.state,
      zip: editedAccount.shipping.zip,
      country: editedAccount.shipping.country,
      newsletter: editedAccount.newsletter ? '1' : null,
    };
  } else if (editedAccount.roleID === 3) {
    body = {
      userID: editedAccount.userID,
      username: editedAccount.username,
      roleID: 3,
      firstName: editedAccount.firstName,
      lastName: editedAccount.lastName,
      title: editedAccount.title,
      email: editedAccount.email,
      // pword: editedAccount.password ?
      // editedAccount.password.hashedNewPassword :
      // undefined,
      // pwordConfirm: editedAccount.password ?
      // editedAccount.password.hashedNewPasswordConfirm :
      // undefined,
      currentPword: editedAccount.password ?
      editedAccount.password.hashedOldPassword : 
      undefined,
      newPword: editedAccount.password ?
      editedAccount.password.hashedNewPassword :
      undefined,
      newPwordConfirm: editedAccount.password ?
      editedAccount.password.hashedNewPasswordConfirm :
      undefined,
      phoneNumber: editedAccount.phoneNumber,
      facName: editedAccount.company?.name,
      facPhone: editedAccount.company?.phoneNumber,
      facAdmin: editedAccount.company?.admin,
      facOfficeMgr: editedAccount.company?.officeManager.name,
      facOfficeMgrEmail: editedAccount.company?.officeManager.email,
      facFax: editedAccount.company?.fax,
      facAddress1: editedAccount.shipping.address1,
      facAddress2: editedAccount.shipping.address2,
      facCity: editedAccount.shipping.city,
      facCounty: editedAccount.shipping.county,
      facState: editedAccount.shipping.state,
      facZip: editedAccount.shipping.zip,
      facCountry: editedAccount.shipping.country,
      newsletter: editedAccount.newsletter ? '1' : null
    };
  }

  const res = await api.post(editAccountPort, body);

  if (isDebugging) {
    console.log(`${editAccountPort} response:`, res);//TESTING
  }

  return res;
};

export default editAccountAPI;