import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Request = {
  ID: number;
  bannerText: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const editBannerTextPort = '/modifyBannerText';

/**Edits the text shown in the notification bar. */
const editBannerTextAPI = async (request: Request): Promise<AxiosResponse<Response>> => {
  const res = await api.post(editBannerTextPort, request);
  
  if (isDebugging) {
    console.log(`${editBannerTextPort} response:`, res);//TESTING
  }

  return res;
};

export default editBannerTextAPI;