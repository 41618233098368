import React from 'react';
import {useLocation, Link} from 'react-router-dom';

const Banner = () => {
  const location = useLocation();

  return (
    <div className='auth__banner'>
      <Link
        className={
          location.pathname === '/login' ?
          'auth__login auth__login--active' :
          'auth__login'
        }
        to='/login'
      >
        Facility Login
      </Link> 
      <Link
        className={
          location.pathname === '/register' ?
          'auth__register auth__register--active' :
          'auth__register'
        }
        to='/register'
      >
        Facility Registration
      </Link> 
    </div>
  );
};

export default Banner;