import FinalizedCart from '../entities/FinalizedCart';
import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  invoiceID: string;
  facility_rewards: string;
};

const finalizeCartsPort = '/finalizeCarts';

const finalizeCartsAPI =
async (finalizedCarts: Array<FinalizedCart>): Promise<AxiosResponse<Response>> => {
  if (isDebugging) {
    console.log('/finalizeCarts request:', finalizedCarts);//TESTING
  }
  
  const res = await api.post(finalizeCartsPort, finalizedCarts);

  if (isDebugging) {
    console.log(`${finalizeCartsPort} response:`, res);//TESTING
  }

  return res;
};

export default finalizeCartsAPI;