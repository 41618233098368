import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const getRelatedProductsPort = '/getRelatedProducts';

const getRelatedProductsAPI = async (deptId: number, mfb?: 'M' | 'F' | 'B') => {
  const res = await api.get(getRelatedProductsPort, {
    params: {
      deptID: deptId,
      MFB: mfb || undefined
    }
  });

  if (isDebugging) {
    console.log(`${getRelatedProductsPort} response:`, res);//TESTING
  }

  return res;
};

export default getRelatedProductsAPI;