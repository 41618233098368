import getBannerText from './getBannerText';
import BannerText from '../entities/BannerText';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editBannerTextAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

/**Edits the text shown in the notification bar, then updates the store. */
export const editBannerText = (set: SetState<State>, get: GetState<State>) =>
	async (bannerText: BannerText) => {

		try {
			await editBannerTextAPI({
        ID: bannerText.id,
        bannerText: bannerText.text
      });

      setAlert(set, get)({
        status: 'success',
        header: 'Success',
        text: 'Successfully updated banner text'
      });

			getBannerText(set, get)();

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log(`editBannerText error:`, err);//TESTING
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to update banner text'
			});
		}
	};

export default editBannerText;