import {post} from 'application/api/baseApi';

type NormalRequest = {
  targetAdminID: number;
};

type PasswordRequest = NormalRequest & {
  pword: string;
  pwordConfirm: string;
};

type Request = NormalRequest | PasswordRequest;

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyAdminAccount';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const deleteAdminAPI = (req: Request) => post<Response>(
  path,
  {
    targetAdminID: req.targetAdminID,
    username: ''    
  }
);

export default deleteAdminAPI;