import {State} from 'application/store';
import {getDeptMatrixAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

/**Stores the matrix used to display all departments. */
export const getDeptMatrix = (set: SetState<State>, get: GetState<State>) =>
	async () => {

    const res = await getDeptMatrixAPI();

    if (res === null) return;
    
    set(() => ({
      deptMatrix: res.length > 0 ?
      res.map((dept) => ({
        id: dept.deptID,
        name: dept.deptName,
        subDepts: dept.subDept.map((subDept) => ({
          id: subDept.subDeptID,
          name: subDept.subDeptName,
          superSubDepts: subDept.superSubDept.map((superSubDept) => ({
            id: superSubDept.superSubDeptID,
            name: superSubDept.superSubDeptName
          }))
        }))
      })) : []
    }));
	};

export default getDeptMatrix;