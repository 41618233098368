import facilityPopup from '../../media/Fac_Promo_Popup.png';
import {ReactComponent as CloseIcon} from '../../media/icon-close.svg';
import React, {MouseEventHandler} from 'react';
import {useHistory} from 'react-router-dom';

type Props = {
  onClose: MouseEventHandler<HTMLButtonElement>;
};

const RegisterPopup = ({onClose}: Props) => {
  const history = useHistory();
  const onClick = () => history.push('/info/facility');

  return (
    <div className='landing__popup'>
      <img src={facilityPopup} alt='facility promotion'/>
      <button onClick={onClose}>
        <CloseIcon />
      </button>
      <button className='btn btn--primary' onClick={onClick}>
        Learn More
      </button>
    </div>
  );
};

export default RegisterPopup;