import {get} from 'application/api/baseApi';

export type Response = [
  {
    userID: number;
    fname: string;
    lname: string;
    address1: string;
    city: string;
    state: string;
  }
];

const path = '/indCustomerLookup';

/**
 * Middleware for {@link get | the generic API "get" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param search Optional search string
 * @returns Expected response or null
 */
const lookupIndividualAPI = (search?: string) => get<Response>(
  path,
  {
    params: {
      value: search || ''
    }
  }
);

export default lookupIndividualAPI;