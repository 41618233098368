import {useRecaptchaContext} from '../../context/recaptcha';
import handleReCAPTCHA from '../../utility/google/handleReCAPTCHA';
import {useStore} from '../../../store';
import {useState} from 'react';

const FooterController = () => {
  const account = useStore((state) => state.account);
  const dispatchSubscribeToNewsletter = useStore((state) => state.dispatchSubscribeToNewsletter);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRecaptchaContext();

  const onSubmitNewsletter = async () => {
    const token = await handleReCAPTCHA(recaptchaRef);
    if (!token) return;

    setIsLoading(true);
    await dispatchSubscribeToNewsletter(email, token);
    setIsLoading(false);
    setEmail('');
  };

  return {
    account,
    email,
    setEmail,
    isLoading,
    onSubmitNewsletter
  };
};

export default FooterController;