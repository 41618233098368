import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const emptyCartPort = '/emptyCart';

const emptyCartAPI = async (userId: number, residentId = 0) => {
  const body = {
    userID: userId,
    residentID: residentId
  };
  const res = await api.post(emptyCartPort, body);

  if (isDebugging) {
    console.log(`${emptyCartPort} response:`, res);//TESTING
  }

  return res;
};

export default emptyCartAPI;