import {useStore} from '../../../store';
import React from 'react';
import {TextField, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent} from '@mui/material';

const ResidentSelection = () => {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const account = useStore((state) => state.account);
  const selectedResident = useStore((state) => state.selectedResident);
  const residentCart = useStore((state) => state.checkout.carts?.find((cart) => cart.residentId === selectedResident?.id));
  const dispatchSetSelectedResident = useStore((state) => state.dispatchSetSelectedResident);

  const onChangeSelectedResident = (event: SelectChangeEvent<string>) => {
    dispatchSetSelectedResident(Number(event.target.value));
  };

  return <>
    {
      isAuthenticated &&
      account &&
      account.roleId === 3 &&
      account.residents &&
      account.residents.length > 0 &&
      <section className='resident-selection'>
        <div className='resident-selection__main'>
          <FormControl>
            <InputLabel id='name-label'>Shopping for:</InputLabel>
            <Select
              labelId='name-label'
              label='Shopping for:'
              value={selectedResident?.id.toString() || ''}
              onChange={(e) => onChangeSelectedResident(e)}
            >
              {
                account.residents.map((resident) =>
                  <MenuItem value={resident.id.toString()}>
                    {`${resident.firstName} ${resident.lastName}`}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        </div>
        <div className='resident-selection__resident'>
          {
            selectedResident ?
            <>
              {
                selectedResident.clothingSize &&
                <span>
                  <p>Clothes:</p>
                  <h5>{selectedResident.clothingSize}</h5>
                </span>
              }
              {
                selectedResident.shoeSize &&
                <span>
                  <p>Shoes:</p>
                  <h5>{selectedResident.shoeSize}</h5>
                </span>
              }
              {
                selectedResident.budget &&
                selectedResident.budget > 0 ?
                <span>
                  <p>Budget:</p>
                  <h5>{'$' + selectedResident.budget}</h5>
                </span> :
                <></>
              }
              <span
                className={`resident-selection__total ${
                  residentCart &&
                  Number(residentCart.totals.grandTotal) > selectedResident.budget &&
                  selectedResident.budget !== 0 &&
                  'resident-selection__total--over-budget'
                }`}
              >
                <p>Cart Total:</p>
                <h5>{`$${residentCart?.totals.grandTotal || 0}`}</h5>
              </span>
              <span>
                <p>Items:</p>
                <h5>{residentCart?.orders.length || 0}</h5>
              </span>
            </> :
            'No resident selected'
          }
        </div>
      </section>
    }
  </>
};

export default ResidentSelection;
