import React from 'react';
import {useHistory} from 'react-router-dom';

const PageNotFound = () => {
  const history = useHistory();

  const redirectToCatalog = () => history.push('/productList/dept/1');

  return (
    <section className='page-not-found'>
      <div className='page-not-found__content'>
        <h1>Oops!</h1>
        <h2>
          We couldn't find the page <br />
          that you were looking for.
        </h2>
        <h2>Try searching, or</h2>
        <button className='btn btn--primary' onClick={redirectToCatalog}>
          Go Shopping
        </button>
      </div>
    </section>
  );
};

export default PageNotFound;