import {post} from 'application/api/baseApi';

export type Request = {
  entryDate: string;
  nonWebOrders: number;
  nonWebOrderAmount: number;
  nonWebInvoices: number;
  nonWebInvoiceAmount: number;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/addDailyEntries';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const addDailyEntriesAPI = (req: Request) => post<Response>(path, req);

export default addDailyEntriesAPI;