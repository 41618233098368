import {DomainError, ErrorConfig} from 'application/utility';

/**Custom error to indicate that it has originated from the application store.*/
export default class StoreError extends DomainError {

  /**
   * Creates a new StoreError instance.
   * @param error Error coming from the store
   * @param message Custom error message
   * @param config Optional config to alter behavior
   * @returns New StoreError instance
   */
  constructor(error: any, message: string, config?: ErrorConfig) {
    super(error, message, config);

    this.name = 'StoreError'
  }
};