import handleReCAPTCHA from '../../../../utility/google/handleReCAPTCHA';	
import {useRecaptchaContext} from '../../../../context/recaptcha';
import {ReactComponent as LoadingIconSmall} from '../../../../media/icon-loading-small.svg';
import {useStore} from '../../../../../store';
import {authService} from '../../../../../utility';
import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import TextField from '@mui/material/TextField';

const Login = () => {
	const history = useHistory();
	const isAuthenticated = useStore((state) => state.isAuthenticated);
	const account = useStore((state) => state.account);
	const dispatchLogin = useStore((state) => state.dispatchLogin);
	const [formData, setFormData] = useState({
		usernameOrEmail: '',
		plainPassword: ''
	});
	const [isLoading, setIsLoading] = useState(false);
	const recaptchaRef = useRecaptchaContext();

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) =>
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});

	const onSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		const token = await handleReCAPTCHA(recaptchaRef);
		if (!token) return;
		
		setIsLoading(true);

		await dispatchLogin(
			formData.usernameOrEmail,
			authService.password.hash(formData.plainPassword),
			token
		);

		setFormData({
			usernameOrEmail: '',
			plainPassword: ''
		});

		setIsLoading(false);
	};
	
	useEffect(() => {
		if (isAuthenticated && account && account?.roleId === 3) history.push('/account/residents');
	}, [isAuthenticated, account]);

	return (
		<section className='login'>
			<p className='login__heading'>Sign in below</p>
			<form onSubmit={onSubmitForm}>
				<TextField
					className='login__username'
					name='usernameOrEmail'
					label='Username/Email'
					value={formData.usernameOrEmail}
					onChange={onChangeInput}
					required
				/>
				<TextField
					className='login__password'
					name='plainPassword'
					label='Password'
					type='password'
					value={formData.plainPassword}
					onChange={onChangeInput}
					required
				/>
				<button
					className='btn btn--primary login__submit'
					type='submit'
					disabled={isLoading}
				>
					{
						isLoading ?
						<LoadingIconSmall /> :
						'Login'
					}
				</button>
			</form>
			<span className='login__link-wrap login__link-wrap--dual'>
				<Link to='/helpCenter/username'>Forgot Username</Link>
				<div className='login__line'></div>
				<Link to='/helpcenter/password'>Forgot Password</Link>
			</span>
			<p className='login__link-wrap'>
				{`Don't have an account? `}
				<Link className='login__link' to='/register'>
					Switch over to registration
				</Link>
			</p>
		</section>
	);
};

export default Login;