import NavbarController from './NavbarController';
import Searchbar from '../searchbar/SearchbarView';
import logo from '../../../media/sss_logo.png';
import logoShort from '../../../media/sss_logo-short.png';
import {ReactComponent as ShoppingCartIcon} from '../../../media/icon-shopping-cart.svg';
import {ReactComponent as UserIcon} from '../../../media/icon-user.svg';
import {ReactComponent as MenuIcon} from '../../../media/icon-menu.svg';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';

const NavbarView = () => {
  const {
    navigation,
    account,
    isAuthenticated,
    hoveredDept,
    hoveredAccount,
    screenWidth,
    init,
    onNavChange,
    clearDeptPanel,
    clearAccountPanel,
    clearAccountSidebar,
    clearHamburger,
    onClickLogout,
    onClickAccount,
    onClickHamburger,
    onClickDept,
    onHoverDept,
    onHoverAccount
  } = NavbarController();

  useEffect(init, []);
  useEffect(onNavChange, [navigation]);

  return (
    <section className='navbar' onMouseLeave={clearDeptPanel}>
      {
        navigation &&
        hoveredDept &&
        <div className='navbar__panel'>
          {
            hoveredDept.subDepartments.map((subDept, ind) => 
              <div key={ind} className='navbar__panel-item'>
                <Link
                  key={subDept.subDept_ID}
                  to={`/productList/dept/${hoveredDept.dept_ID}/${subDept.subDept_ID}`}
                  onClick={clearDeptPanel}
                >
                  {subDept.subDept}
                </Link>
                {
                  subDept.superSubDepartments &&
                  subDept.superSubDepartments.length > 0 &&
                  subDept.superSubDepartments.map((superSubD) =>
                    <Link
                      key={superSubD.superSubDept_ID}
                      to={`/productList/dept/${hoveredDept.dept_ID}/${subDept.subDept_ID}/${superSubD.superSubDept_ID}`}
                      onClick={clearDeptPanel}
                    >
                      {superSubD.superSubDept}
                    </Link>
                  )
                }
              </div>
            )
          }
        </div>
      }
      {
        navigation &&
        hoveredAccount &&
        <div className='navbar__panel navbar__panel--account' onMouseLeave={clearAccountPanel}>
          {
            (!account || account.roleId !== 3) &&
            <>
              <Link to='/login'>Login</Link>
              <Link to='/register'>Register</Link>
            </>
          }
          {
            isAuthenticated &&
            account &&
            account.roleId === 3 &&
            <>
              <Link to='/account/invoices' onClick={clearAccountPanel}>Invoices</Link>
              <Link to='/account/residents' onClick={clearAccountPanel}>Residents</Link>
              <Link to='/account/edit' onClick={clearAccountPanel}>Account</Link>
              {/* <Link to='/account/password' onClick={clearAccountPanel}>Change Password</Link> */}
              <button
                className='navbar__button'
                onClick={onClickLogout}
              >
                Logout
              </button>
            </>
          }
        </div>
      }
      {
        navigation &&
        <button
          className='navbar__hamburger'
          onClick={onClickHamburger}
        >
          <MenuIcon />
        </button>
      }
      {
        navigation &&
        <ul className='navbar__links navbar__links--catalog' onMouseLeave={clearHamburger}>
          <li className='navbar__search navbar__search--mobile'>
            <Searchbar />
          </li>
          {
            navigation.map((dept) =>
              <li
                key={dept.dept_ID}
                className='navbar__link navbar__link--mobile'
                id={'dept' + dept.dept_ID.toString()}
                onClick={() => onClickDept(dept.dept_ID)}
                onMouseOver={() => onHoverDept(dept)}
              >
                <Link
                  to={`/productList/dept/${dept.dept_ID}`}
                >
                  {dept.dept}
                </Link>
                <div className='navbar__dropdown'>
                  {
                    dept.subDepartments.map((subDept) => 
                    <Link
                      key={subDept.subDept_ID}
                      to={`/productList/dept/${dept.dept_ID}/${subDept.subDept_ID}`}
                    >
                      {subDept.subDept}
                    </Link>)
                  }
                </div>
              </li>
            )
          }
        </ul>
      }

      <Link className={`logo ${screenWidth > 1000 ? '' : 'logo--small'}`} to='/'>
        <img src={screenWidth > 1000 ? logo : logoShort} alt='logo' />
      </Link>

      <ul className='navbar__links navbar__links--other'>
        {
          navigation &&
          <li className='navbar__search navbar__search--desktop'>
            <Searchbar />
          </li>
        }
        <li
          className='navbar__link'
          onMouseOver={onHoverAccount}
          onClick={onClickAccount}
        >
          {
            account && account.roleId === 3 ?
            <>
              <p className='navbar__greeting'>{`Hello ${account.firstName}`}</p>
              <button>
                <UserIcon className='icon-user icon-user--green' id='user-svg'/>
              </button>
            </> :
            <>
              <Link className='navbar__login' to='/login'>Login/Register</Link>
              {/* <button>
                <UserIcon className='icon-user' id='user-svg'/>
              </button> */}
            </>
          }
        </li>
        <li className='navbar__link' onMouseOver={clearAccountPanel}>
          <Link to='/checkout/carts'>
            <ShoppingCartIcon />
          </Link>
        </li>
      </ul>
      <ul
        className='navbar__links navbar__links--account'
        onMouseOver={clearDeptPanel}
        onMouseLeave={clearAccountSidebar}
      >
        {
          (!account || account.roleId !== 3) &&
          <>
            <Link to='/login'>Login</Link>
            <Link to='/register'>Register</Link>
          </>
        }
        {
          isAuthenticated &&
          account &&
          account.roleId === 3 &&
          <>
            <Link to='/account/invoices' onClick={() => {onClickAccount();clearAccountPanel();}}>Invoices</Link>
            <Link to='/account/residents' onClick={() => {onClickAccount();clearAccountPanel();}}>Residents</Link>
            <Link to='/account/edit' onClick={() => {onClickAccount();clearAccountPanel();}}>Account</Link>
            <Link to='/account/password' onClick={() => {onClickAccount();clearAccountPanel();}}>Change Password</Link>
            <Link to='/account/facilityForms' onClick={() => {onClickAccount();clearAccountPanel();}}>Facility Forms</Link>
            <Link to='/account/freeCatalog' onClick={() => {onClickAccount();clearAccountPanel();}}>Free Catalog</Link>
            <button className='navbar__button' onClick={onClickLogout}>Logout</button>
          </>
        }
      </ul>
    </section>
  )
};

export default NavbarView;
