import FeedbackController from './FeedbackController';
import feedbackBannerImage from '../../../media/FeedbackBanner.png';
import {useEffect} from 'react';
import {TextField} from '@mui/material';

const FeedbackView = () => {
  const {
    account,
    updateAccountInfo,
    init,
    formData,
    onChangeInput,
    onSubmit,
    isWantingFollowUp,
    setIsWantingFollowUp,
    submitButtonAttributes
  } = FeedbackController();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateAccountInfo();
  }, [account]);
  
  return (
    <section className='feedback'>
      <div className='feedback__banner'>
        <img src={feedbackBannerImage} alt='banner'/>
        <h1>Customer Feedback</h1>
        <h2>Tell us what you think</h2>
      </div>
      <p className='feedback__desc'>
        We appreciate your comments and/or feedback regarding our
        website, our products, our service, our employees, or
        anything you'd like to pass along to management. Please
        take a moment to fill out these fields below, and we will
        take your comments or suggestions into careful
        consideration.
      </p>
      <form onSubmit={onSubmit} className='feedback__form'>
        <TextField
          label='First Name'
          name='firstName'
          value={formData.firstName}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <TextField
          label='Last Name'
          name='lastName'
          value={formData.lastName}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <TextField
          label='Email'
          name='email'
          value={formData.email}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <TextField
          label='Phone Number'
          name='phoneNumber'
          value={formData.phoneNumber}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <TextField
          label='Facility Name'
          name='facilityName'
          value={formData.facilityName}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <TextField
          className='feedback__comments'
          multiline
          minRows={6}
          maxRows={6}
          label='Comments'
          name='comments'
          value={formData.comments}
          onChange={(e) => onChangeInput(e)}
          required
        />
        <div className='feedback__follow-up'>
          Do you want us to follow up with you regarding this?
          <span className='switch'>
            <input
              type='checkbox'
              checked={isWantingFollowUp}
              onChange={() => setIsWantingFollowUp(!isWantingFollowUp)}
              id='follow-up-checkbox'
              className='switch__checkbox'
            />
            <label htmlFor='follow-up-checkbox' className='switch__label' />
          </span>
        </div>
        <input
          className='btn btn--primary'
          type='submit'
          value={submitButtonAttributes.text}
          disabled={submitButtonAttributes.disabled}
        />
      </form>
    </section>
  );
};

export default FeedbackView;
