import ProductPreview from '../productPreview/ProductPreview';
import ResidentSelection from '../../auth/ResidentSelection';
import Breadcrumbs from '../../navigation/breadcrumbs/BreadcrumbsView';
import DeptNav from '../../navigation/depts/DeptNavView';
import {ReactComponent as LoadingIcon} from '../../../media/icon-loading.svg';
import {useStore, Breadcrumbs as BreadcrumbArray} from '../../../../store';
import {SearchType, Product, getProductListAPI, getRelatedProductsAPI} from '../../../../api';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

export type ProductListParams = {
  dept?: string;
  subDept?: string;
  superSubDept?: string;
  search?: string;
  need?: string;
};

const ProductList = () => {
  const navigation = useStore((state) => state.navigation);
  const selectedResident = useStore((state) => state.selectedResident);
  const dispatchCreateBreadcrumbs = useStore((state) => state.dispatchCreateBreadcrumbs);
  const dispatchGetResidentList = useStore((state) => state.dispatchGetResidentList);
  const {dept, subDept, superSubDept, search, need} = useParams<ProductListParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState<Array<Omit<Product, 'relatedProds'>>>([]);
  const [relatedProducts, setRelatedProducts] = useState<Array<Omit<Product, 'relatedProds'>>>([]);
  let searchType: SearchType;
  let searchValue: string | number;
  let breadcrumbsArray: BreadcrumbArray = [];

  const getRandomKey = () => Math.random() * Math.random() * 100;

  const createBreadcrumbs = () => {
    if (!need && !search) {

      dept && 
      navigation?.forEach((d) => {
        if (d.dept_ID === Number(dept)) breadcrumbsArray.push({
          id: d.dept_ID,
          name: d.dept,
          path: `/productList/dept/${d.dept_ID}`
        });

        subDept &&
        d.subDepartments.forEach((subD) => {
          if (subD.subDept_ID === Number(subDept)) breadcrumbsArray.push({
            id: subD.subDept_ID,
            name: subD.subDept,
            path: `/productList/dept/${d.dept_ID}/${subD.subDept_ID}`
          });

          superSubDept &&
          subD.superSubDepartments.forEach((superSubD) => {
            if (superSubD.superSubDept_ID === Number(superSubDept)) breadcrumbsArray.push({
              id: superSubD.superSubDept_ID,
              name: superSubD.superSubDept,
              path: `/productList/dept/${d.dept_ID}/${subD.subDept_ID}/${superSubD.superSubDept_ID}`
            });
          });
        });
      });
      
      dispatchCreateBreadcrumbs(breadcrumbsArray);
    } else {
      useStore.setState({breadcrumbs: undefined});
    }
  };

  if (dept && subDept && superSubDept) {
    searchType ='superSubDept';
    searchValue = Number(superSubDept);
  } else if (dept && subDept) {
    searchType = 'subDept';
    searchValue = Number(subDept);
  } else if (dept) {
    searchType = 'dept';
    searchValue = Number(dept);
  } else if (need) {
    searchType = 'need';
    searchValue = need;
  } else {
    searchType = 'search';
    searchValue = search || '';
  }

  createBreadcrumbs();

  useEffect(() => {
    getProductListAPI(searchType, searchValue)
      .then((res) => {
        setProductList(res.data);

        getRelatedProductsAPI(
          typeof searchValue === 'number' ?
          searchValue : 
          1,
          selectedResident ?
          selectedResident.sex :
          undefined
        ).then((res) => {
          setRelatedProducts(res.data);
          setIsLoading(false);
        });
      });
  },[searchType, searchValue]);

  useEffect(dispatchGetResidentList, []);

  // if (!navigation) return <Redirect to='/' />;

  return (
    <section className='product-list'>
      {
        isLoading ?
        <LoadingIcon /> :
        <>
          <Breadcrumbs />

          <aside className='product-list__left-gutter'>
            <ResidentSelection />
            <DeptNav />
          </aside>

          <div className='product-list__main'>
            {
              breadcrumbsArray &&
              breadcrumbsArray.length > 0 &&
              <h1 className='product-list__main-title'>{breadcrumbsArray[breadcrumbsArray.length - 1].name}</h1>
            }
            {
              !productList ||
              productList.length === 0 ||
              !searchValue ?
              <p>Oops, nothing matches this search.</p> :
              (() => {
                let currentSubDept = '';
                let currentSuperSubDept = '';
                let currentClothingType = '';
                let productsWithHeaders: Array<JSX.Element> = [];

                productList.forEach((product, index) => {
                  productsWithHeaders.push(<ProductPreview product={product} key={getRandomKey()} />);
                })

                return productsWithHeaders;
              })()
            }
          </div>

          <aside className='product-list__right-gutter hide--medium'>
            {
              productList?.length > 0 &&
              <>
                <p>Also Available:</p>
                {
                  relatedProducts.map((product, index) => 
                    <ProductPreview product={product} key={index} />
                  )
                }
              </>
            }
          </aside>
        </>
      }
    </section>
  )
};

export default ProductList;