import {AdminToEditExtended} from '../../components/admin/LookupAdmin';
import {useStore} from '../../../store';
import React, {MouseEvent} from 'react';

type Props = {
  value: number;
  data: AdminToEditExtended;
};

const DeleteAdminRenderer = (props: Props) => {
  const {
    value,
    data: {
      id,
      username,
      password,
      passwordConfirm,
      isSuperAdmin,
      isNew
    }
  } = props;
  const dispatchEditAdmin = useStore((state) => state.dispatchEditAdmin);
  const dispatchAddAdmin = useStore((state) => state.dispatchAddAdmin);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (
      (username.length < 1) ||
      (username === 'Enter username')
    ) {
      dispatchSetAlert({
        status: 'warning',
        header: 'Invalid Input',
        text: 'Username must be at least one character long'
      });
      return;
    }
    
    if (
      (password.length > 1 && password.length < 8) ||
      (passwordConfirm.length > 1 && password.length < 8)
    ) {
      dispatchSetAlert({
        status: 'warning',
        header: 'Invalid Input',
        text: 'Password must be at least 8 characters long'
      });

      return;
    }
    
    if (password !== passwordConfirm) {
      dispatchSetAlert({
        status: 'warning',
        header: 'Invalid Input',
        text: 'Passwords must match'
      });
  
      return;
    }

    if (isNew) {
      dispatchAddAdmin({
        username,
        password,
        passwordConfirm,
        isSuperAdmin
      });
    } else {
      dispatchEditAdmin({
        id,
        username,
        password,
        passwordConfirm,
        isSuperAdmin
      });
    }
  };

  return (
    <button
      className='ag-button'
      onClick={onClick}
      style={{color: '#00f', textDecoration: 'underline'}}
      // style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
    >
      {isNew ? 'Add' : 'Save'}
    </button>
  );
};

export default DeleteAdminRenderer;