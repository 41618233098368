import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

export type Response = {
  status: 'SUCCESS' | 'FAILURE';
};

const resendSubAccountInvitePort = '/resendSubInvite';

/**Resends an invitation that finishes sub-account setup. */
const resendSubAccountInviteAPI =
async (subAccountId: number, email: string, isResettingPassword = false): Promise<AxiosResponse<Response>> => {
  const res = await api.get(resendSubAccountInvitePort, {
    params: {
      subID: subAccountId,
      email: email,
      resetP: isResettingPassword ? 1 : undefined
    }
  });

  if (isDebugging) {
    console.log(`${resendSubAccountInvitePort} response:`, res);//TESTING
  }

  return res;
};

export default resendSubAccountInviteAPI;