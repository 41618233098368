import {useStore} from '../../../store';
import {isDebugging} from '../../../utility';
import ReCAPTCHA from 'react-google-recaptcha';
import {RefObject} from 'react';

const handleReCAPTCHA = async (recaptchaRef?: RefObject<ReCAPTCHA>) => {
  const dispatchSetAlert = useStore.getState().dispatchSetAlert;

  try {
    if (recaptchaRef === undefined || recaptchaRef.current === null) return;
    recaptchaRef.current.reset();

    console.log('recaptcha_before:', recaptchaRef);//TESTING

    const token = await recaptchaRef.current.executeAsync();
    console.log('token:', token);//TESTING
    if (token === null) throw new Error('token is null');
    
    return token;
    
  } catch (err: any) {
    // if (isDebugging) {
      console.log('token failure:', recaptchaRef);
      console.log('err: ', err);
    // }
    dispatchSetAlert({
      status: 'warning',
      header: 'Token Failure',
      text: 'Unable to validate request, please try again later'
    });
  }
};

export default handleReCAPTCHA;