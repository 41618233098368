import handleReCAPTCHA from '../../../utility/google/handleReCAPTCHA';
import {useRecaptchaContext} from '../../../context/recaptcha';
import {useStore} from '../../../../store';
import {giveFeedbackAPI} from '../../../../api';
import {useState, ChangeEvent, FormEvent} from 'react';

const FeedbackController = () => {
  const account = useStore((state) => state.account);
  const dispatchGetAccountDetails = useStore((state) => state.dispatchGetAccountDetails);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    facilityName: '',
    comments: ''
  });
  const [isWantingFollowUp, setIsWantingFollowUp] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [submitButtonAttributes, setSubmitButtonAttributes] = useState({
    disabled: false,
    text: 'Submit'
  });
  const recaptchaRef = useRecaptchaContext();

  const init = dispatchGetAccountDetails;

  const updateAccountInfo = () => {
    if (!account || account.roleId !== 3) return;

    setFormData({
      ...formData,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email || '',
      phoneNumber: account.phoneNumber || '',
      facilityName: account.company?.name || ''
    });
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const token = await handleReCAPTCHA(recaptchaRef);
    if (!token) return;

    const request = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      facName: formData.facilityName,
      comment: formData.comments,
      requestFollowup: isWantingFollowUp ? 'Yes' : 'No'
    };

    giveFeedbackAPI(request, token).then((res) => {
      if (res.status === 200)  {
        dispatchSetAlert({
          status: 'success',
          header: 'Success',
          text: 'Thank you for your feedback!'
        });

        setSubmitButtonAttributes({
          disabled: true,
          text: 'Message Sent'
        });
      } else {
        dispatchSetAlert({
          status: 'danger',
          header: 'Failure',
          text: 'Oops, there was a problem submitting your feedback'
        });
      }

      setFormData({
        ...formData,
        comments: ''
      });

      setIsWantingFollowUp(false);
    })
  };

  return {
    account,
    updateAccountInfo,
    init,
    formData,
    onChangeInput,
    onSubmit,
    isWantingFollowUp,
    setIsWantingFollowUp,
    submitButtonAttributes
  }
};

export default FeedbackController;