import {ReactComponent as TrashIcon} from '../../media/icon-trash.svg';
import {useStore} from '../../../store';
import React, {MouseEvent} from 'react';
import {Link} from 'react-router-dom';

type Props = {
  value: number;
  data: {
    id: number;
    productId: number;
  };
};

const DeleteDeptRenderer = (props: Props) => {
  const dispatchDeleteDept = useStore((state) => state.dispatchDeleteDept);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const isConfirmed = window.confirm(`Are you sure you'd like to delete this department?`);

    if (!isConfirmed) return;

    dispatchDeleteDept(props.data.id, props.data.productId);
  };

  return (
    <button className='btn' onClick={onClick} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
      <TrashIcon style={{width: '2rem'}}/>
    </button>
  );
};

export default DeleteDeptRenderer;