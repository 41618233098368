import {State} from '../..';
import Breadcrumbs from '../entities/Breadcrumbs';
import {SetState, GetState} from 'zustand';

const createBreadcrumbs = (set: SetState<State>, get: GetState<State>) =>
(breadcrumbs: Breadcrumbs) => {

  set(() => ({
    breadcrumbs: breadcrumbs
  }));

  localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
};

export default createBreadcrumbs;