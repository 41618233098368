import Cart from './Cart';

type Checkout = {
  isRetrieving: boolean;
  isProcessing: boolean;
  isFinalized: boolean;
  isPayingWithCard?: boolean;
  specialShipping?: boolean;
  invoiceId?: number;
  carts: Array<Cart>;
};

export const defaultCheckout = {
  isRetrieving: false,
  isFinalized: false,
  isProcessing: false,
  carts: []
};

export default Checkout;