import SearchbarController from './SearchbarController';
import {ReactComponent as SearchIcon} from '../../../media/icon-search.svg';
import {TextField} from '@mui/material';

const SearchbarView = (props: {className?: string;}) => {
  const {
    searchValue,
    onChangeInput,
    onSubmit    
  } = SearchbarController();

  // useEffect(() => {
  //   //This prevents every keydown after enter from triggering another search
  //   if (searching === true) {
  //     setSearchValue('');
  //     setSearching(false);
  //   }
  // }, [searching]);

  return (
    <form
      className={'searchbar ' + props.className || ''}
      onSubmit={onSubmit}
    >
      <TextField
        value={searchValue}
        onChange={onChangeInput}
      />
      <button className='btn' type='submit'>
        <SearchIcon />
      </button>
    </form>
  )
};

export default SearchbarView;
