import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {getResidentListAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

const getResidentList = (set: SetState<State>, get: GetState<State>) => async () => {
  const account = get().account;

  try {
    // if (!account) throw new Error('there was no account to retrieve residents from');
    if (!account || account.roleId !== 3) return;

    const userId = account.userId;
    const selectedResident = localStorage.getItem('selectedResident');
    
    const res = await getResidentListAPI(Number(userId));
    const residents = res.data.map((resident) => ({
      id: resident.residentID,
      userId: resident.userID,
      firstName: resident.fName,
      lastName: resident.lName,
      clothingSize: resident.clothing_size,
      shoeSize: resident.shoe_size,
      budget: Number(resident.budget_amount),
      sex: resident.sex
    }));

    if (isDebugging) {
      console.log('residents modified in store:', residents);//TESTING
    }

    set(produce((state) => {
      state.account = {
        ...state.account,
        residents: residents
      }
    }));

    // set((state) => ({
    //   account: {
    //     ...state.account,
    //     residents: residents
    //   }
    // }));

    if (
      residents &&
      residents.length > 0 &&
      selectedResident
    ) {
      set({
        selectedResident: residents.find((resident) => resident.id === Number(selectedResident))
      })
    } else if (
      residents &&
      residents.length > 0
    ) {
      set({
        selectedResident: residents[0]
      })
    }

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('get resident list store error:', err);//TESTING
    }
		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err.response.data.message || 'Failed to retrieve residents'
		});    
  }
};

export default getResidentList;