import React, {Dispatch, SetStateAction} from 'react'

type Props = {
  wantsNewsletter: boolean;
  setWantsNewsletter: Dispatch<SetStateAction<boolean>>;
};

const NewsletterCheckbox = (props: Props) => {
  const {
    wantsNewsletter,
    setWantsNewsletter
  } = props;

  const onChangeCheckbox = () => setWantsNewsletter(!wantsNewsletter)

  return (
    <div className='register__newsletter'>
      <span className='switch'>
        <input
          type='checkbox'
          checked={wantsNewsletter}
          onChange={onChangeCheckbox}
          id='newsletter-checkbox'
          className='switch__checkbox'
          />
        <label htmlFor='newsletter-checkbox' className='switch__label' />
      </span>
      Would you like to sign up to our newsletter?
    </div>
  );
};

export default NewsletterCheckbox;