import logout from './logout';
import getAccountDetails from './getAccountDetails';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {createGuestAccountAPI} from '../../../api';
import {authService, isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';

export const createGuest = (set: SetState<State>, get: GetState<State>) =>
	async () => {
		const accountInStore = get().account;

		try {
			if (accountInStore) {
				logout(set);
				
				throw new Error();
			}

			const res = await createGuestAccountAPI();
    	const {userID, roleID, userToken, firstName, lastName, username} = res.data;

			authService.token.set(userToken);
			authService.credentials.set(username, 'tempAccount#1');

      set({
				isAuthenticated: true,
        account: {
          userId: userID,
          roleId: Number(roleID),
          firstName: firstName,
					lastName: lastName
        }
      });

			await getAccountDetails(set, get)();

		} catch (err: any | unknown) {
			if (isDebugging) {
				console.log('create guest account error:', err);//TESTING
			}
			setAlert(set, get)({
				status: 'danger',
				header: 'Error',
				text: err.response.data.message || 'Failed to create guest account'
			});
		}
	};

export default createGuest;