import bannerLarge from '../../../media/ReturnsExchangeBannerL.png';
import bannerMedium from '../../../media/ReturnsExchangeBannerM.png';
import bannerSmall from '../../../media/ReturnsExchangeBannerS.png';
import React, {useState, useEffect} from 'react';

const ReturnsController = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bannerImage, setBannerImage] = useState(
    windowWidth > 1000 ?
    bannerLarge :
    windowWidth > 700 ?
    bannerMedium :
    bannerSmall
  );

  const init = () => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  };

  const onWidthEvent = () => setBannerImage(
    windowWidth > 1000 ?
    bannerLarge :
    windowWidth > 700 ?
    bannerMedium :
    bannerSmall
  );

  const onClickQuestion = (id: string) => {
    const answer = document.querySelector(`#${id}`);

    if (!answer) return;

    answer.classList.toggle('return-policy__text-block--hidden');
  };

  return {
    init,
    bannerImage,
    windowWidth,
    onWidthEvent,
    onClickQuestion
  };
};

export default ReturnsController;