import getFacilityInvoices from './getFacilityInvoices';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {editFacilityInvoiceAPI} from 'application/api';
import {SetState, GetState} from 'zustand';

export const editFacilityInvoice = (set: SetState<State>, get: GetState<State>) =>
	async (userId: string, invoiceId: string, facilityId: string, isProcessed: boolean, isPaid: boolean) => {

		const req = {
			userId: userId,
			invoiceId: invoiceId,
			facilityId: facilityId,
			isProcessed: isProcessed,
			isPaid: isPaid
		};
		
		const res = await editFacilityInvoiceAPI(req);
		
		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully updated invoice'
		});

		getFacilityInvoices(set, get)();
	};

export default editFacilityInvoice;