import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import {AxiosResponse} from 'axios';

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const validateTokenPort = '/recoverAccount';

const validateTokenAPI = async (token: string): Promise<AxiosResponse<Response>> => {
  const res = await api.get(validateTokenPort, {params: {rtype: 'checkCode', reqcode: token}});

  if (isDebugging) {
    console.log(`${validateTokenPort} response:`, res);//TESTING
  }
  
  return res;
};

export default validateTokenAPI;