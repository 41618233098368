import ServerError from 'application/api/utility/errors/ServerError';
import {authService, ErrorConfig, isDebugging} from 'application/utility';
import axios from 'axios';

export const baseURL = 'https://ssswebaf.azurewebsites.net/api';

/**
 * Axios instance for the back-end server.
 * @remark Includes the root url and required request headers.
 */
const api = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': authService.token.get()
	}
});

/**
 * Handles any errors originating from the server.
 * @param err 
 * @param path API path attempted
 */
const handleServerError = (err: any | unknown, path: string) => {
	const errorConfig: ErrorConfig = {
		log: isDebugging,
		display: true,
	};

	const errorMessage = 
	err.response.data.message ||
	'There was an issue on our server, please try again later.';

	new ServerError(
		err,
		errorMessage,
		path,
		err.status,
		errorConfig
	);
};

/**
 * Generic function that makes a "get" request to the server API.
 * @param path API path substring
 * @param config Optional request config
 * @returns Promised response
 */
export const get = async <Response>(path: string, config?: any, instance = api): Promise<Response | null> => {

	try {
		const res = await instance.get(path, config);
		return res.data;
		
	} catch (err: any) {
		handleServerError(err, path);
		return null;
	}
};

/**
 * Generic function that makes a "post" request to the server API.
 * @param path API path substring
 * @param req Request body
 * @returns Promised response
 */
export const post = async <Response>(path: string, req: any, instance = api): Promise<Response | null> => {

	try {
		const res = await instance.post(path, req);
		return res.data;
		
	} catch (err: any) {
		handleServerError(err, path);
		return null;
	}
};

export default api;