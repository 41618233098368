import {post} from 'application/api/baseApi';

type Request = {
  prod_id: string;
  prod_groupID: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyProductSub';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const deleteSubProductAPI = (req: Request) => post<Response>(
  path,
  {
    ...req,
    prod_itemID: '',
    prod_name: '',
    prod_size: '',
    prod_desc: '',
    prod_price: '',
    prod_shipping: ''
  }
);

export default deleteSubProductAPI;