import FacFormsController from './FacFormsController';
import AccountNav from '../../navigation/account/AccountNavView';
import pdfImage from '../../../media/PDF.png';
import companyInfo from '../../../utility/companyInfo';
import {Link} from 'react-router-dom';

const FacFormsView = () => {
  const {account, location} = FacFormsController();
  const forms = [
    {
      heading: 'Pre-Order Shopping List',
      desc: `This is a helpful tool when shopping for multiple residents.\n
        1. Print or make copies of this Resident Requet Check-off List.\n
        2. Write the Residents' names on the forms.\n
        3. Pass out to nurses, aids, etc. to get list of needs for the residents.\n
        4. Then collect the lists at a later time.\n
        5. Then you can call, mail, fax, or shop here online to put in your orders.`,
      pdf: 'ResidentRequestList'
    },
    {
      heading: 'Catalog Order List',
      desc: `In case you would like to print out our Catalog Order Form, we have enclosed the link here.`,
      pdf: 'CatalogOrderForm'
    },
    {
      heading: 'Return/Exchange Form',
      desc: `If you need to return or exchange any merchandise, please print out this form and return it to us.`,
      pdf: 'ReturnsExchangeForm'
    }
  ];


  return (
    <section className='facility-forms'>
    {/* <section className={`facility-forms ${account && account.roleId === 3 ? 'facility-forms--facility' : ''}`}> */}
      {/* {
        account &&
        account.roleId === 3 &&
        <AccountNav currentPath={location.pathname} />
      } */}
      <div className='facility-forms__main'>
        <h1>Facility Forms</h1>
        <a
          className='facility-forms__link'
          href={`${document.location.origin}/pdfs/${forms[0].pdf}.pdf`}
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={pdfImage} alt='PDF'/>
        </a>
        <div className='facility-forms__form'>
          <h3>Pre-Order Shopping List</h3>
          <p>
            This form or list is a helpful tool when shopping for multiple residents. It is ideal for office staff to print out and pass it off to housekeeping or nursing aids, and ask them to fill in the needs for specific residents.
          </p>
          <div className='facility-forms__list'>
              <p>1)</p>
              <p>
                Print and make copies of the Pre-Order Shopping List.
              </p>
              <p>2)</p>
              <p>
                Write the resident's name on the forms.
              </p>
              <p>3)</p>
              <p>
              Pass out to nurses, aids, etc. to get list of needs for the residents.
              </p>
              <p>4)</p>
              <p>
                Collect the lists at a later time/date.
              </p>
              <p>5)</p>
              <p>
              Use the list as a guideline to help you navigate your shopping with us online.
              </p>
          </div>
        </div>
        <a
          className='facility-forms__link'
          href={`${document.location.origin}/pdfs/${forms[1].pdf}.pdf`}
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={pdfImage} alt='PDF'/>
        </a>
        <div className='facility-forms__form'>
          <h3>Catalog Order Form</h3>
          <p>
            Each of our physical catalogs have this order form available.
            This form is great when you need to place several orders for
            several residents. Just print out this form for each of the
            residents you need to shop for.
          </p>
          <div className='facility-forms__list'>
            <p>1)</p>
            <p>
              Fax forms to: (765) 378-7758
            </p>
            <p>2)</p>
            <p>
              Mail forms to: Senior Shopping Service, 319 Anderson Rd.,
              Chesterfield, IN 46017
            </p>
            <p>3)</p>
            <p>
              Scan and email to: <a href={`mailto:${companyInfo.email.customerService}`}>
                {companyInfo.email.customerService}
              </a>
            </p>
            <p>4)</p>
            <p>
              Or just use these forms to call in your order, and use
              to shop here online.
            </p>
          </div>
        </div>
        <a
          className='facility-forms__link'
          href={`${document.location.origin}/pdfs/${forms[2].pdf}.pdf`}
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={pdfImage} alt='PDF'/>
        </a>
        <div className='facility-forms__form'>
          <h3>Returns/Exchange Form</h3>
          <p>
            This is the form you will need to include with any return you
            send back to Senior Shopping Service. Please check out
            our <Link to='/info/returnPolicy'>Returns/Exchange page</Link>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FacFormsView;
