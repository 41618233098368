import React from 'react';
import {Link} from 'react-router-dom';

type Props = {
  value: number;
  data: {
    id: number;
  }
};

const ResidentCellRenderer = (props: Props) => <Link to={`/admin/lookup/facility/${props.data.id}`}>
  {props.value}
</Link>;

export default ResidentCellRenderer;