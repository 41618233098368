import ProductDetailController from './ProductDetailController';
import AdminNav from '../AdminNav';
import Breadcrumbs from '../../navigation/breadcrumbs/BreadcrumbsView';
import Draft from '../../../utility/draftjs/Draft';
import {ReactComponent as LoadingIcon} from '../../../media/icon-loading.svg';
import React, {useState, useEffect} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} from '@mui/material';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

/**
 * Page that details a single product
 * @remark Preferrably this would be refactored to look more like the other admin components, but this will be time consuming.
 * @returns Page component
*/
const ProductDetail = () => {
 const {
  storeProductInfo,
  location,
  isLoading,
  productInfo,
  isNew,
  gridRefSubProds,
  gridRefDepts,
  columnDefsSubProds,
  columnDefsDepts,
  defaultColDef,
  rowDataSubProds,
  rowDataDepts,
  subProductInfo,
  deptMatrix,
  selectedDept,
  selectedSubDept,
  mainImage,
  thumbnails,
  init,
  productInfoListener,
  onSubProductValueChanged,
  onFirstDataRendered,
  onSubmitProductInfo,
  onSubmitNewSubProduct,
  onSubmitNewDept,
  onChangeProductInfo,
  onChangeSubProductInfo,
  onChangeSex,
  onChangeDept,
  onChangeSubDept,
  onChangeSuperSubDept,
  toggleIsTaxExempt,
  toggleIsHidden,
  onDeleteProduct,
  onBrokenImage,
  setProdDesc//TEMP
 } = ProductDetailController();

 const getDeptDropdowns = () => deptMatrix ? <>
  <FormControl>
    <InputLabel id='dept-label'>Dept</InputLabel>
    <Select
      label='Dept'
      labelId='dept-label'
      value={selectedDept?.id}
      onChange={onChangeDept}
      required
    >
      {
        deptMatrix.map((dept, index) =>
          <MenuItem value={dept.id} key={index}>{dept.name}</MenuItem>
        )
      }
    </Select>
  </FormControl>
  <FormControl>
    <InputLabel id='subdept-label'>Sub Dept</InputLabel>
    <Select
      label='Sub Dept'
      labelId='subdept-label'
      value={selectedSubDept?.id}
      onChange={onChangeSubDept}
      disabled={!selectedDept}
    >
      {
        selectedDept?.subDepts.map((subDept, index) =>
          <MenuItem value={subDept.id} key={index}>{subDept.name}</MenuItem>
        )
      }
    </Select>
  </FormControl>
  <FormControl>
    <InputLabel id='supersubdept-label'>Super Sub Dept</InputLabel>
    <Select
      label='Super Sub Dept'
      labelId='supersubdept-label'
      value={selectedSubDept?.id}
      onChange={onChangeSuperSubDept}
      disabled={!selectedSubDept || !selectedDept}
    >
      {
        selectedSubDept?.superSubDepts.map((superSubDept, index) =>
          <MenuItem value={superSubDept.id} key={index}>{superSubDept.name}</MenuItem>
        )
      }
    </Select>
  </FormControl>
 </> :
 <></>;
  
  useEffect(() => {init()}, []);
  
  useEffect(productInfoListener, [storeProductInfo]);

  return (
    <div className='a-prod-detail'>
      {
        isLoading ?
        <LoadingIcon /> :
        <>
          <form className='a-prod-detail__info' onSubmit={onSubmitProductInfo}>
            {
              isNew ?
              <h3>New Product</h3> :
              <div className='a-prod-detail__display'>
                <img src={mainImage} onError={onBrokenImage} alt='product'/>
                <span className='a-prod-detail__thumbnails'>
                  {thumbnails.map((thumbnail) => thumbnail)}
                </span>
              </div>
            }
            <TextField
              label='Name'
              name='name'
              value={productInfo.name}
              onChange={onChangeProductInfo}
              required
            />
            <TextField
              label='Group ID'
              name='groupId'
              value={productInfo.groupId}
              onChange={onChangeProductInfo}
              disabled={!isNew}
            />
            <TextField
              label='Item ID'
              name='itemId'
              value={productInfo.itemId}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Clothing Type'
              name='clothingType'
              value={productInfo.clothingType}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Needs'
              name='needs'
              value={productInfo.needs}
              onChange={onChangeProductInfo}
            />
            <FormControl>
              <InputLabel id='sex-label'>Gender</InputLabel>
              <Select
                label='Gender'
                labelId='sex-label'
                value={productInfo.sex}
                onChange={onChangeSex}
              >
                <MenuItem value='B'>Both</MenuItem>
                <MenuItem value='F'>Female</MenuItem>
                <MenuItem value='M'>Male</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label='Color/Style Options'
              name='options'
              value={productInfo.options}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Sched. Delivery Item ID'
              name='scheduledDeliveryId'
              value={productInfo.scheduledDeliveryId}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Sched. Delivery Options'
              name='scheduledDeliveryOptions'
              value={productInfo.scheduledDeliveryOptions}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Price Range'
              name='priceRange'
              value={productInfo.priceRange}
              onChange={onChangeProductInfo}
              required
            />
            <TextField
              label='Extra Shipping Charge'
              name='shippingCharge'
              value={productInfo.shippingCharge}
              onChange={onChangeProductInfo}
            />
            <TextField
              label='Image Count'
              type='number'
              name='imageCount'
              value={productInfo.imageCount}
              onChange={onChangeProductInfo}
            />
            <span>
              <Checkbox
                checked={productInfo.isTaxExempt}
                onChange={toggleIsTaxExempt}
              />
              Tax Exempt
            </span>
            <span>
              <Checkbox
                checked={productInfo.isHidden}
                onChange={toggleIsHidden}
              />
              Hidden from Website
            </span>
            <Draft
              className='a-prod-detail__desc'
              placeholder='Description'
              startingHTML={storeProductInfo?.desc || ''}
              setCurrentHTML={setProdDesc}
            />
            {isNew && getDeptDropdowns()}
            <input
              className='btn btn--primary'
              type='submit'
              value={isNew ? 'Add Product' : 'Save Product Info'}
            />
          </form>
          {
            !isNew &&
            <>
              <div>
                <h3>Departments</h3>
                <div className='ag-theme-alpine'>
                  <AgGridReact
                    ref={gridRefDepts}
                    columnDefs={columnDefsDepts}
                    defaultColDef={defaultColDef}
                    rowData={rowDataDepts}
                    onFirstDataRendered={() => onFirstDataRendered('dept')}
                    pagination={true}
                    paginationPageSize={5}
                    domLayout='autoHeight'
                    />
                </div>
                {
                  deptMatrix &&
                  <form
                    className='a-prod-detail__depts'
                    onSubmit={onSubmitNewDept}
                  >
                    <h5>Add Dept</h5>
                    {getDeptDropdowns()}
                    <input
                      className='btn btn--primary'
                      type='submit'
                      value='Add Dept'
                    />
                  </form>
                }
              </div>
              <div>
                <h3>Sub Products</h3>
                <div className='ag-theme-alpine'>
                  <AgGridReact
                    ref={gridRefSubProds}
                    columnDefs={columnDefsSubProds}
                    defaultColDef={defaultColDef}
                    rowData={rowDataSubProds}
                    onCellValueChanged={onSubProductValueChanged}
                    onFirstDataRendered={() => onFirstDataRendered('sub')}
                    pagination={true}
                    paginationPageSize={5}
                    domLayout='autoHeight'
                    />
                </div>
                <form className='a-prod-detail__sub-prods' onSubmit={onSubmitNewSubProduct}>
                  <h5>Add Sub-Product</h5>
                  <TextField
                    label='Item ID'
                    name='itemId'
                    value={subProductInfo.itemId}
                    onChange={onChangeSubProductInfo}
                    required
                  />
                  <TextField
                    label='Name'
                    name='name'
                    value={subProductInfo.name}
                    onChange={onChangeSubProductInfo}
                    required
                  />
                  <TextField
                    label='Size'
                    name='size'
                    value={subProductInfo.size}
                    onChange={onChangeSubProductInfo}
                  />
                  <TextField
                    label='Price'
                    name='price'
                    value={subProductInfo.price}
                    onChange={onChangeSubProductInfo}
                    required
                  />
                  <input className='btn btn--primary' type='submit' value='Add Sub-Product' />
                </form>
              </div>
              <div>
                <button
                  className='btn btn--danger a-prod-detail__delete'
                  onClick={onDeleteProduct}
                >
                  Delete Product
                </button>
              </div>
            </>
          }
        </>
      }
    </div>
  );
};

export default ProductDetail;