import InvoiceCellRenderer from '../../utility/agGrid/InvoiceCellRenderer';
import CheckboxRenderer from '../../utility/agGrid/CheckboxRenderer';
import {useStore} from '../../../store';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import React, {useState, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';

type Params = {
  userId: string;
};

type IndInvoice = {
  id: number;
  invoiceId: number;
  date: string;
  firstName: string;
  lastName: string;
  total: string;
  isProcessed: boolean;
};

/**
 * Custom hook to handle individual invoice state and actions
 * @returns invoice list state and an action to edit them
 */
const useIndividualInvoices = (userId?: string) => {
  const invoices = useStore((state) => state.invoices.individual);
  const dispatchGetIndividualInvoices = useStore((state) => state.dispatchGetIndividualInvoices);
  const dispatchEditIndividualInvoice = useStore((state) => state.dispatchEditIndividualInvoice);
  const [curInvoices, setCurInvoices] = useState<Array<IndInvoice>>([]);
  
  useEffect(() => {
    dispatchGetIndividualInvoices(userId);
  }, []);
  
  useEffect(() => {
    setCurInvoices(invoices.map((inv) => ({
      id: inv.user.id,
      invoiceId: inv.id,
      date: inv.date,
      firstName: inv.user.firstName,
      lastName: inv.user.lastName,
      total: inv.totalCost,
      isProcessed: inv.isProcessed
    })));
  }, [invoices]);
  
  return {curInvoices, dispatchEditIndividualInvoice};
};

/**
 * Page that lists individual invoices
 * @returns Page component
*/
const InvoicesIndividual = () => {
  const {userId} = useParams<Params>();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 3,
      name: 'Individual Invoices',
      path: '/admin/invoices/individual'
    }
  ]);
  const {curInvoices, dispatchEditIndividualInvoice} = useIndividualInvoices(userId);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {field: 'invoiceId', headerName: 'Invoice ID', filter: 'agNumberColumnFilter', cellRenderer: InvoiceCellRenderer},
    {field: 'date', headerName: 'Date', filter: 'agDateColumnFilter'},
    {field: 'firstName', headerName: 'First Name', filter: 'agTextColumnFilter'},
    {field: 'lastName', headerName: 'Last Name', filter: 'agTextColumnFilter'},
    {field: 'total', headerName: 'Total', filter: 'agNumberColumnFilter'},
    {field: 'isProcessed', headerName: 'Processed', cellRenderer: CheckboxRenderer}
  ];
  
  const onCellValueChanged = (e: any) => {
    const invoice: IndInvoice = e.data;

    dispatchEditIndividualInvoice(
      invoice.id.toString(),
      invoice.invoiceId.toString(),
      invoice.isProcessed
    );
  };
  

  return (
    <section className='a-table'>
      <DataGrid
        rowData={curInvoices}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onCellValueChanged={onCellValueChanged}
      />
    </section>
  );
};

export default InvoicesIndividual;