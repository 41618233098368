import {post} from 'application/api/baseApi';

type Request = {
  prod_id: string;
  prod_groupID: string;
  prod_itemID: string;
  prod_name: string;
  prod_desc: string;
  prod_price: string;
  prod_dept: string;
  prod_subDept: string;
  prod_superSubDept: string;
  prod_clothingType?: string;
  prod_nurseTip?: string;
  prod_color?: string;
  prod_shipping: string;
  prod_picLG?: string;
  prod_picAlt?: string;
  prod_picMain?: string;
  prod_picSugg?: string;
  prod_picThumb?: string;
  prod_picThumbA?: string;
  sched_delivery_ID?: string;
  sched_delivery_options?: string;
  taxExempt: '1' | '0';
  MFB?: 'M' | 'F' | 'B',
  imageCount: string;
  hide: '1' | '0';
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = '/modifyProductMain';

/**
 * Middleware for {@link post | the generic API "post" function}.
 * @remark Adds type restrictions and the path parameter.
 * @param req Request body
 * @returns Expected response or null
 */
const editProductAPI = (req: Request) => post<Response>(path, req);

export default editProductAPI;