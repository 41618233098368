import {getRewardsInfoAPI} from '../../../api';
import {useStore} from '../../../store';
import {ReactComponent as LoadingIcon} from '../../media/icon-loading.svg';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const RewardsInfo = () => {
  const account = useStore((state) => state.account);
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const [rewardsInfo, setRewardsInfo] = useState({
    policy: '',
    amount: '',
    expDate: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const init = async () => {
    setIsLoading(true);

    try {
      if (!account) throw new Error();

      const res = await getRewardsInfoAPI(account.userId);
  
      setRewardsInfo({
        policy: res.data.policy,
        amount: res.data.RewardAmount.toString(),
        expDate: res.data.RewardExpirationDate
      });
  
      
    } catch (err: any) {
      dispatchSetAlert({
        status: 'danger',
        header: 'Error',
        text: 'There was a problem connecting to the server'
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (account?.roleId !== 3) return history.push('/');
  }, [account]);

  useEffect(() => { 
    init();
  }, []);

  if (isLoading) return (
    <section className='rewards-info'>
      <LoadingIcon />
    </section>
  );

  return (
    <section className='rewards-info'>
      <h1>Rewards</h1>
      <div className='rewards-info__points'>
        <span>
          <h1>{rewardsInfo.amount}</h1>
          <h4>Available Reward Dollars</h4>
        </span>
        <p>Expires on: {rewardsInfo.expDate}</p>
      </div>
      <p className='rewards-info__policy'>{rewardsInfo.policy}</p>
    </section>
  );
};

export default RewardsInfo;