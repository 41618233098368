import CheckboxRenderer from '../../utility/agGrid/CheckboxRenderer';
import DeleteCarouselRenderer from '../../utility/agGrid/DeleteCarouselRenderer';
import {useStore, LandingCarousel} from '../../../store';
import {LooseObject} from 'application/utility/entities/LooseObject';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import {AgGridReact} from 'ag-grid-react';
import React, {useState, useEffect, useCallback, Dispatch, SetStateAction, useRef, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {RowDragMoveEvent, ColDef, GridOptions, GetRowIdParams, GridReadyEvent, RowDragEndEvent} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getLandingCarouselTESTAPI, editLandingCarouselTESTAPI, editLandingCarouselTESTReq } from 'application/api';

export const defaultCarousel: LandingCarousel = {
  id: Math.floor(Math.random() * 9999999),
  image: 'Enter image file',
  link: 'Enter path',
  headerText: 'Enter header',
  subHeaderText: 'Enter subheader',
  buttonText: 'Enter button text',
  isHidden: false,
  orderNum: 0
};

/**
 * Custom hook to handle carousel state and actions
 * @returns carousels from the back-end, with one default carousel
 */
const useCarousels = () => {
  const storeCarousels = useStore((state) => state.carousels.landing);
  const dispatchGetLandingCarousel = useStore((state) => state.dispatchGetLandingCarousel);
  const dispatchEditLandingCarousel = useStore((state) => state.dispatchEditLandingCarousel)
  const [curCarousels, setCurCarousels] = useState<Array<LandingCarousel>>([]);

  const submitCarouselChanges = async (newArray: Array<LandingCarousel>) => {
    const apiReadyCarousels: editLandingCarouselTESTReq = newArray.map((c) => ({
      id: c.id,
      image: c.image,
      link: c.link,
      hide: c.isHidden ? 1 : 0,
      button_text: c.buttonText,
      headline: c.headerText,
      subhead: c.subHeaderText,
      orderNum: c.orderNum
    }));
    console.log('apiReadyCarousels', apiReadyCarousels);
    
    dispatchEditLandingCarousel(newArray);
  };
  
  useEffect(() => {
    dispatchGetLandingCarousel(true);
  }, []);
  
  useEffect(() => {
    // const modifiedCarousels: Array<LandingCarousel> =
    // storeCarousels.map((item) => ({
    //   ...item,
    //   isNew: false,
    // }));
  
    // modifiedCarousels.unshift({
    //   ...defaultCarousel
    // });
  
    setCurCarousels(storeCarousels);
  
  }, [storeCarousels]);

  return {curCarousels, setCurCarousels, submitCarouselChanges};
};

/**
 * Abstracted component to handle carousel grid functionality
 * @returns custom grid for carousels
 */
const CarouselGrid = (props: {
  curCarousels: LandingCarousel[],
  setCurCarousels: Dispatch<SetStateAction<LandingCarousel[]>>,
  submit: (newArray: Array<LandingCarousel>) => Promise<void>
}) => {
  const storeCarousels = useStore((state) => state.carousels.landing);
  const dispatchUpdateCarouselOrder = useStore((state) => state.dispatchUpdateCarouselOrder);
  const dispatchGetLandingCarousel = useStore((state) => state.dispatchGetLandingCarousel);
  const { curCarousels, setCurCarousels, submit } = props;
  const gridRef: any = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const defaultColDef = {
    resizable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {
      field: 'image',
      headerName: 'Image',
      editable: true,
      rowDrag: true
    },
    {
      field: 'link',
      headerName: 'Path',
      editable: true,
      resizable: true
    },
    {
      field: 'headerText',
      headerName: 'Header',
      editable: true
    },
    {
      field: 'subHeaderText',
      headerName: 'Subheader',
      editable: true
    },
    {
      field: 'buttonText',
      headerName: 'Button',
      editable: true
    },
    {
      field: 'isNew',
      hide: true
    },
    {
      field: 'isHidden',
      headerName: 'Hidden',
      cellRenderer: CheckboxRenderer,
      minWidth: 50,
      maxWidth: 100
    },
    {
      field: 'delete',
      headerName: 'Delete',
      cellRenderer: (props: any) => DeleteCarouselRenderer(props, curCarousels, setCurCarousels),
      minWidth: 50,
      maxWidth: 100
    },
    // {
    //   field: 'submit',
    //   headerName: 'Action',
    //   cellRenderer: UpdateCarouselRenderer,
    //   minWidth: 50,
    //   maxWidth: 100
    // }
  ];
  
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const addCarousel = useCallback(() => {
    let newCarouselList = [...curCarousels];
    newCarouselList.unshift(defaultCarousel)

    setCurCarousels(newCarouselList)

  }, [curCarousels]);

  const deleteCarousel = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();
    console.log('selectedRowNodes', selectedRowNodes);
    
    const selectedIds = selectedRowNodes.map(function (rowNode: any) {
        return rowNode.id;
    });
    const filteredData = curCarousels.filter(function (c) {
        return !selectedIds.includes(c.id);
    });
    setCurCarousels(filteredData);
}, [curCarousels]);

  const moveInArray = (arr: any[], fromIndex: number, toIndex: number) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  const onRowDragMove = useCallback(
    (event: RowDragMoveEvent) => {
      if (!curCarousels) throw new Error("No row data was found on grid");
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove && overNode !== undefined) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode!.data;
        const fromIndex = curCarousels.indexOf(movingData);
        const toIndex = curCarousels.indexOf(overData);
        const newCarousels = curCarousels.slice();
        moveInArray(newCarousels, fromIndex, toIndex);
        console.log("CAROUSELS", newCarousels);
        setCurCarousels(newCarousels);
        gridRef.current!.api.setRowData(newCarousels);
        gridRef.current!.api.clearFocusedCell();
      }
    },
    [curCarousels]
  );

  const onRowDragEnd = useCallback(
    (event: RowDragEndEvent) => {
      /* The following creates a new array of carousels from
        the order of the current carousels with the content of the state carousels.
        This makes sure we don't accidentally update the back-end with unsubmitted content changes
      */
      let idToOrderNum:LooseObject<number> = {};
      let reOrderedCarousels: any[] = [];

      curCarousels.forEach((carousel, index) => {
        idToOrderNum[carousel.id] = index;
      });

      storeCarousels.forEach((carousel) => {
        reOrderedCarousels.push({
          id: carousel.id,
          image: carousel.image,
          link: carousel.link.replace("'", "''"),
          button_text: carousel.buttonText.replace("'", "''"),
          headline: carousel.headerText.replace("'", "''"),
          subhead: carousel.subHeaderText.replace("'", "''"),
          hide: carousel.isHidden ? 1 : 0,
          orderNum: idToOrderNum[carousel.id]
        })
      });

      reOrderedCarousels.sort((a, b) => a.orderNum - b.orderNum);

      editLandingCarouselTESTAPI(reOrderedCarousels);

      dispatchUpdateCarouselOrder(reOrderedCarousels)

      // const updatedCarousels = curCarousels.map((carousel, index) => ({...carousel, orderNum: index}))
      // console.log("ROW DRAG END", updatedCarousels);
      // gridRef.current!.api.showLoadingOverlay();
    },
    [curCarousels]
  )

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // add id to each item, needed for immutable store to work
    const carouselsWithOrderNum = curCarousels.map((carousel, index) => ({...carousel, id: index}))
    setCurCarousels(carouselsWithOrderNum);
    params.api.setRowData(curCarousels);
  }, []);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.id;
  }, []);

  const test = async () => getLandingCarouselTESTAPI()

  useEffect(() => {
    test()
  }, []);

  useEffect(() => console.log('curCarousels', gridRef?.current?.api?.getRenderedNodes()), [curCarousels])

  return (
    <div style={{display: 'grid', gridTemplateRows: '5rem 1fr'}}>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
        <button className='btn btn--secondary' onClick={() => dispatchGetLandingCarousel(true)}>Reset</button>
        <button className='btn btn--secondary' onClick={() => addCarousel()}>Add</button>
        <button className='btn btn--secondary' onClick={() => submit(curCarousels)}>Submit</button>
      </div>
      <div className='ag-theme-alpine' style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          // onRowDragEnd={onRowDragEnd}
          onRowDragMove={onRowDragMove}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          getRowId={getRowId}
          rowData={curCarousels}
        />
      </div>
    </div>
  );
};

/**
 * Page that lists the front page carousels
 * @returns Page component
*/
const LookupCarousel = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 5,
      name: 'Landing',
      path: location.pathname
    }
  ]);
  const {curCarousels, setCurCarousels, submitCarouselChanges} = useCarousels();

  return <div style={{display: 'grid', gridTemplateRows: '1fr'}}>
    {/* <button className='btn btn--primary' onClick={() => submitCarouselChanges(curCarousels)} style={{fontWeight: 'bold'}}>
      Submit
    </button> */}
    <CarouselGrid
      curCarousels={curCarousels}
      setCurCarousels={setCurCarousels}
      submit={submitCarouselChanges}
    />
  </div>;
};

export default LookupCarousel;