const regex = {
  isValidCurrency: (value: string) => {
    const currencyRegex = new RegExp(/^\d{1,}(\.\d{0,2})?$/);

    return currencyRegex.test(value);
  },

  hasPartialCents: (value: string) => {
    const currencyRegex = new RegExp(/^\d{1,}(\.\d{1})$/);

    return currencyRegex.test(value);    
  }
};

export default regex;