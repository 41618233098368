import {useStore} from '../../../store';
import React from 'react';
import {Link} from 'react-router-dom';

type Props = {
  currentPath: string;
};

type LinkData = {
  name: string;
  path: string;
  selected?: boolean;
  requiresSuperAdmin?: boolean;
};

/**
 * Navigation component that includes links to all admin pages.
 * @returns Navigation component
*/
const AdminNav = (props: Props) => {
  const {currentPath} = props;
  const admin = useStore((state) => state.admin);
  const dispatchLogoutAdmin = useStore((state) => state.dispatchLogoutAdmin);
  let links: Array<LinkData> = [
    {
      name: 'Invoices (Facility)',
      path: '/admin/invoices/facility',
    },
    {
      name: 'Invoices (Individual)',
      path: '/admin/invoices/individual'
    },
    {
      name: 'Facilities',
      path: '/admin/lookup/facility'
    },
    {
      name: 'Individuals',
      path: '/admin/lookup/individual'
    },
    {
      name: 'Products',
      path: '/admin/lookup/product'
    },
    {
      name: 'Landing Page',
      path: '/admin/landing'
    },
    {
      name: 'Reward Transactions',
      path: '/admin/rewards'
    },
    {
      name: 'Daily Entries',
      path: '/admin/dailyEntries'
    },
    {
      name: 'Tax Rates',
      path: '/admin/tax'
    },
    {
      name: 'Admins',
      path: '/admin/adminList',
      requiresSuperAdmin: true
    }
  ];

  links = links.map((link) => ({
    ...link,
    selected: link.path === currentPath
  }));

  return (
    <ul className='a-nav'>
      {
        links.map((link) =>
          link.requiresSuperAdmin && !admin?.superUser ?
          <></> :
          <Link
            className={`a-nav__link ${link.selected ? 'a-nav__link--selected' : ''}`}
            to={link.path}
          >
            {link.name}
          </Link>
        )
      }
      <button onClick={() => dispatchLogoutAdmin()}>
        Logout
      </button>
    </ul>
  );
};

export default AdminNav;