import React, {useState} from 'react';

const AboutView = () => {
  const [companyMessage, setCompanyMessage] = useState(
    `Senior Shopping Service was founded by a dedicated nurse who recognized a critical need for specialized clothing services within nursing home facilities. With her vision to enhance the quality of life for residents and simplify the shopping process for caregivers, our company has been committed to providing unparalleled service since its inception in 1985.\n 
    Our journey began with a simple yet profound idea: to make shopping easy!  Recognizing the challenges faced by both residents and caregivers, in traditional shopping experiences, we set out to revolutionize the way seniors access quality clothing.\n
    Central to our mission is the development of an exclusive website tailored to the unique needs of nursing facilities. Through this platform, we offer a seamless and efficient shopping experience, allowing caregivers to browse and purchase essential clothing items with ease. Our online digital approach streamlines the process and ensures accessibility for those caregivers with limited schedules.\n
    What sets Senior Shopping Service apart is our unwavering commitment to providing exceptional assistance and support to our customers. We understand the importance of going above and beyond, and our dedicated team is always on hand to offer personalized guidance and assistance. Whether it's selecting the right size or navigating our website, we strive to provide the extra care and attention that our customers value most.\n
    At the heart of our business lies a dedication to quality. We take pride in offering a carefully curated selection of merchandise that meets the highest standards of excellence. From comfortable loungewear to stylish accessories, each item in our collection is chosen with the comfort and satisfaction of our customers in mind.\n
    Ultimately, our goal is simple: to ensure the happiness and well-being of our customers. We believe that every individual deserves access to quality clothing and exceptional service, and we are committed to making that a reality. With Senior Shopping Service, shopping for essentials becomes not just a task but a delight, empowering seniors to live with dignity and comfort.`
  );


  return (
    <section className='about'>
      <h1 className='about__heading'>About Us</h1>
      <div className='about__message'>
        {
          companyMessage.split('\n').map((line, index) =>
            <p key={index}>{line}</p>
          )
        } 
      </div>
    </section>
  );
};

export default AboutView;