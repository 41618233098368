import InvoiceButtonRenderer from '../../utility/agGrid/InvoiceButtonRenderer';
import {lookupIndividualAPI} from '../../../api';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import React, {useState, useEffect, FormEvent} from 'react';
import {useLocation} from 'react-router-dom';
import {TextField} from '@mui/material';

/**
 * Page that lists individual accounts
 * @returns Page component
*/
const LookupIndividual = () => {
  const location = useLocation();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 5,
      name: 'Individuals',
      path: location.pathname
    }
  ]);
  const [rowData, setRowData] = useState<Array<Object>>([]);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
    // maxWidth: 150
  };
  const columnDefs = [
    {field: 'customerName', headerName: 'Customer Name', filter: 'agTextColumnFilter'},
    {field: 'address', headerName: 'Address', filter: 'agNumberColumnFilter'},
    {field: 'cityState', headerName: 'City/State', filter: 'agDateColumnFilter'},
    {field: 'invoices', headerName: 'Invoices', cellRenderer: InvoiceButtonRenderer}
  ];
  const [searchValue, setSearchValue] = useState('');

  const handleLookupIndividual = async (value: string) => {
    const res = await lookupIndividualAPI(value);
    if (res === null) return;

    setRowData(res.map((ind) => ({
      customerName: `${ind.fname} ${ind.lname}`,
      address: ind.address1,
      cityState: `${ind.city}, ${ind.state}`,
      invoices: ind.userID
    })));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLookupIndividual(searchValue);
  };

  useEffect(() => {
    handleLookupIndividual(' ');
  }, []);

  return (
    <div className='a-table a-table--searchable'>
      <form onSubmit={onSubmit} style={{display: 'flex', margin: '1rem'}}>
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className='btn btn--primary' type='submit'>
          Search
        </button>
      </form>
      <DataGrid
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default LookupIndividual;