import {State} from 'application/store';
import StoreError from 'application/store/utility/errors/StoreError';
import setAlert from 'application/store/alerts/actions/setAlert';
import {deleteProductAPI} from 'application/api';
import {isDebugging} from 'application/utility';
import {SetState, GetState} from 'zustand';

export const deleteProduct = (set: SetState<State>, get: GetState<State>) =>
	async (productId: number, groupId: string) => {
    const productInStore = get().productInfo;

    if (!productInStore) throw new StoreError(
      productInStore,
      'There was an issue retrieving the product, please try again later.',
      {
        log: isDebugging,
        display: true
      }
    );

		const req = {
			prod_id: productId.toString(),
			prod_groupID: groupId,
		};

		const res = await deleteProductAPI(req);

		if (res !== null) setAlert(set, get)({
			status: 'success',
			header: 'Success',
			text: 'Successfully deleted product'
		});

		set({
			productInfo: undefined
		});
	};

export default deleteProduct;