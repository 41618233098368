import Cart from '../entities/Cart';
import {State} from '../..';
import setAlert from '../../alerts/actions/setAlert';
import {FinalizedCart, finalizeCartsAPI} from '../../../api';
import {isDebugging} from '../../../utility';
import {SetState, GetState} from 'zustand';
import {produce} from 'immer';

export type CartToFinalize = Cart & {
  specialShipping: boolean;
  rewardsUsed?: string;
};

const finalizeCarts = (set: SetState<State>, get: GetState<State>) =>
async (cartToFinalize: CartToFinalize) => {

  try {
    const account = get().account;
    if (!account) throw new Error('No account found');
    if (cartToFinalize.status === 'Still Shopping') throw new Error('Invalid status');

    let modifiedFinalizedCart: FinalizedCart = account.roleId === 3 ?
    {
      userID: Number(account.userId),
      residentID: cartToFinalize.residentId,
      firstName: account.firstName,
      lastName: account.lastName,
      paymentType: cartToFinalize.status === 'Pay from Trust Account' ?
        'Pay with Resident Trust Acct.' :
        cartToFinalize.status,
      gTotal: cartToFinalize.totals.grandTotal,
      tax: cartToFinalize.totals.tax,
      shipping: cartToFinalize.totals.shipping,
      label_instructions: cartToFinalize.labelOption || 'Do not label',
      invoice_comments: cartToFinalize.comment || '',
      facPO: account.company?.po || '',
      rewardsUsed: cartToFinalize.rewardsUsed,
      isAcceptingSubs: cartToFinalize.isAcceptingSubs
    } :
    {
      userID: Number(account.userId),
      residentID: 0,
      firstName: account.firstName,
      lastName: account.lastName,
      gTotal: cartToFinalize.totals.grandTotal,
      tax: cartToFinalize.totals.tax,
      shipping: cartToFinalize.totals.shipping,
      label_instructions: cartToFinalize.labelOption || 'Do not label',
      invoice_comments: cartToFinalize.comment || '',
      label_name: cartToFinalize.labelName,
      specialShipping: cartToFinalize.specialShipping ? '1' : undefined,
      isAcceptingSubs: cartToFinalize.isAcceptingSubs
    };

    set((state) => ({
      checkout: {
        ...state.checkout,
        isProcessing: true,
        isFinalized: false
      }
    }));

    const res = await finalizeCartsAPI([modifiedFinalizedCart]);

    // account.roleId === 3 ?
    // set((state) => ({
    //   checkout: {
    //     ...state.checkout,
    //     invoiceId: Number(res.data.invoiceID),
    //     isProcessing: false,
    //     isFinalized: true,
    //     isPayingWithCard: cart.status === 'Pay with Card',
    //     specialShipping: cart.specialShipping
    //   }
    // })) :
    set((state) => ({
      checkout: {
        ...state.checkout,
        invoiceId: Number(res.data.invoiceID),
        isProcessing: false,
        isFinalized: true,
        isPayingWithCard: cartToFinalize.status === 'Credit Card',
        specialShipping: cartToFinalize.specialShipping
      }
    }));

    set(produce((state) => {
      state.account.company = {
        ...state.account.company,
        rewards: res.data.facility_rewards
      }
    }));

  } catch (err: any | unknown) {
    if (isDebugging) {
      console.log('finalize carts store error:', err);
    }

    set((state) => ({
      checkout: {
        ...state.checkout,
        isProcessing: false,
        isFinalized: false
      }
    }));

		setAlert(set, get)({
			status: 'danger',
			header: 'Error',
			text: err?.response?.data?.message || 'Failed to finalize cart'
		});  
  }
};

export default finalizeCarts;