import api from '../../baseApi';
import {isDebugging} from '../../../utility';

const completeStripePort = '/stripe_checkout_success';

/**Completes a Stripe order. */
const completeStripeAPI = async (userId: string, invoiceId: string, session_id: string) => {
  const res = await api.post(completeStripePort, {
    userID: userId,
    invoiceID: invoiceId,
    session_id: session_id
  });

  if (isDebugging) {
    console.log(`${completeStripePort} response:`, res);//TESTING
  }

  return res;
};

export default completeStripeAPI;