import React from 'react';
import {Link} from 'react-router-dom'

type Props = {
  value: number;
  data: {
    date: Date;
    facilityId: string;
    facilityName: string;
    id: number;
    invoiceId: number;
    invoiceType: string;
    isProcessed: string;
    total: string;
  }
};

const InvoiceCellRenderer = (props: Props) => {

  return (
    <Link
      to={`/invoice/view/${props.data.id}/${props.data.invoiceId}`}
      target='_blank'
      rel='noreferrer noopener'
    >
      {props.value}
    </Link>
  );
};

export default InvoiceCellRenderer;